import React from 'react';
import { useLocation, useOutletContext, useSearchParams } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { calcDisplayNo, extractAllClasses } from '../../AppManager';
import ClassCard from '../../components/ClassCard';
import Paginate from '../../components/Paginate';
import EmptySearch from '../../components/EmptySearch';

function AdminClasses() {
    const path = useLocation().pathname;
    const { search, record, setShowSearch } = useOutletContext();
    const [classes, setClasses] = React.useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const classFilter = searchParams.get("trainingClass");
    const [itemsPerPage, setItemsPerPage] = React.useState(5);

    React.useEffect(() => {
        const width = 210;
        const height = 150;
        const pagePadMarginWidth = 20;
        const pagePadMarginHeight = 20;
        setItemsPerPage(calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight));
        window.addEventListener("resize", () => {
            setItemsPerPage(calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight));
        })
        
        return () => {
            window.removeEventListener("resize", () => {
                setItemsPerPage(calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight));
            })
        }
    }, []);

    React.useEffect(() => {
        setClasses(extractAllClasses(record));
    }, [record]);

    React.useEffect(() => {
        setShowSearch(true);
    });

    function setFilter(key, value){
        setSearchParams(param => {
            value ? param.set(key, value) : param.delete(key);
            return param;
        });
    }

    function filterClass(data){
        if(classFilter){
            return data.filter(dat => dat.trainingClass === classFilter);
        }
        return data;
    }

    React.useEffect(() => {
        document.title = 'Classes';
    }, []);

    const data = filterClass(classes);

    const classesElement = data.filter(dat => (dat.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).map(dat => <ClassCard key={nanoid()} name={ dat.name } to={ dat.name } />);

    path === "/admin/students" && classesElement.unshift(<ClassCard key={nanoid()} name={ "All Students" } to={ "/admin/overview/allstudents" } />);
    path === "/admin/teachers" && classesElement.unshift(<ClassCard key={nanoid()} name={ "All Teachers" } to={ "/admin/overview/allteachers" } />);

    return (
        <div className="overview-students">
            {classes.length !== 0 && <nav>
                <div>
                    <button className={`filter-btn ${classFilter === "Believers Class" ? "selected" : ""}`} onClick={() => setFilter("trainingClass", "Believers Class")}>Believers Class</button>
                    <button className={`filter-btn ${classFilter === "Baptismal Class" ? "selected" : ""}`} onClick={() => setFilter("trainingClass", "Baptismal Class")}>Baptismal Class</button>
                    <button className={`filter-btn ${classFilter === "Workers in Training" ? "selected" : ""}`} onClick={() => setFilter("trainingClass", "Workers in Training")}>Workers in Training</button>
                    {classFilter && <button className='filter-btn clear-filters' onClick={() => setFilter("trainingClass", null)}>Clear Filter</button>}
                </div>
            </nav>}
            { 
                classesElement.length === 0
                ?
                <EmptySearch message={"No Class Found"} />
                :
                <Paginate itemsPerPage={itemsPerPage} items={classesElement} />
            }
        </div>
    );
}

export default AdminClasses;