import React from 'react';
import { capitalizeEachWord, getClassPassMarks, getStudentDirect, showSwal } from '../AppManager';
import { Link, redirect, useLoaderData, useNavigate } from 'react-router-dom';
import { clearUser, getCookie, getCurrUser } from '../Auth';
import { FaKey, FaUserPen } from 'react-icons/fa6';

export async function loader(){
    const currUser = await getCurrUser();
    if(currUser.position !== "student"){
        return redirect("/forbidden");
    }
    return currUser;
}

function Profile() {
    const currUser = useLoaderData();
    const navigate = useNavigate();
    const [page, setPage] = React.useState(true);
    const [pageFlip, setPageFlip] = React.useState(true);
    const [student, setStudent] = React.useState({ attendance: "", centre: "", class: "", image: "", name: "", teacher: "", teacherMatric: "", trainingClass: "", matric: "", location: "", score: "" });
    const [classMark, setClassMark] = React.useState(true);
    
    React.useEffect(() => {
        function fakeAuth(){
            const position = getCookie("nd38h4nnue2");
            if(position !== "student"){
                const check = getCookie("nwoeem93jnrr49") || getCookie("nd38h4nnue2") || getCookie("njen98ue9jA") || getCookie("nniNJSN89u3heu23") || getCookie("BNEidw2k83e8y9");
                if(check){
                    clearUser();
                }
                navigate("/");
            }
        }

        window.addEventListener("storage", fakeAuth);
        return () => {
            window.removeEventListener("storage", fakeAuth);
        };
    }, [navigate]);
    
    React.useEffect(() => {
        getStudentDirect(currUser.matric)
        .then(data => setStudent({ attendance: data.attendance, centre: data.centre, class: data.class, image: data.image, name: data.name, teacher: data.teacher, teacherMatric: data.teacherMatric, trainingClass: data.trainingClass, matric: data.matric, location: data.location, score: data.score }))
        .catch(() => {
            showSwal("error", "Student not Found");
            navigate("/", {replace: true});
        });
    }, [currUser, navigate]);

    function togglePage(){
        setPageFlip(oldPageFlip => !oldPageFlip);
        setTimeout(() => {
            setPage(oldPage => !oldPage);
        }, 250);
    };

    React.useEffect(() => {
        document.title = student?.name ? student.name : "Profile";
    }, [student]);

    
    React.useEffect(() => {
        getClassPassMarks()
        .then(passMarks => {
            for (const className in passMarks) {
                if (Object.hasOwnProperty.call(passMarks, className)) {
                    const clas = passMarks[className];
                    if(student.class.toLowerCase().indexOf(className.toLowerCase()) !== -1){
                        setClassMark(clas);
                    }
                }
            }
        })
        .catch(err => err);
    }, [student]);

    return (
        <div className="profile" style={{flexDirection: "column"}}>
            <h1 className="heading1" style={{marginTop: "10px", textAlign: "center"}}>Student Profile</h1>
            {!student?.name && <div className="loader-item" style={{ width: "220px", height: "280px", margin: "20px 0" }}>
                <div className="ruc-watermark">
                    <div>
                        <img src="/images/Bible Study Icon.png" alt="RUC Training Unit" />
                        <p>RUC Training Unit</p>
                    </div>
                </div>
            </div>}
            {student?.name && <div className="details-card-wrapper">
                <div className={`details-card ${pageFlip ? "" : "rotate"}`}>
                    {page && <div className='front' onClick={togglePage}>
                        <div className='details-img'>
                            <img onError={(e)=>{ if (e.target.src !== "/user.png"){ e.target.onerror = null; e.target.src="/user.png"; }}} src={`/images/students/${student.image}`} alt={capitalizeEachWord(student.name)} />
                        </div>
                        <span>{capitalizeEachWord(student.name)}</span>
                        <span>Student</span>
                        <span className='manage'>
                            <Link to="edit" state={{id: student.matric, position: "student", user: currUser}}><FaUserPen style={{fontSize: "1.1em"}} /></Link>
                            <Link to="password" state={{matric: student.matric, position: "student", user: currUser}}><FaKey style={{fontSize: "1.1em"}} /></Link>
                        </span>
                    </div>}
                    {!page && <div className='back' onClick={togglePage}>
                        <div>
                            <h3>Name</h3>
                            <span>{capitalizeEachWord(student.name)}</span>
                        </div>
                        <div>
                            <h3>Matric Number</h3>
                            <span>{student.matric.toUpperCase()}</span>
                        </div>
                        <div>
                            <h3>Attendance</h3>
                            <span>{student.attendance}</span>
                        </div>
                        {student.score && parseInt(student.score) !== 1000 && <div>
                            <h3>Exam Score</h3>
                            <span style={{color: parseInt(classMark) <= parseInt(student.score) ? "#90E18D" : "red", textShadow: "0 0 1px #FFFFFF", fontWeight: "bolder"}}>{student.score}%</span>
                        </div>}
                        <div>
                            <h3>Centre</h3>
                            <span>{student.centre}</span>
                        </div>
                        <div>
                            <h3>Training Class</h3>
                            <span>{student.trainingClass}</span>
                        </div>
                        <div>
                            <h3>Class</h3>
                            <span>{student.class}</span>
                        </div>
                        <div>
                            <h3>Teacher</h3>
                            <span><Link to="teacher" state={{teacherMatric: student.teacherMatric, class: student.class}}>{capitalizeEachWord(student.teacher)}</Link></span>
                        </div>
                        <div>
                            <h3>Location</h3>
                            <span>{student.location}</span>
                        </div>
                    </div>}
                    <div className="ruc-watermark">
                        <div>
                            <img src="/images/Bible Study Icon.png" alt="RUC Training Unit" />
                            <p>RUC Training Unit</p>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default Profile;