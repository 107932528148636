import React from 'react';
import { FaEye, FaEyeSlash, FaHandshake, FaIdCard, FaKey, FaLock, FaSpinner } from 'react-icons/fa6';
import { LiaTimesCircle } from 'react-icons/lia';
import { useNavigate, useParams } from 'react-router-dom';
import { passwordReset } from '../../Auth';
import NotFound from '../../components/NotFound';
import { passwordStrength } from '../../AppManager';

function PasswordReset() {
    const { token } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = React.useState({matric: "", password: "", confirmpassword: "", token: token ? token : ""});
    const [status, setStatus] = React.useState({status: "", type: "", message: ""});
    const [loading, setLoading] = React.useState(false);
    const [showp, setShowp] = React.useState(false);
    const [redirection, setRedirection] = React.useState("");
    const [checkProgress, setCheckProgress] = React.useState({strength: "0%", color: "red", upper: false, lower: false, special: false, length: false, number: false});
    const [mismatch, setMismatch] = React.useState(false);

    React.useEffect(() => {
        if(redirection !== ""){
            navigate(redirection.path, redirection.options);
        }
    }, [redirection, navigate]);

    function handleChange(e){
        const { name, value } = e.target;

        setFormData(oldFormData => {
            let newFormData = {...oldFormData, [name]: value};
            
            if(name === "password" || name === "confirmpassword"){
                setMismatch(newFormData.password !== newFormData.confirmpassword && newFormData.confirmpassword.length > 0 ? true : false);
                setCheckProgress(passwordStrength(newFormData.password));
            }

            return newFormData;
        });
    }

    function success(position){
        setStatus({status: "success", type: "success", message: "Password Reset Successful. Redirecting..."});
        setFormData({matric: "", password: "", token: ""});
        setTimeout(() => {
            setRedirection({path: `/login/${position === "assistant teacher" ? "teacher" : position}?message=Login with New Password`});
        }, 1000);
    }

    React.useEffect(() => {
        document.title = "Reset Password";
    }, []);

    async function handleSubmit(e){
        e.preventDefault();
        try {
            setLoading(true);
            const res = await passwordReset(formData.matric, formData.token, formData.password, formData.confirmpassword);
            if(res[0] === "Success"){
                success(res[1]);
            }else if(res[0] === "Same"){
                setStatus({status: "Same", type: "failed", message: "New Password same as Old Password"});
                setLoading(false);
            }else if(res[0] === "Mismatch"){
                setStatus({status: "Mismatch", type: "failed", message: "Password Mismatched"});
                setLoading(false);
            }else if(res[0] === "Incorrect"){
                setStatus({status: "Incorrect", type: "failed", message: "Matric/Token Mismatch"});
                setLoading(false);
            }else if(res[0] === "Permission"){
                setStatus({status: "Permission", type: "failed", message: "Missing Permission"});
                setLoading(false);
            }else if(res[0] === "Expired"){
                setStatus({status: "Expired", type: "failed", message: "Token Expired"});
                setLoading(false);
            }else{
                setStatus({status: "error", type: "failed", message: "Password Reset Failed"});
                setLoading(false);
            }  
        } catch (error) {
            setStatus({status: "error", type: "failed", message: "Password Reset Failed"});
            setLoading(false);
        }
    }

    function toggleShow(){
        setShowp(oldShowp => !oldShowp);
    }

    React.useEffect(() => {
        if(status.message !== ""){
            document.getElementById("status-wrapper").scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [status]);

    return (
        !token || token.length !== 10
        ?
        <NotFound />
        :
        <section className="contactform resetform loginform">
            <div className="formwrapper">
                <div className={`wrapper`} style={{height: status.message !== "" ? "570px" : "530px"}}>
                    <div className="form-box login">
                        <h2>Password Reset</h2>
                        <form onSubmit={handleSubmit}>
                            {status.message !== "" && <p className='status-wrapper' id="status-wrapper"><span className={`status-message ${status.type}`}>{status.message}</span><LiaTimesCircle className='status-close' onClick={() => {setStatus({status: "", type: "", message: ""})}}/></p>}
                            <div className="input-box">
                                <span className="icon"><FaKey /></span>
                                <input placeholder=" " type="text" name='token' autoComplete="off" value={formData.token} readOnly maxLength={10} minLength={10} required style={{letterSpacing: "5px"}} />
                                <label>Token</label>
                            </div>
                            <div className="input-box">
                                <span className="icon"><FaIdCard /></span>
                                <input readOnly={loading} placeholder=" " type="text" name='matric' autoComplete="off" pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/[A-Za-z]{3}/\d{2}/\d{4,5}$|(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/(FD|fd|Fd|fD)/\d{2}/\d{4,5}$|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$" title='RUN/xxx/yy/yyyy' value={formData.matric} onChange={handleChange} style={{textTransform: "uppercase"}} required />
                                <label>Matric Number</label>
                            </div>
                            <div className="input-box" style={{borderBottom: `2px solid ${mismatch ? "red" : "#002F63"}`}}>
                                <span className="icon">
                                    {!showp && <FaEye style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                    {showp && <FaEyeSlash style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                </span>
                                <input readOnly={loading} placeholder=" " name='password' type={showp ? "text" : "password"} value={formData.password} autoComplete="off" onChange={handleChange} className='password-input' pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}" required />
                                <label><FaLock /> New Password</label>
                            </div>
                            <div className="input-box" style={{borderBottom: `2px solid ${mismatch ? "red" : "#002F63"}`}}>
                                <span className="icon">
                                    {!showp && <FaEye style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                    {showp && <FaEyeSlash style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                </span>
                                <input readOnly={loading} placeholder=" " name='confirmpassword' type={showp ? "text" : "password"} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}" className='password-input' value={formData.confirmpassword} autoComplete="off" onChange={handleChange} required />
                                <label><FaHandshake /> Confirm Password</label>
                            </div>
                            <span className='password-note'>
                                <div id='strength'><div id='strength-bar' style={{width: checkProgress.strength, backgroundColor: checkProgress.color}}></div></div>
                                <i>
                                    At Least:
                                    <ul>
                                        <li className={checkProgress.upper ? "complete" : null}><span> 1 Upper Case</span></li>
                                        <li className={checkProgress.lower ? "complete" : null}><span> 1 Lower Case</span></li>
                                        <li className={checkProgress.number ? "complete" : null}><span> 1 Number</span></li>
                                        <li className={checkProgress.special ? "complete" : null}><span> 1 Special Character</span></li>
                                        <li className={checkProgress.length ? "complete" : null}><span> 6 Characters</span></li>
                                    </ul>
                                </i>
                            </span>
                            <button type="submit" className="btn" disabled={loading || (checkProgress.strength !== "0%" && checkProgress.strength !== "100%") || mismatch}>{loading ? <FaSpinner className='spinner' /> : "Submit"}</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PasswordReset;