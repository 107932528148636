import React from 'react'
import ClassCard from '../../components/ClassCard'

export default function SapetroClasses() {
    return (
        <>
            <h2 className="heading2">Sapetro Classes Location</h2>
            <div>
                <ClassCard name={"Believers Class"} to={""} image="/images/sapetro.jpg" />
                <span><strong>Location:</strong> Sapetro</span>
            </div>
            <div>
                <ClassCard name={"Baptismal Class"} to={""} image="/images/sapetro.jpg" />
                <span><strong>Location:</strong> Sapetro</span>
            </div>
            <div>
                <ClassCard name={"Workers in Training"} to={""} image="/images/sapetro.jpg" />
                <span><strong>Location:</strong> Sapetro</span>
            </div>
        </>
    )
}
