import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { calcEachAtt, showSwal, specialSignIn, updateClassAverage } from '../../../AppManager';
import ConfirmAction from '../../../components/ConfirmAction';

function SpecialConfirmSignIn() {
    const { idnum, date, time, className, classRecord, currUser } = useLocation().state;
    const navigate = useNavigate();
    const member = classRecord.hasOwnProperty(idnum) ? classRecord[idnum] : {image: "user.png"};
    const [redirection, setRedirection] = React.useState("");
    const [close, setClose] = React.useState(true);

    React.useEffect(() => {
        if(redirection !== ""){
            navigate(redirection.path, redirection.options);
        }
    }, [redirection, navigate]);

    React.useEffect(() => {
        document.title = "Confirm Sign-In";
        
        if(!idnum || !date || !time || !className || !classRecord){
            setRedirection(() => {return {path: "/admin/special/signin", options: {replace: true}}})
        }
    }, [idnum, date, time, className, classRecord]);
    

    async function handleSubmit(){
        try {
            if(Object.hasOwnProperty.call(classRecord, idnum)) {
                if(!classRecord.meetings.includes(date)){
                    showSwal("error", `Date not Found`);
                    return;
                }

                const status = member[date];
                window.history.replaceState({}, document.title);
                if(status !== "ABSENT"){
                    showSwal("error", `Already Signed In`);
                }else{
                    const fakeMem = classRecord[idnum];
                    fakeMem[date] = time;
                    const attendance = calcEachAtt(classRecord.meetings, fakeMem) + "%";
                    const res = await specialSignIn({ matric: member.matric, className, date, time, attendance });

                    if(res === "Signed"){
                        updateClassAverage(member.trainingClass, member.class);
                        showSwal("success", `Signed In`);;
                    }else if(res === "Already"){
                        showSwal("error", `Already Signed In`);
                    }else{
                        showSwal("error", "Sign In Failed");
                    }
                }
            }else{
                showSwal("error", "Student not Found");
            }
        } catch (error) {
            showSwal("error", "Student not Found");
        }
        setRedirection({path: "/admin/special/signin", options: { replace: true, state: {} }});
    }

    React.useEffect(() => {
        document.title = "Confirm Sign In";
    }, []);
    
    return (
        <div className="formwrapper confirmsign">
            {!close && <ConfirmAction currUser={currUser} setClose={setClose} confirmed={handleSubmit} />}
            <div className="wrapper">
                <div className="form-box login">
                    <h2>Confirm Sign In</h2>
                    <form onSubmit={(e) => { e.preventDefault(); setClose(false); }}>
                        <div className="imgwrap">
                            <img onError={(e)=>{ if (e.target.src !== "/user.png"){ e.target.onerror = null; e.target.src="/user.png"; }}} className="img" src={`/images/students/${member.image}`} alt={member.name} />
                        </div>
                        <p className='heading3'>{member.name}</p>
                        <input type="submit" value="confirm" name="signin" className="btn" />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SpecialConfirmSignIn;