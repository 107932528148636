import React from 'react';
import { NavLink } from 'react-router-dom';

function BelieversClass() {
    React.useEffect(() => {
        document.title = "Believers Class";
    }, []);

    return (
        <section className='classes-info'>
            <h1 className="heading1">Believers Class</h1>
            <div>
                <img src="/images/believers class.png" alt="Believers Class" />
                <div>
                    <p>
                        This is a class for those who have just given their lives to Christ or have given their life to Christ a long time ago but have never been taught how to live a victorious Christian life. Believers Class will provide you with the information you need to be an overcoming Christian. 
                    </p>
                    <p>
                        The Believers class is one every growth-thirsty Christian should endeavour to attend. It seeks to create in every believer a foundation which is solid enough to withstand any type of structure that would thereafter come upon it. A foundation firm enough to hold in place the whole structure against both gentile and boisterous winds of life which blow naturally upon every building. The book of Matthew 7:25 (KJV) says “And the rain descended, and the floods came, and the winds blew, and beat upon the house; and it fell not: for it was founded upon a rock”. The absence of the type of foundation described in this passage is the reason for failure in the lives of many Christians. 
                    </p>
                    <p>
                        The forgiveness of sin that brings salvation is not the end of the redemption from sin and this latter part you can only receive through knowledge. The knowledge of who you were as an unbeliever and who you are now after your salvation. The lack of this knowledge explains why many believers today are sinning believers and they seem not to have an answer to this question of sin captivity. The Bible says in John 8:32 (KJV), “And ye shall know the truth, and the truth shall make you free”.
                    </p>
                    <p>
                        You are invited to a class that holds immense value for both your present and future. It is the overcomers who willingly forfeit present pleasures for future rewards. Today, choose to be an overcomer by dedicating time and making the sacrifice to come and study at the feet of the Lord Jesus Christ.
                    </p>

                </div>
            </div>
            <NavLink to='/register' state={{trainingClass: "Believers Class"}}><button className="btn">Join Class</button></NavLink>
        </section>
    );
}

export default BelieversClass;