import React from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { calcCumAtt, calcEachAtt, createToday, getCurrTimeInfo, showSwal, signIn, todayExists, updateClassAverage } from '../../AppManager';

function ConfirmSignIn() {
    const { classRecord, setClassRecord } = useOutletContext();
    const { state } = useLocation();
    const navigate = useNavigate();
    const idnum = state ? state.id : "";
    const member = classRecord.hasOwnProperty(idnum) ? classRecord[idnum] : {image: "user.png"};
    const [redirection, setRedirection] = React.useState("");

    React.useEffect(() => {
        if(redirection !== ""){
            navigate(redirection.path, redirection.options);
        }
    }, [redirection, navigate]);

    React.useEffect(() => {
        document.title = "Confirm Sign-In";
        if(!idnum){
            setRedirection(() => {return {path: "/overview/attendance/signin", options: {replace: true}}})
        }
    }, [idnum])
    

    async function handleSubmit(e){
        e.preventDefault();
        try {
            if(Object.hasOwnProperty.call(classRecord, idnum)) {
                const currTimeInfo = getCurrTimeInfo();
                if(!todayExists(classRecord)){
                    createToday(classRecord);
                }
                const status = member[currTimeInfo.today];
                window.history.replaceState({}, document.title);
                if(status !== "ABSENT"){
                    showSwal("error", `Already Signed In`);
                }else{
                    const fakeMem = classRecord[idnum];
                    fakeMem[currTimeInfo.today] = currTimeInfo.currTime24;
                    const attendance = calcEachAtt(classRecord.meetings, fakeMem) + "%";
                    const res = await signIn({ matric: member.matric, Class: member.class, attendance });
                    if(res === "Signed"){
                        updateClassAverage(member.trainingClass, member.class);
                        setClassRecord(() => {
                            let update = {...classRecord, [idnum]: {...classRecord[idnum], [currTimeInfo.today]: currTimeInfo.currTime24}};
                            update = calcCumAtt(update);
                            return update;
                        });
                        showSwal("success", `Signed In`);;
                    }else if(res === "Already"){
                        showSwal("error", `Already Signed In`);
                    }else{
                        showSwal("error", "Sign In Failed");
                    }
                }
            }else{
                showSwal("error", "Student not Found");
            }
        } catch (error) {
            showSwal("error", "Student not Found");
        }
        setRedirection({path: "/overview/attendance/signin", options: { replace: true, state: {} }});
    }

    React.useEffect(() => {
        document.title = "Confirm Sign In";
    }, []);
    
    return (
        <div className="formwrapper confirmsign">
            <div className="wrapper">
                <div className="form-box login">
                    <h2>Confirm Sign In</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="imgwrap">
                            <img onError={(e)=>{ if (e.target.src !== "/user.png"){ e.target.onerror = null; e.target.src="/user.png"; }}} className="img" src={`/images/students/${member.image}`} alt={member.name} />
                        </div>
                        <p className='heading3'>{member.name}</p>
                        <input type="submit" value="confirm" name="signin" className="btn" />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ConfirmSignIn;