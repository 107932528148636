import React from 'react'
import { Outlet, redirect } from 'react-router-dom'
import { getCurrUser } from '../../Auth';

export async function loader(){
    const currUser = await getCurrUser();
    if(currUser.position !== "none"){
        return redirect("/");
    }
    return null;
}

export default function Login() {
  return (
    <>
        <Outlet />
    </>
  )
}
