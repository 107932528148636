import React from 'react';
import { FaEye, FaEyeSlash, FaHandshake, FaKey, FaLock, FaSpinner } from 'react-icons/fa6';
import { LiaTimesCircle } from 'react-icons/lia';
import { useLocation, useNavigate } from 'react-router-dom';
import { changePassword, clearUser, getCookie } from '../../Auth';
import { passwordStrength, showSwal } from '../../AppManager';

function ChangePassword() {
    const navigate = useNavigate();
    const { pathname, state } = useLocation();
    const returnPath = pathname.slice(0, pathname.indexOf(`/password`));
    const { matric, position, user } = state ? state : {matric: "", position: "", user: {position: "", matric: ""}};
    const [formData, setFormData] = React.useState({oldpassword: "", newpassword: "", confirmpassword: ""});
    const [status, setStatus] = React.useState({status: "", type: "", message: ""});
    const [loading, setLoading] = React.useState(false);
    const [showp, setShowp] = React.useState(false);
    const [oldShowp, setOldShowp] = React.useState(false);
    const [redirection, setRedirection] = React.useState("");
    const [checkProgress, setCheckProgress] = React.useState({strength: "0%", color: "red", upper: false, lower: false, special: false, length: false, number: false});
    const [mismatch, setMismatch] = React.useState(false);

    React.useEffect(() => {
        if(redirection !== ""){
            navigate(redirection.path, redirection.options);
        }
    }, [redirection, navigate]);

    React.useEffect(() => {
        const position = getCookie("nd38h4nnue2");
        if(position !== "student" && position !== "teacher" && position !== "assistant teacher"){
            clearUser();
            navigate("/");
        }
    }, [navigate]);

    React.useEffect(() => {
        if(user.position !== position.toLowerCase() || user.matric !== matric || user.position === "" || user.matric === ""){
            showSwal("error", "Missing Permission");
            navigate(returnPath, {replace: true});
        }
    }, [position, navigate, matric, user, returnPath]);

    function handleChange(e){
        const { name, value } = e.target;
        setFormData(oldFormData => {
            let newFormData = {...oldFormData, [name]: value};
            
            if(name === "newpassword" || name === "confirmpassword"){
                setMismatch(newFormData.newpassword !== newFormData.confirmpassword && newFormData.confirmpassword.length > 0 ? true : false);
                setCheckProgress(passwordStrength(newFormData.newpassword));
            }

            return newFormData;
        });
    }

    function success(){
        setStatus({status: "success", type: "success", message: "Password Changed Successful. Redirecting..."});
        setFormData({matric: "", password: "", token: ""});
        setTimeout(() => {
            setRedirection({path: `/login/${user.position === "assistant teacher" ? "teacher" : user.position}?message=Login with New Password`});
        }, 1000);
    }

    async function handleSubmit(e){
        e.preventDefault();
        try {
            setLoading(true);
            const send = { matric: user.matric, position: user.position, oldpassword: formData.oldpassword, newpassword: formData.newpassword, confirmpassword: formData.confirmpassword };
            const res = await changePassword(send);
            if(res === "Success"){
                success();
            }else if(res === "Same"){
                setStatus({status: "Same", type: "failed", message: "New Password same as Old Password"});
                setLoading(false);
            }else if(res === "Mismatch"){
                setStatus({status: "Mismatch", type: "failed", message: "Password Mismatched"});
                setLoading(false);
            }else if(res === "Incorrect"){
                setStatus({status: "Incorrect", type: "failed", message: "Wrong Old Password"});
                setLoading(false);
            }else if(res === "Permission"){
                setStatus({status: "Permission", type: "failed", message: "Missing Permission"});
                setLoading(false);
            }else if(res === "Not Found"){
                setStatus({status: "Not Found", type: "failed", message: "User not Found"});
                setLoading(false);
            }else{
                setStatus({status: "error", type: "failed", message: "Password Change Failed"});
                setLoading(false);
            }  
        } catch (error) {
            setStatus({status: "error", type: "failed", message: "Password Change Failed"});
            setLoading(false);
        }
    }

    function toggleShow(){
        setShowp(oldShowp => !oldShowp);
    }

    function toggleOldShow(){
        setOldShowp(oldShowp => !oldShowp);
    }

    React.useEffect(() => {
        if(status.message !== ""){
            document.getElementById("status-wrapper").scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [status]);

    React.useEffect(() => {
        document.title = "Change Password";
    }, []);

    return (
        <section className="attendanceform changeform">
            <div className="formwrapper">
                <div className={`wrapper`} style={{height: status.message !== "" ? "470px" : "450px"}}>
                    <div className="form-box login">
                        <h2>Change Password</h2>
                        <form onSubmit={handleSubmit}>
                            {status.message !== "" && <p className='status-wrapper' id="status-wrapper"><span className={`status-message ${status.type}`}>{status.message}</span><LiaTimesCircle className='status-close' onClick={() => {setStatus({status: "", type: "", message: ""})}}/></p>}
                            <div className="input-box">
                                <span className="icon">
                                    {!oldShowp && <FaEye style={{cursor: "pointer"}} className='ico' onClick={toggleOldShow} />}
                                    {oldShowp && <FaEyeSlash style={{cursor: "pointer"}} className='ico' onClick={toggleOldShow} />}
                                </span>
                                <input readOnly={loading} placeholder=" " name='oldpassword' type={oldShowp ? "text" : "password"} value={formData.oldpassword} autoComplete="off" onChange={handleChange} required />
                                <label><FaLock /> Old Password</label>
                            </div>
                            <div className="input-box" style={{borderBottom: `2px solid ${mismatch ? "red" : "#002F63"}`}}>
                                <span className="icon">
                                    {!showp && <FaEye style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                    {showp && <FaEyeSlash style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                </span>
                                <input readOnly={loading} placeholder=" " name='newpassword' type={showp ? "text" : "password"} value={formData.newpassword} autoComplete="off" onChange={handleChange} className='password-input' pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}" required />
                                <label><FaKey /> New Password</label>
                            </div>
                            <div className="input-box" style={{borderBottom: `2px solid ${mismatch ? "red" : "#002F63"}`}}>
                                <span className="icon">
                                    {!showp && <FaEye style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                    {showp && <FaEyeSlash style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                </span>
                                <input readOnly={loading} placeholder=" " name='confirmpassword' type={showp ? "text" : "password"} value={formData.confirmpassword} autoComplete="off" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}" className='password-input' onChange={handleChange} required />
                                <label><FaHandshake /> Confirm Password</label>
                            </div>
                            <span className='password-note'>
                                <div id='strength'><div id='strength-bar' style={{width: checkProgress.strength, backgroundColor: checkProgress.color}}></div></div>
                                <i>
                                    At Least:
                                    <ul>
                                        <li className={checkProgress.upper ? "complete" : null}><span> 1 Upper Case</span></li>
                                        <li className={checkProgress.lower ? "complete" : null}><span> 1 Lower Case</span></li>
                                        <li className={checkProgress.number ? "complete" : null}><span> 1 Number</span></li>
                                        <li className={checkProgress.special ? "complete" : null}><span> 1 Special Character</span></li>
                                        <li className={checkProgress.length ? "complete" : null}><span> 6 Characters</span></li>
                                    </ul>
                                </i>
                            </span>
                            <button type="submit" className="btn" disabled={loading || (checkProgress.strength !== "0%" && checkProgress.strength !== "100%") || mismatch}>{loading ? <FaSpinner className='spinner' /> : "Submit"}</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ChangePassword;