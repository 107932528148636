import React from 'react'
import ClassCard from '../components/ClassCard';
import { Outlet } from 'react-router-dom';

export default function FindUs() {
    const [justify, setJustify] = React.useState("flex-start");
    React.useEffect(() => {
        document.title = "Find Us";
        const width = 210;
        const screenWidth = window.screen.width;
        if(width * 5 > screenWidth - 20){
            setJustify("flex-start");
        }else{
            setJustify("space-evenly");
        }
        window.addEventListener("resize", () => {
            if(width * 5 > screenWidth - 20){
                setJustify("flex-start");
            }else{
                setJustify("center");
            }
        })
        
        return () => {
            window.removeEventListener("resize", () => {
                if(width * 5 > screenWidth - 20){
                    setJustify("flex-start");
                }else{
                    setJustify("center");
                }
            })
        }
    }, []);

    return (
        <section className="findus">
            <h1 className="heading1">Find Us</h1>
            <div className='centres'>
                <div style={{justifyContent: justify}}>
                    <ClassCard name={"Chapel"} to={"/findus/chapel"} image="/images/chapel.jpg" />
                    <ClassCard name={"Sapetro"} to={"/findus/sapetro"} image="/images/sapetro.jpg" />
                </div>
            </div>
            <div className="classes">
                <Outlet />
            </div>
        </section>
    );
};
