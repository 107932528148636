import React from 'react';
import { NavLink, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { FaTrash, FaUserPen } from 'react-icons/fa6';
import { capitalizeEachWord, getStudent, showSwal } from '../../AppManager';

function StudentDetails() {
    const pathname = useLocation().pathname;
    const { record, passMarks } = useOutletContext();
    const { id } = useParams();
    const navigate = useNavigate();
    const [student, setStudent] = React.useState({name: "", class: "", location: "", trainingClass: "", centre: "", teacher: "", attendance: "", score: 1000, image: "user.png"});
    const [classMark, setClassMark] = React.useState(true);
    const [page, setPage] = React.useState(true);
    const [pageFlip, setPageFlip] = React.useState(true);
    const returnPath = pathname.slice(0, pathname.indexOf(`/${id}`));

    React.useEffect(() => {
        getStudent(id, record)
        .then(data => setStudent(data))
        .catch(() => {
            showSwal("error", "Student not Found");
            navigate(returnPath, {replace: true});
        })
    }, [id, record, navigate, returnPath]);


    function togglePage(){
        setPageFlip(oldPageFlip => !oldPageFlip);
        setTimeout(() => {
            setPage(oldPage => !oldPage);
        }, 250);
    };

    React.useEffect(() => {
        document.title = student?.name ? student.name : "Student Details";
    }, [student]);

    React.useEffect(() => {
        for (const className in passMarks) {
            if (Object.hasOwnProperty.call(passMarks, className)) {
                const clas = passMarks[className];
                if(student.class.toLowerCase().indexOf(className.toLowerCase()) !== -1){
                    setClassMark(clas);
                }
            }
        }
    }, [passMarks, student]);

    return (
        <div className="details-card-wrapper">
            {!student?.name && <div className="loader-item" style={{ width: "220px", height: "280px" }}>
                <div className="ruc-watermark">
                    <div>
                        <img src="/images/Bible Study Icon.png" alt="RUC Training Unit" />
                        <p>RUC Training Unit</p>
                    </div>
                </div>
            </div>}
            {student?.name && <div className={`details-card ${pageFlip ? "" : "rotate"}`}>
                {page && <div className='front' onClick={togglePage}>
                    <div className='details-img'>
                        <img onError={(e)=>{ if (e.target.src !== "/user.png"){ e.target.onerror = null; e.target.src="/user.png"; }}} src={`/images/students/${student.image}`} alt={capitalizeEachWord(student.name)} />
                    </div>
                    <span>{capitalizeEachWord(student.name)}</span>
                    <span>Student</span>
                    {pathname.indexOf("allstudents") === -1 && <span className='manage'>
                        <NavLink to="edit"><FaUserPen style={{fontSize: "1.1em"}} /></NavLink>
                        <NavLink to="delete"><FaTrash style={{fontSize: "0.9em"}} /></NavLink>
                    </span>}
                </div>}
                {!page && <div className='back' onClick={togglePage}>
                    <div>
                        <h3>Name</h3>
                        <span>{capitalizeEachWord(student.name)}</span>
                    </div>
                    <div>
                        <h3>Matric Number</h3>
                        <span>{student.matric.toUpperCase()}</span>
                    </div>
                    <div>
                        <h3>E-Mail</h3>
                        <span style={{ wordBreak: "break-all" }}><a href={`mailto: ${student.email}`}>{student.email}</a></span>
                    </div>
                    <div>
                        <h3>Attendance</h3>
                        <span>{student.attendance}</span>
                    </div>
                    {student.score && parseInt(student.score) !== 1000 && <div>
                        <h3>Exam Score</h3>
                        <span style={{color: parseInt(classMark) <= parseInt(student.score) ? "#90E18D" : "red", textShadow: "0 0 1px #FFFFFF", fontWeight: "bolder"}}>{student.score}%</span>
                    </div>}
                    <div>
                        <h3>Centre</h3>
                        <span>{student.centre}</span>
                    </div>
                    <div>
                        <h3>Training Class</h3>
                        <span>{student.trainingClass}</span>
                    </div>
                    <div>
                        <h3>Class</h3>
                        <span>{student.class}</span>
                    </div>
                    <div>
                        <h3>Location</h3>
                        <span>{student.location}</span>
                    </div>
                    <div>
                        <h3>Teacher</h3>
                        <span>{capitalizeEachWord(student.teacher)}</span>
                    </div>
                </div>}
                <div className="ruc-watermark">
                    <div>
                        <img src="/images/Bible Study Icon.png" alt="RUC Training Unit" />
                        <p>RUC Training Unit</p>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default StudentDetails;