import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { calcDailyAtt, getCurrTimeInfo } from '../../AppManager';

function Statistics() {
    const { classRecord } = useOutletContext();
    const [stats, setStats] = React.useState([]);

    React.useEffect(() => {
        setStats(calcDailyAtt(getCurrTimeInfo().today, classRecord));
    }, [classRecord]);

    React.useEffect(() => {
        document.title = "Statistics";
    }, []);

    return (
        <div className="formwrapper statistics">
            <div className="wrapper">
                {stats.length === 0 && <div className="loader-item" style={{ width: "100%", height: "220px", background: "linear-gradient(to top right, #F4F6FF 0%, #FFF 100%)", backgroundSize: "200% 100%", boxShadow: "0 5px 8px rgba(0, 0, 0, 0.4)" }}>
                    <div className="ruc-watermark" style={{width: "100%"}}>
                        <div>
                            <img src="/images/Bible Study Icon.png" alt="RUC Training Unit" />
                            <p style={{ color: "#002F63" }}>RUC Training Unit</p>
                        </div>
                    </div>
                </div>}
                {stats.length !== 0 && <div className="form-box login">
                    <h2>Statistics</h2>
                    <form>
                        <div><div>Total Registered : </div><span>{stats[0]}</span></div>
                        <div><div>Total Signed-In : </div><span>{stats[1]}</span></div>
                        <div><div>Attendance Percentage : </div><span>{stats[2]}%</span></div>
                    </form>
                </div>}
            </div>
        </div>
    );
}

export default Statistics;