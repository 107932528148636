import React from 'react';
import { FaChalkboardUser, FaEnvelope, FaEye, FaEyeSlash, FaHandshake, FaIdCard, FaLock, FaSpinner, FaUser } from 'react-icons/fa6';
import { LiaTimesCircle } from 'react-icons/lia';
import { capitalizeEachWord, studentRegistration, cleanMatric, cleanName, passwordStrength } from '../../../AppManager';
import { useOutletContext } from 'react-router-dom';
import { nanoid } from "nanoid";
import ConfirmAction from '../../../components/ConfirmAction';

function SpecialRegister() {
    const [formData, setFormData] = React.useState({ name: "", matric: "", email: "", password: "", confirmpassword: "", class: "" });
    const [status, setStatus] = React.useState({status: "", type: "", message: ""});
    const [disabled, setDisabled] = React.useState(false);
    const [showp, setShowp] = React.useState(false);
    const [mismatch, setMismatch] = React.useState(false);
    const [checkProgress, setCheckProgress] = React.useState({strength: "0%", color: "red", upper: false, lower: false, special: false, length: false, number: false});
    const [uploading, setUploading] = React.useState(false);
    const [close, setClose] = React.useState(true);
    const { maxStudents, classes, currUser } = useOutletContext();

    function handleChange(e){
        const { name, type, value, checked, files } = e.target;
        setFormData(oldFormData => {
            let newFormData = {...oldFormData, [name]: type === "checkbox" ? checked : type === "file" ? files[0] : name === "name" ? cleanName(capitalizeEachWord(value)) : name === "matric" ? cleanMatric(value) : value};
            
            if(name === "password" || name === "confirmpassword"){
                setMismatch(newFormData.password !== newFormData.confirmpassword && newFormData.confirmpassword.length > 0 ? true : false);
                setCheckProgress(passwordStrength(newFormData.password));
            }

            if(name === "previoustrainingclass"){
                newFormData = {...newFormData, trainingclass: value === "None" ? "Believers Class" : value === "Believers Class" ? "Baptismal Class" : value === "Baptismal Class" ? "Workers in Training" : "None"};
                setDisabled(value === "Workers in Training");
            }
            return newFormData;
        });
    }

    async function handleSubmit(){
        try {
            if(!(disabled || mismatch)){
                setUploading(true);
                const classDetail = classes.filter(classe => classe.name === formData.class)[0];
                if(classDetail){
                    if(parseInt(classDetail.students) >= maxStudents){
                        setStatus({status: "full", type: "failed", message: "Class is Full"});
                        setUploading(false);
                    }else{
                        const regData = { name: formData.name, matric: formData.matric, email: formData.email, trainingClass: classDetail.trainingClass, Class: formData.class, centre: classDetail.centre, image: "user.png", attendance: "0%", password: formData.password, confirmpassword: formData.confirmpassword };
                        const res = await studentRegistration(regData);
                        if(res === "Registered"){
                            setTimeout(() => {
                                setStatus({status: "success", type: "success", message: "Registration Successful"});
                                setFormData({ name: "", matric: "", email: "", password: "", confirmpassword: "", class: "" });
                                setUploading(false);
                            }, 1000);
                        }else if(res === "Exists"){
                            setStatus({status: "exists", type: "failed", message: "Already Registered"});
                            setUploading(false);
                        }else{
                            setStatus({status: "error", type: "failed", message: "Registration Failed"});
                            setUploading(false);
                        }
                    }
                }else{
                    setStatus({status: "error", type: "failed", message: "Registration Failed"});
                    setUploading(false);
                }
            }
        } catch (error) {
            setStatus({status: "error", type: "failed", message: "Registration Failed"});
            setUploading(false);
        }
    }

    function toggleShow(){
        setShowp(oldShowp => !oldShowp);
    }

    React.useEffect(() => {
        if(status.message !== ""){
            document.getElementById("status-wrapper").scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [status]);

    React.useEffect(() => {
        document.title = "Registration";
    }, []);

    const selectClasses = classes.map(cent => <option key={nanoid()} value={cent.name}>{cent.name}</option>);

    return (
        <section className="contactform registration spec">
            {!close && <ConfirmAction currUser={currUser} setClose={setClose} confirmed={handleSubmit} />}
            <div className="formwrapper">
                <div className={`wrapper`} style={{height: status.message !== "" ? "690px" : "650px"}}>
                    <div className="form-box login">
                        <h2>Registration</h2>
                        <form onSubmit={(e) => { e.preventDefault(); setClose(false); }}>
                            {status.message !== "" && <p className='status-wrapper' id="status-wrapper"><span className={`status-message ${status.type}`}>{status.message}</span><LiaTimesCircle className='status-close' onClick={() => {setStatus({status: "", type: "", message: ""})}}/></p>}
                            <div className="input-box">
                                <span className="icon"><FaUser /></span>
                                <input readOnly={uploading} placeholder=" " type="text" name='name' value={formData.name} onChange={handleChange} autoComplete="off" required minLength={7} maxLength={25} pattern="[A-Za-z\- ]+" />
                                <label>Name</label>
                            </div>
                            <div className="input-box">
                                <span className="icon"><FaIdCard /></span>
                                <input readOnly={uploading} placeholder=" " type="text" name='matric' style={{textTransform: "uppercase"}} value={formData.matric} onChange={handleChange} autoComplete="off" pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/[A-Za-z]{3}/\d{2}/\d{4,5}$|(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/(FD|fd|Fd|fD)/\d{2}/\d{4,5}$|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$" title='RUN/xxx/yy/yyyy' maxLength={16} required />
                                <label>Matric Number</label>
                            </div>
                            <div className="input-box">
                                <span className="icon"><FaEnvelope /></span>
                                <input readOnly={uploading} placeholder=" " type="email" name='email' value={formData.email} minLength={13} maxLength={36} onChange={handleChange} autoComplete="off" required />
                                <label>E-Mail</label>
                            </div>
                            <div className="input-box">
                                <select disabled={uploading} name='class' value={formData.class} onChange={handleChange} className="inputs" required>
                                    <option value="" disabled>Choose one...</option>
                                    {selectClasses}
                                </select>
                                <label><FaChalkboardUser /> Class</label>
                            </div>
                            <div className="input-box" style={{borderBottom: `2px solid ${mismatch ? "red" : "#002F63"}`}}>
                                <span className="icon">
                                    {!showp && <FaEye style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                    {showp && <FaEyeSlash style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                </span>
                                <input readOnly={uploading} placeholder=" " maxLength={30} type={showp ? "text" : "password"} name='password' pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}" className='password-input' value={formData.password} autoComplete="off" onChange={handleChange} required />
                                <label><FaLock /> Password</label>
                            </div>
                            <div className="input-box" style={{borderBottom: `2px solid ${mismatch ? "red" : "#002F63"}`}}>
                                <span className="icon">
                                    {!showp && <FaEye style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                    {showp && <FaEyeSlash style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                </span>
                                <input readOnly={uploading} placeholder=" " maxLength={30} type={showp ? "text" : "password"} name='confirmpassword' value={formData.confirmpassword} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"  className='password-input' autoComplete="off" onChange={handleChange} required />
                                <label><FaHandshake /> Confirm Password</label>
                            </div>
                            <span className='password-note'>
                                <div id='strength'><div id='strength-bar' style={{width: checkProgress.strength, backgroundColor: checkProgress.color}}></div></div>
                                <i>
                                    At Least:
                                    <ul>
                                        <li className={checkProgress.upper ? "complete" : null}><span> 1 Upper Case</span></li>
                                        <li className={checkProgress.lower ? "complete" : null}><span> 1 Lower Case</span></li>
                                        <li className={checkProgress.number ? "complete" : null}><span> 1 Number</span></li>
                                        <li className={checkProgress.special ? "complete" : null}><span> 1 Special Character</span></li>
                                        <li className={checkProgress.length ? "complete" : null}><span> 6 Characters</span></li>
                                    </ul>
                                </i>
                            </span>
                            <button type="submit" disabled={mismatch || disabled || uploading || (checkProgress.strength !== "0%" && checkProgress.strength !== "100%")} className='btn'>{uploading ? <FaSpinner className='spinner' /> : "Submit"}</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SpecialRegister;