import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { LiaTimesCircle } from 'react-icons/lia';
import { FaIdCard, FaCalendarDays, FaChalkboardUser, FaSpinner } from 'react-icons/fa6';
import { getClassRecord, getCurrTimeInfo, stripSpace } from '../../../AppManager';
import { nanoid } from "nanoid";

function SpecialSignIn(){
    const { classes, currUser } = useOutletContext();
    const [formData, setFormData] = React.useState({ idnum: "", datetime: "", class: "" });
    const [loading, setLoading] = React.useState(false);
    const [status, setStatus] = React.useState({status: "", type: "", message: ""});
    const navigate = useNavigate();

    React.useEffect(() => {
        document.title = "Sign In";
    }, []);

    function handleChange(e){
        const { name, value } = e.target;
        setFormData(old => ({ ...old, [name]: name === "idnum" ? stripSpace(value.toUpperCase()) : value }));
    }

    async function handleSubmit(e){
        e.preventDefault();
        try {
            setLoading(true);
            const classDetail = classes.filter(classe => classe.name === formData.class)[0];
            const classRecord = await getClassRecord(classDetail.trainingClass, formData.class);
            if(Object.hasOwnProperty.call(classRecord, formData.idnum) || Object.hasOwnProperty.call(classRecord, formData.idnum.padStart(5, "0")) || (formData.idnum.length === 5 && formData.idnum[0] === "0" && Object.hasOwnProperty.call(classRecord, formData.idnum.slice(1)))) {
                const uidnum = Object.hasOwnProperty.call(classRecord, formData.idnum) ? formData.idnum : (formData.idnum.length === 5 && formData.idnum[0] === "0" && Object.hasOwnProperty.call(classRecord, formData.idnum.slice(1))) ? formData.idnum.slice(1) : formData.idnum.padStart(5, "0");
                const member = classRecord[uidnum];

                const currTimeInfo = getCurrTimeInfo();
                const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                const nani = new Date(formData.datetime);
                const date = `${days[nani.getDay()]}-${nani.getDate().toString().padStart(2, "0")}-${(nani.getMonth() + 1).toString().padStart(2, "0")}-${nani.getFullYear()}`;
                const time = new Intl.DateTimeFormat('en-US', currTimeInfo.options24).format(nani).toString().slice(0, 2) + currTimeInfo.currTime.slice(2);

                if(!classRecord.meetings.includes(date)){
                    setStatus({status: "date", type: "failed", message: "Date not Found"});
                    setLoading(false);
                    return;
                }
                
                const status = member[date];
                if(status !== "ABSENT"){
                    setStatus({status: "info", type: "failed", message: "Already logged in"});
                    setLoading(false);
                }else{
                    setStatus({status: "redirect", type: "success", message: "Redirecting..."});
                    navigate(`/admin/special/signin/confirm`, {state: { permission: true, idnum: uidnum, date, time, className: formData.class, classRecord, currUser }});
                }
            }else{
                setStatus({status: "date", type: "failed", message: "Student not Found"});
                setLoading(false);
            }
        } catch (error) {
            setStatus({status: "date", type: "failed", message: "Student not Found"});
            setLoading(false);
        }
    }

    React.useEffect(() => {
        document.title = "Sign In";
    }, []);

    const selectClasses = classes.map(cent => <option key={nanoid()} value={cent.name}>{cent.name}</option>);

    return (
        <div className="formwrapper">
            <div className="wrapper" style={{height: status.message !== "" ? "450px" : "410px"}}>
                <div className="form-box login">
                    <h2>Sign In</h2>
                    <form onSubmit={handleSubmit}>
                        {status.message !== "" && <p className='status-wrapper' id="status-wrapper"><span className={`status-message ${status.type}`}>{status.message}</span><LiaTimesCircle className='status-close' onClick={() => {setStatus({status: "", type: "", message: ""})}}/></p>}
                        <div className="input-box">
                            <select disabled={loading} name='class' value={formData.class} onChange={handleChange} className="inputs" required>
                                <option value="" disabled>Choose one...</option>
                                {selectClasses}
                            </select>
                            <label><FaChalkboardUser /> Class</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaCalendarDays /></span>
                            <input type="datetime-local" name="datetime" className='input' value={formData.datetime} max={new Date().toISOString().slice(0, -8)} onChange={handleChange} required />
                            <label>Date-Time</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaIdCard /></span>
                            <input type="text" onChange={handleChange} value={formData.idnum} autoComplete="off" pattern="\d{4,5}|[0-9]{3}[A-Za-z]{2}" minLength={4} maxLength={5} required name="idnum" style={{textTransform: "uppercase"}} placeholder=" " />
                            <label>Matric Number</label>
                        </div>
                        <button type="submit" disabled={loading} className='btn'>{loading ? <FaSpinner className='spinner' /> : "Submit"}</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SpecialSignIn;