import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { extractMatricDigits, extractClassesTeachers, calcDisplayNo } from '../../AppManager';
import MemberCard from '../../components/MemberCard';
import Paginate from '../../components/Paginate';
import EmptySearch from '../../components/EmptySearch';

function AdminTeachers() {
    const { search, record, setShowSearch } = useOutletContext();
    const { Class } = useParams();
    const [teachers, setTeachers] = React.useState([]);
    const [itemsPerPage, setItemsPerPage] = React.useState(5);

    React.useEffect(() => {
        const width = 160;
        const height = 220;
        const pagePadMarginWidth = 20;
        const pagePadMarginHeight = 20;
        setItemsPerPage(calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight));
        window.addEventListener("resize", () => {
            setItemsPerPage(calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight));
        })
        
        return () => {
            window.removeEventListener("resize", () => {
                setItemsPerPage(calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight));
            })
        }
    }, []);

    React.useEffect(() => {
        setTeachers(extractClassesTeachers(record, Class));
    }, [record, Class]);

    React.useEffect(() => {
        setShowSearch(true);
    });

    React.useEffect(() => {
        document.title = 'Teachers';
    }, []);

    const students = teachers.filter(dat => (dat.name.toLowerCase().indexOf(search.toLowerCase()) > -1 || dat.matric.toLowerCase().indexOf(search.toLowerCase()) > -1)).map(dat => <MemberCard key={nanoid()} name={dat.name} image={dat.image} matric={dat.matric} to={extractMatricDigits(dat.matric)} imgfolder="teachers" />);

    return (
        <div className="overview-students">
            <h1 className="heading1" style={{width: "100vw"}}>{Class} Teachers</h1>
            { 
                students.length === 0
                ?
                <EmptySearch message={"No Teacher Found"} />
                :
                <Paginate itemsPerPage={itemsPerPage} items={students} />
            }
        </div>
    );
}

export default AdminTeachers;