import React from 'react';
import { NavLink, useOutletContext } from 'react-router-dom';
import { extractMatricDigits, getClassDetails, showSwal, updateClassAdmission, updateCurrentClass } from '../../AppManager';
import ConfirmAction from '../../components/ConfirmAction';

function OverviewClass() {
    const { user, record, setRecord } = useOutletContext();
    const [classInfo, setClassInfo] = React.useState({name: "", teacher: "", "teacher matric": "RUN/ABC/12/3456", "assistant teacher": "", "assistant matric": "RUN/ABC/12/3456", location: "", students: "", centre: "", trainingClass: "", admission: true, currentClass: true});
    const [close, setClose] = React.useState(true);
    const [actionEvent, setActionEvent] = React.useState({});

    React.useEffect(() => {
        setClassInfo(getClassDetails(user.class, record))
    }, [user, record]);

    async function toggleAdmission(e){
        const { name, type, checked } = e.target;
        if(name === "admission" && type === "checkbox"){
            const res = await updateClassAdmission({ name: classInfo.name, admission: checked });
            if(res === "Updated"){
                setClassInfo(oldClassInfo => {
                    return {...oldClassInfo, [name]: checked}
                });
                setRecord(oldRecord => {
                    let lasses = oldRecord[classInfo.centre][classInfo.trainingClass].classes;
                    for (let l = 0; l < lasses.length; l++) {
                        let currC = lasses[l];
                        if(currC.name === classInfo.name){
                            lasses[l] = {...currC, [name]: checked};
                        }    
                    }
                    return {...oldRecord, [classInfo.centre]: {...oldRecord[classInfo.centre], [classInfo.trainingClass]: {...oldRecord[classInfo.centre][classInfo.trainingClass], classes: lasses}}};
                });
            }else{
                showSwal("error", "Failed");
            }
        }
    }

    function toggleClass(e){
        const { name, type, checked } = e.target;
        setActionEvent({ name, type, checked });
        setClose(false);
    }

    async function toggleClassConfirmed({ name, type, checked }){
        if(name === 'currentClass' && type === "checkbox"){
            const res = await updateCurrentClass({ name: classInfo.name, currentClass: checked });
            if(res === "Updated"){
                setClassInfo(oldClassInfo => {
                    return {...oldClassInfo, [name]: checked}
                });
                setRecord(oldRecord => {
                    let lasses = oldRecord[classInfo.centre][classInfo.trainingClass].classes;
                    for (let l = 0; l < lasses.length; l++) {
                        let currC = lasses[l];
                        if(currC.name === classInfo.name){
                            lasses[l] = {...currC, [name]: checked, students: 0};
                        }    
                    }
                    return {...oldRecord, [classInfo.centre]: {...oldRecord[classInfo.centre], [classInfo.trainingClass]: {...oldRecord[classInfo.centre][classInfo.trainingClass], classes: lasses}}};
                });
            }else if(res === "Exists"){
                showSwal("error", "Record Exists");
            }else if(res === "Empty"){
                showSwal("error", "Record Empty");
            }else{
                showSwal("error", "Failed");
            }
        }
    }

    return (
        <div className="overview-class">
            {!close && <ConfirmAction currUser={user} setClose={setClose} confirmed={() => {toggleClassConfirmed(actionEvent)}} />}
            <h1 className="heading1">{classInfo.name}</h1>
            <div>
                <div><h1 className="heading3" style={{textAlign: "left"}}>Teacher:</h1><span><NavLink to={`/overview/teacher/${extractMatricDigits(classInfo["teacher matric"])}`}>{classInfo.teacher}</NavLink></span></div>
                <div><h1 className="heading3" style={{textAlign: "left"}}>Assistant Teacher:</h1><span><NavLink to={`/overview/teacher/${extractMatricDigits(classInfo["assistant matric"])}`}>{classInfo["assistant teacher"]}</NavLink></span></div>
                <div><h1 className="heading3" style={{textAlign: "left"}}>Centre:</h1><span>{classInfo.centre}</span></div>
                <div><h1 className="heading3" style={{textAlign: "left"}}>Training Class:</h1><span>{classInfo.trainingClass}</span></div>
                <div><h1 className="heading3" style={{textAlign: "left"}}>Location:</h1><span>{classInfo.location}</span></div>
                <div><h1 className="heading3" style={{textAlign: "left"}}>Number of Students:</h1><span><NavLink to="/overview/students">{classInfo.students}</NavLink></span></div>
                <div>
                    <h1 className="heading3" style={{textAlign: "left"}}>Admission:</h1>
                    <span className='checkbox-wrapper'>
                        <input className="tgl tgl-skewed" id="cb3-8" name='admission' type="checkbox" checked={classInfo.admission} onChange={toggleAdmission}/>
                        <label className="tgl-btn" data-tg-off="CLOSED" data-tg-on="OPEN" htmlFor="cb3-8"></label>
                    </span>
                </div>
                <div>
                    <h1 className="heading3" style={{textAlign: "left"}}>Class:</h1>
                    <span className='checkbox-wrapper'>
                        <input className="tgl tgl-skewed" id="cb3-9" name='currentClass' type="checkbox" checked={classInfo.currentClass} onChange={toggleClass}/>
                        <label className="tgl-btn tgl-btn2 tgl-btn3" data-tg-off="START" data-tg-on="ONGOING" htmlFor="cb3-9"></label>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default OverviewClass;