import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { calcDisplayNo, extractClassStudents, extractMatricDigits } from '../../AppManager';
import MemberCard from '../../components/MemberCard';
import Paginate from '../../components/Paginate';
import EmptySearch from '../../components/EmptySearch';
import { StudentsLoader } from './AllStudents';

function OverviewStudent() {
    const { search, user, setShowSearch } = useOutletContext();
    const [students, setStudents] = React.useState([]);
    const [studentsFetch, setStudentsFetch] = React.useState(false);
    const [itemsPerPage, setItemsPerPage] = React.useState(5);

    React.useEffect(() => {
        const width = 160;
        const height = 220;
        const pagePadMarginWidth = 20;
        const pagePadMarginHeight = 20;
        setItemsPerPage(calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight));
        window.addEventListener("resize", () => {
            setItemsPerPage(calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight));
        })
        
        return () => {
            window.removeEventListener("resize", () => {
                setItemsPerPage(calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight));
            })
        }
    }, []);

    React.useEffect(() => {
        extractClassStudents(user.class)
        .then(data => {
            setStudents(data);
            setStudentsFetch(true);
        })
    }, [user]);

    React.useEffect(() => {
        setShowSearch(true);
    });

    React.useEffect(() => {
        document.title = "Students";
    }, []);

    const studentsElement = students.filter(dat => (dat.name.toLowerCase().indexOf(search.toLowerCase()) > -1 || dat.matric.toLowerCase().indexOf(search.toLowerCase()) > -1)).map(dat => <MemberCard key={nanoid()} name={dat.name} image={dat.image} matric={dat.matric} to={extractMatricDigits(dat.matric)} imgfolder="students" />);
    studentsElement.unshift(<MemberCard key={nanoid()} name={"All Students"} image={"user.png"} matric={""} to={"/overview/allstudents"} imgfolder="students" />);

    return (
        <div className="overview-students">
            {
                studentsFetch
                ? 
                    studentsElement.length === 0
                    ?
                    <EmptySearch message={"No Student Found"} />
                    :
                    <Paginate itemsPerPage={itemsPerPage} items={studentsElement} />
                :
                <><MemberCard key={nanoid()} name={"All Students"} image={"user.png"} matric={""} to={"/overview/allstudents"} imgfolder="students" /><StudentsLoader /></>
            }
        </div>
    );
}

export default OverviewStudent;