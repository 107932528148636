import React from 'react';
import { capitalizeEachWord } from '../AppManager';

function RegisterConfirmation({ formData, preview, handleSubmit, edit }) {
    const [page, setPage] = React.useState(true);
    const [pageFlip, setPageFlip] = React.useState(true);

    function togglePage(){
        setPageFlip(oldPageFlip => !oldPageFlip);
        setTimeout(() => {
            setPage(oldPage => !oldPage);
        }, 250);
    };

    return (
        <div className="details-card-wrapper" style={{ flexDirection: "column" }}>
            <h1 style={{ marginBottom: "20px", marginTop: "-50px", fontSize: "2em", color: "#002F63" }}>Confirm Registration</h1>
            <div className={`details-card ${pageFlip ? "" : "rotate"}`}>
                {page && <div className='front' onClick={togglePage}>
                    <div className='details-img'>
                        <img onError={(e)=>{ if (e.target.src !== "/user.png"){ e.target.onerror = null; e.target.src="/user.png"; }}} src={preview} alt={capitalizeEachWord(formData.name)} />
                    </div>
                    <span style={{ wordWrap: "break-word" }}>{capitalizeEachWord(formData.name)}</span>
                    <span>{formData.matric.toUpperCase()}</span>
                </div>}
                {!page && <div className='back' onClick={togglePage}>
                    <div>
                        <h3>Name</h3>
                        <span style={{ wordWrap: "break-word" }}>{capitalizeEachWord(formData.name)}</span>
                    </div>
                    <div>
                        <h3>Matric Number</h3>
                        <span>{formData.matric.toUpperCase()}</span>
                    </div>
                    <div>
                        <h3>E-Mail</h3>
                        <span style={{ wordBreak: "break-all" }}>{formData.email}</span>
                    </div>
                    <div>
                        <h3>Training Class</h3>
                        <span>{formData.trainingclass}</span>
                    </div>
                    <div>
                        <h3>Centre</h3>
                        <span>{formData.worshipcentre}</span>
                    </div>
                </div>}
                <div className="ruc-watermark">
                    <div>
                        <img src="/images/Bible Study Icon.png" alt="RUC Training Unit" />
                        <p>RUC Training Unit</p>
                    </div>
                </div>
            </div>
            <div style={{ width: "220px", marginTop: "20px" }}>
                <button type="button" className='btn' onClick={edit} style={{ marginBottom: "10px", backgroundColor: "#90E18D" }}>Edit</button>
                <button type="button" className='btn' onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
}

export default RegisterConfirmation;