import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion";
import Layout from './components/Layout';
import Home from './pages/Home';
import Error from './components/Error';
import NotFound from './components/NotFound';
import Offline from './components/Offline';
import ContactForm from './pages/ContactForm';
import Student from './pages/login/Student';
import Teacher from './pages/login/Teacher';
import Admin from './pages/login/Admin';
import Register from './pages/Register';
import Overview, { loader as overviewLoader } from './pages/Overview';
import OverviewStudent from './pages/overview/OverviewStudent';
import OverviewClass from './pages/overview/OverviewClass';
import TeacherDetails from './pages/overview/TeacherDetails';
import StudentDetails from './pages/overview/StudentDetails';
import Attendance from './pages/overview/Attendance';
import SignIn from './pages/attendance/SignIn';
import Statistics from './pages/attendance/Statistics';
import EditStudent from './pages/overview/EditStudent';
import DeleteStudent from './pages/overview/DeleteStudent';
import Profile, { loader as profileLoader } from './pages/Profile';
import OverviewGeneral from './pages/admin/OverviewGeneral';
import AdminOverview, { loader as adminLoader } from './pages/AdminOverview';
import AdminTeachers from './pages/admin/AdminTeachers';
import AdminStudents from './pages/admin/AdminStudents';
import AdminTeacherDetails from './pages/admin/AdminTeacherDetails';
import AdminStudentDetails from './pages/admin/AdminStudentDetails';
import AdminEdit from './pages/admin/AdminEdit';
import AdminDelete from './pages/admin/AdminDelete';
import AdminAttendance from './pages/admin/AdminAttendance';
import AddCentre from './pages/admin/AddCentre';
import AddClass from './pages/admin/AddClass';
import EditClass from './pages/admin/EditClass';
import BelieversClass from './pages/BelieversClass';
import WorkersInTraining from './pages/WorkersInTraining';
import BaptismalClass from './pages/BaptismalClass';
import AdminClasses from './pages/admin/AdminClasses';
import ConfirmSignIn from './pages/attendance/ConfirmSignIn';
import ProfileTeacher from './pages/ProfileTeacher';
import AdminClassDetails from './pages/admin/AdminClassDetails';
import AllStudents from './pages/overview/AllStudents';
import PasswordReset from './pages/login/Reset';
import Login, {loader as loginLoader} from './pages/login/Login';
import TeacherRegistration from './pages/TeacherRegistration';
import AdminAllTeachers from './pages/admin/AdminAllTeachers';
import RegisterWelcome from './pages/RegisterWelcome';
import EditTeacher from './pages/overview/EditTeacher';
import ChangePassword from './pages/overview/ChangePassword';
import ProfileEdit from './pages/ProfileEdit';
import Forbidden from './components/Forbidden';
import FindUs from './pages/FindUs';
import ChapelClasses from './pages/findus/ChapelClasses';
import SapetroClasses from './pages/findus/SapetroClasses';
import AdminReg from './pages/AdminReg';
import MaxStudents from './pages/admin/MaxStudents';
import PassMarksView from './pages/admin/PassMarksView';
import PassMarksEdit from './pages/admin/PassMarksEdit';
import EditContact, {loader as editContactLoader} from './pages/EditContact';
import SpecialRegister from './pages/admin/special/SpecialRegister';
import AdminSpecial from './pages/admin/AdminSpecial';
import Specials from './pages/admin/Specials';
import SpecialSignIn from './pages/admin/special/SpecialSignIn';
import SpecialConfirmSignIn from './pages/admin/special/SpecialConfirmSignIn';

const transition = { duration: 0.5 };

const AnimatedRoute = ({ children }) => (
  <motion.div
    key={window.location.pathname}
    initial={{ opacity: 0, x: -100 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: 100 }}
    transition={transition}
  >
    {children}
  </motion.div>
);


export default function App() {
  const routes = [
    { path: '/', element: <Home />, errorElement: <Error /> },
    { path: 'adminreg', element: <AdminReg />, errorElement: <Error /> },
    { path: 'believersclass', element: <BelieversClass />, errorElement: <Error /> },
    { path: 'baptismalclass', element: <BaptismalClass />, errorElement: <Error /> },
    { path: 'workersintraining', element: <WorkersInTraining />, errorElement: <Error /> },
    { path: 'contact', element: <ContactForm />, errorElement: <Error /> },
    { path: 'editcontact', element: <EditContact />, loader: editContactLoader, errorElement: <Error /> },
    { path: 'findus', parent: "findus", element: <FindUs />, errorElement: <Error /> },
    { path: '/', childOf: "findus", element: <ChapelClasses />, errorElement: <Error /> },
    { path: 'chapel', childOf: "findus", element: <ChapelClasses />, errorElement: <Error /> },
    { path: 'sapetro', childOf: "findus", element: <SapetroClasses />, errorElement: <Error /> },
    { path: 'register', element: <Register />, errorElement: <Error /> },
    { path: 'register/welcome', element: <RegisterWelcome />, errorElement: <Error /> },
    { path: 'teacherreg', element: <TeacherRegistration />, errorElement: <Error /> },
    { path: 'login', parent: 'login', element: <Login />, loader: loginLoader, errorElement: <Error /> },
    { path: '/', childOf: 'login', element: <Student />, errorElement: <Error /> },
    { path: 'student', childOf: 'login', element: <Student />, errorElement: <Error /> },
    { path: 'teacher', childOf: 'login', element: <Teacher />, errorElement: <Error /> },
    { path: 'admin', childOf: 'login', element: <Admin />, errorElement: <Error /> },
    { path: 'passwordreset', element: <PasswordReset />, errorElement: <Error /> },
    { path: 'passwordreset/:token', element: <PasswordReset />, errorElement: <Error /> },
    { path: 'profile', element: <Profile />, loader: profileLoader, errorElement: <Error /> },
    { path: 'profile/teacher', element: <ProfileTeacher />, loader: profileLoader, errorElement: <Error /> },
    { path: 'profile/password', element: <ChangePassword />, loader: profileLoader, errorElement: <Error /> },
    { path: 'profile/edit', element: <ProfileEdit />, loader: profileLoader, errorElement: <Error /> },
    { path: 'overview', parent: 'overview', element: <Overview />, loader: overviewLoader, errorElement: <Error /> },
    { path: '/', childOf: 'overview', element: <OverviewClass />, errorElement: <Error /> },
    { path: 'class', childOf: 'overview', element: <OverviewClass />, errorElement: <Error /> },
    { path: 'teacher/:id', childOf: 'overview', element: <TeacherDetails />, errorElement: <Error /> },
    { path: 'teacher/:id/edit', childOf: 'overview', element: <EditTeacher />, errorElement: <Error /> },
    { path: 'teacher/:id/password', childOf: 'overview', element: <ChangePassword />, errorElement: <Error /> },
    { path: 'students', childOf: 'overview', element: <OverviewStudent />, errorElement: <Error /> },
    { path: 'students/:id', childOf: 'overview', element: <StudentDetails />, errorElement: <Error /> },
    { path: 'students/:id/edit', childOf: 'overview', element: <EditStudent />, errorElement: <Error /> },
    { path: 'students/:id/delete', childOf: 'overview', element: <DeleteStudent />, errorElement: <Error /> },
    { path: 'allstudents', childOf: 'overview', element: <AllStudents />, errorElement: <Error /> },
    { path: 'allstudents/:id', childOf: 'overview', element: <StudentDetails />, errorElement: <Error /> },
    { path: 'attendance', childOf: 'overview', parent: 'attendance', element: <Attendance />, errorElement: <Error /> },
    { path: '/', childOf: 'attendance', element: <SignIn />, errorElement: <Error /> },
    { path: 'signin', childOf: 'attendance', element: <SignIn />, errorElement: <Error /> },
    { path: 'signin/confirm', childOf: 'attendance', element: <ConfirmSignIn />, errorElement: <Error /> },
    { path: 'statistics', childOf: 'attendance', element: <Statistics />, errorElement: <Error /> },
    { path: 'admin', parent: 'admin', element: <AdminOverview />, loader: adminLoader, errorElement: <Error /> },
    { path: '/', childOf: 'admin', element: <OverviewGeneral />, errorElement: <Error /> },
    { path: 'studentsmax', childOf: 'admin', element: <MaxStudents />, errorElement: <Error /> },
    { path: 'overview', childOf: 'admin', element: <OverviewGeneral />, errorElement: <Error /> },
    { path: 'overview/allstudents', childOf: 'admin', element: <AllStudents />, errorElement: <Error /> },
    { path: 'overview/allstudents/:id', childOf: 'admin', element: <AdminStudentDetails />, errorElement: <Error /> },
    { path: 'overview/allstudents/:id/edit', childOf: 'admin', element: <EditStudent />, errorElement: <Error /> },
    { path: 'overview/allstudents/:id/special', childOf: 'admin', element: <AdminSpecial />, errorElement: <Error /> },
    { path: 'overview/allstudents/:id/delete', childOf: 'admin', element: <DeleteStudent />, errorElement: <Error /> },
    { path: 'overview/allteachers', childOf: 'admin', element: <AdminAllTeachers />, errorElement: <Error /> },
    { path: 'overview/allteachers/:id', childOf: 'admin', element: <AdminTeacherDetails />, errorElement: <Error /> },
    { path: 'overview/allteachers/:id/edit', childOf: 'admin', element: <AdminEdit />, errorElement: <Error /> },
    { path: 'overview/allteachers/:id/delete', childOf: 'admin', element: <AdminDelete />, errorElement: <Error /> },
    { path: 'overview/addcentre', childOf: 'admin', element: <AddCentre />, errorElement: <Error /> },
    { path: 'overview/classes', childOf: 'admin', element: <AdminClasses />, errorElement: <Error /> },
    { path: 'overview/classes/:Class', childOf: 'admin', element: <AdminClassDetails />, errorElement: <Error /> },
    { path: 'overview/classes/:Class/teacher/:id', childOf: 'admin', element: <AdminTeacherDetails />, errorElement: <Error /> },
    { path: 'overview/classes/:Class/teacher/:id/edit', childOf: 'admin', element: <AdminEdit />, errorElement: <Error /> },
    { path: 'overview/classes/:Class/teacher/:id/delete', childOf: 'admin', element: <AdminDelete />, errorElement: <Error /> },
    { path: 'overview/classes/:Class/students', childOf: 'admin', element: <AdminStudents />, errorElement: <Error /> },
    { path: 'overview/classes/:Class/students/:id', childOf: 'admin', element: <AdminStudentDetails />, errorElement: <Error /> },
    { path: 'overview/classes/:Class/students/:id/edit', childOf: 'admin', element: <EditStudent />, errorElement: <Error /> },
    { path: 'overview/classes/:Class/students/:id/special', childOf: 'admin', element: <AdminSpecial />, errorElement: <Error /> },
    { path: 'overview/classes/:Class/students/:id/delete', childOf: 'admin', element: <DeleteStudent />, errorElement: <Error /> },
    { path: 'overview/addclass', childOf: 'admin', element: <AddClass />, errorElement: <Error /> },
    { path: 'overview/editclass', childOf: 'admin', element: <AdminClasses />, errorElement: <Error /> },
    { path: 'overview/editclass/:Class', childOf: 'admin', element: <EditClass />, errorElement: <Error /> },
    { path: 'teachers', childOf: 'admin', element: <AdminClasses />, errorElement: <Error /> },
    { path: 'teachers/:Class', childOf: 'admin', element: <AdminTeachers />, errorElement: <Error /> },
    { path: 'teachers/:Class/:id', childOf: 'admin', element: <AdminTeacherDetails />, errorElement: <Error /> },
    { path: 'teachers/:Class/:id/edit', childOf: 'admin', element: <AdminEdit />, errorElement: <Error /> },
    { path: 'teachers/:Class/:id/delete', childOf: 'admin', element: <AdminDelete />, errorElement: <Error /> },
    { path: 'students', childOf: 'admin', element: <AdminClasses />, errorElement: <Error /> },
    { path: 'students/:Class', childOf: 'admin', element: <AdminStudents />, errorElement: <Error /> },
    { path: 'students/:Class/:id', childOf: 'admin', element: <AdminStudentDetails />, errorElement: <Error /> },
    { path: 'students/:Class/:id/edit', childOf: 'admin', element: <EditStudent />, errorElement: <Error /> },
    { path: 'students/:Class/:id/special', childOf: 'admin', element: <AdminSpecial />, errorElement: <Error /> },
    { path: 'students/:Class/:id/delete', childOf: 'admin', element: <DeleteStudent />, errorElement: <Error /> },
    { path: 'attendance', childOf: 'admin', element: <AdminClasses />, errorElement: <Error /> },
    { path: 'attendance/:Class', childOf: 'admin', element: <AdminAttendance />, errorElement: <Error /> },
    { path: 'special', parent: "special", childOf: 'admin', element: <Specials />, errorElement: <Error /> },
    { path: '/', childOf: 'special', element: <SpecialRegister />, errorElement: <Error /> },
    { path: 'registration', childOf: 'special', element: <SpecialRegister />, errorElement: <Error /> },
    { path: 'signin', childOf: 'special', element: <SpecialSignIn />, errorElement: <Error /> },
    { path: 'signin/confirm', childOf: 'special', element: <SpecialConfirmSignIn />, errorElement: <Error /> },
    { path: 'marks', childOf: 'admin', element: <PassMarksView />, errorElement: <Error /> },
    { path: 'marks/edit', childOf: 'admin', element: <PassMarksEdit />, errorElement: <Error /> },
    { path: 'offline', element: <Offline />, errorElement: <Error /> },
    { path: 'forbidden', element: <Forbidden />, errorElement: <Error /> },
    { path: '*', element: <NotFound />, errorElement: <Error /> },
  ];
  
  const routerElements = {
    path: '/',
    element: <Layout />,
    children: routes.map((route) => (route.childOf ? {} : {
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: <AnimatedRoute>{route.element}</AnimatedRoute>,
      loader: route.loader ? route.loader : undefined,
      action: route.action ? route.action : undefined,
      errorElement: route.errorElement ? route.errorElement : undefined,
      children: route.path === '/' ? undefined : routes.map((rout) => {
        if(rout.childOf === route.parent){
          return {
            index: rout.path === '/',
            path: rout.path === '/' ? undefined : rout.path,
            element: <AnimatedRoute>{rout.element}</AnimatedRoute>,
            loader: rout.loader ? rout.loader : undefined,
            action: rout.action ? rout.action : undefined,
            errorElement: rout.errorElement ? rout.errorElement : undefined,
            children: rout.path === '/' ? undefined : routes.map((routs) => {
              if(routs.childOf === rout.parent){
                return {
                  index: routs.path === '/',
                  path: routs.path === '/' ? undefined : routs.path,
                  element: <AnimatedRoute>{routs.element}</AnimatedRoute>,
                  loader: routs.loader ? routs.loader : undefined,
                  action: routs.action ? routs.action : undefined,
                  errorElement: routs.errorElement ? routs.errorElement : undefined
                }
              }else{
                return {};
              }
            })
          }
        }else{
          return {};
        }
      })
    })),
  };
  
  const router = createBrowserRouter([
    routerElements
  ]);

  return (
    <div className='App'>
      <AnimatePresence mode="wait">
        <RouterProvider router={router} />
      </AnimatePresence>
    </div>
  );
}