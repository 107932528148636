import React from 'react';
import { FaBars } from 'react-icons/fa6';
import { NavLink, Outlet, useOutletContext } from 'react-router-dom';
import { extractAllClasses, sortByProperty } from '../../AppManager';

function Specials() {
    const { record, setRecord, setPreventRefetch, maxStudents, currUser } = useOutletContext();
    const [classes, setClasses] = React.useState([]);

    React.useEffect(() => {
        setClasses(sortByProperty(extractAllClasses(record), "name"));
    }, [record]);

    React.useEffect(() => {
        document.title = "Special";
    }, []);

    React.useEffect(() => {
        setPreventRefetch(true);
    });

    return (
        <section className="attendanceform">
            <button className='text-btn attendance-nav-btn' style={{width: "auto", padding: "10px"}}><FaBars /></button>
            <ul id="optionswrapper">
                <li>
                    <NavLink className={({isActive}) => isActive ? "active" : null} to="registration">Registration</NavLink>
                </li>
                <li>
                    <NavLink className={({isActive}) => isActive ? "active" : null} to="signin">Sign-In</NavLink> 
                </li>
            </ul>
            <Outlet context={{ classes, record, setRecord, setPreventRefetch, maxStudents, currUser }} />
        </section>
    );
}

export default Specials;