import { MDBCarousel, MDBCarouselItem, MDBCarouselCaption } from 'mdb-react-ui-kit';
import React from 'react';
import { Link } from 'react-router-dom';
import ContactForm from './ContactForm';
import FindUs from './FindUs';

function Home() {
    React.useEffect(() => {
        document.title = "RUC Training Unit";
        function scrolled(){
            const nav = document.getElementById("navbar");
            nav.classList.add("fixed");
            document.body.scrollTop >= 80 || document.documentElement.scrollTop >= 80 ? nav.classList.remove("transparent") : nav.classList.add("transparent");
        }
        window.addEventListener("scroll", scrolled);
        scrolled();
        return () => {
            window.removeEventListener("scroll", scrolled);
        }
    }, []);

    return (
        <section className="home">
            <MDBCarousel showControls showIndicators interval={5000}>
                <h1 className='carousel_title'>RUC Training Unit</h1>
                <MDBCarouselItem itemId={1}>
                    <img src="/images/carousels/1.jpg" alt='...' />
                </MDBCarouselItem>
                <MDBCarouselItem itemId={2}>
                    <img src="/images/carousels/3.jpg" alt='...' />
                    <MDBCarouselCaption>
                        <i>Study to shew thyself approved unto God, a workman that needeth not to be ashamed, rightly dividing the word of truth.</i>
                        <h3 className='hide-title'>2 Timothy 2:15 (KJV)</h3>
                    </MDBCarouselCaption>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={3}>
                    <img src="/images/carousels/2.jpg" alt='...' />
                </MDBCarouselItem>
                <MDBCarouselItem itemId={4}>
                    <img src="/images/carousels/teacher.jpg" alt='...' />
                    <MDBCarouselCaption>
                        <i>But grow in grace, and in the knowledge of our Lord and Saviour Jesus Christ. To him be glory both now and for ever. Amen.</i>
                        <h3 className='hide-title'>2 Peter 3:18 (KJV)</h3>
                    </MDBCarouselCaption>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={5}>
                    <img src="/images/carousels/water_baptism.jpg" alt='...' />
                    <MDBCarouselCaption>
                        <i>Go ye therefore, and teach all nations, baptizing them in the name of the Father, and of the Son, and of the Holy Ghost</i>
                        <h3 className='hide-title'>Matthew 28:19 (KJV)</h3>
                    </MDBCarouselCaption>
                </MDBCarouselItem>
            </MDBCarousel>
            <div className="home-main">
                <div id='intro'>
                    <div>
                        <p>As part of the vision and mission of Redeemers’ University Chapel of Power, the Training Unit under the jurisdiction of the Bible Study Department of the church is saddled with the responsibilities of equipping members who intend to learn more or volunteer in joining the workforce through various designed and well-coordinated classes to facilitate the development process of such members.</p>
                        <p>The trainings are scheduled for one hour before Sunday’s Glorious Service by 06:45 a.m. to 07:45 a.m. These classes hold for a period of 6 weeks.</p>
                        <p>For enquiries, you may please contact the Training Unit <a href="#contact">here</a>.</p>
                    </div>
                    <div>
                        <img src="/images/believers class.png" alt="Believers Class" />
                        
                    </div>
                </div>
                <span className='classes-span'>
                    <div className="home-main-class">
                        <h1 className="heading1">Believers Class</h1>
                        <div className='home-class-info'>
                            <p>This is a class for those who have just given their lives to Christ or have given their life to Christ a long time ago but have never been taught how to live a victorious Christian life. Believers Class will provide you with the information you need to be an overcoming Christian.</p>
                        </div>
                        <div className='home-btn-wrapper'>
                            <button className="home-btn"><Link to='/believersclass'>Learn More</Link></button>
                            <button className="home-btn"><Link to='/register' state={{trainingClass: "Believers Class"}}>Join Class</Link></button>
                        </div>
                    </div>
                    <div className="home-main-class">
                        <h1 className="heading1">Baptismal Class</h1>
                        <div className='home-class-info'>
                            <p>Water Baptism is reserved for a person who consciously and knowingly understands his/her decision to receive and follow Jesus Christ as their Lord and Saviour. The word baptize comes from a Greek word "baptizo", which means to submerge or immerse.</p>
                        </div>
                        <div className='home-btn-wrapper'>
                            <button className="home-btn"><Link to='baptismalclass'>Learn More</Link></button>
                            <button className="home-btn"><Link to='/register' state={{trainingClass: "Baptismal Class"}}>Join Class</Link></button>
                        </div>
                    </div>
                    <div className="home-main-class">
                        <h1 className="heading1">Workers in Training</h1>
                        <div className='home-class-info'>
                            <p>This class is a call to higher ground for those who have given their lives to Christ and are taking a further step to commit to God on a higher level to work with God in His vineyard. It is a call to a life of loyalty to the Lord. It involves a regular training scheme</p>
                        </div>
                        <div className='home-btn-wrapper'>
                            <button className="home-btn"><Link to='workersintraining'>Learn More</Link></button>
                            <button className="home-btn"><Link to='/register' state={{trainingClass: "Workers in Training"}}>Join Class</Link></button>
                        </div>
                    </div>
                </span>
                <div id="home-findus">
                    <FindUs />
                </div>
                <div id="contact">
                    <ContactForm />
                </div>
            </div>
        </section>
    );
}

export default Home;