import React from 'react'
import { FaPen } from 'react-icons/fa6';
import { Link, useOutletContext } from 'react-router-dom';

export default function PassMarksView() {
    const { passMarks } = useOutletContext();

    return (
        <div>
            <h1 className="heading1" style={{margin: "15px 0 -15px 0"}}>Pass Marks</h1>
            <div className="details-card-wrapper">
                {!passMarks?.believers && <div className="loader-item" style={{ width: "220px", height: "280px" }}>
                    <div className="ruc-watermark">
                        <div>
                            <img src="/images/Bible Study Icon.png" alt="RUC Training Unit" />
                            <p>RUC Training Unit</p>
                        </div>
                    </div>
                </div>}
                {passMarks?.believers && <div className={`details-card rotate`}>
                    <div className='back'>
                        <div>
                            <h3>Believers Class</h3>
                            <span>{passMarks.believers}%</span>
                        </div>
                        <div>
                            <h3>Baptismal Class</h3>
                            <span>{passMarks.baptismal}%</span>
                        </div>
                        <div>
                            <h3>Workers in Training</h3>
                            <span>{passMarks.workers}%</span>
                        </div>
                        <div>
                            <span><Link to="edit" style={{textDecoration: "none"}}><FaPen style={{fontSize: "0.9em"}} /> Edit</Link></span>
                        </div>
                    </div>
                    <div className="ruc-watermark">
                        <div>
                            <img src="/images/Bible Study Icon.png" alt="RUC Training Unit" />
                            <p>RUC Training Unit</p>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}
