import React from 'react';
import { FaEnvelope, FaMessage, FaPaperPlane, FaPen, FaSpinner, FaUser, FaWhatsapp } from 'react-icons/fa6';
import { capitalizeEachWord, contactFormMail, getContactInfo } from '../AppManager';
import { LiaTimesCircle } from 'react-icons/lia';
import { Link } from 'react-router-dom';
import { getCookie, getCurrUser } from '../Auth';

function ContactForm() {
    const [formData, setFormData] = React.useState({name: "", email: "", message: ""});
    const [contact, setContact] = React.useState({ phone: [{"name":"Paul","phone":"8142644065"},{"name":"Joshua","phone":"8103182378"}], email: "rucbiblestudy@gmail.com" });
    const [status, setStatus] = React.useState({status: "", type: "", message: ""});
    const [loading, setLoading] = React.useState(false);
    const [currUser, setCurrUser] = React.useState({ class: '', position: 'none', matric: '', centre: '' });
    const position = getCookie("nd38h4nnue2");

    React.useEffect(() => {
        getCurrUser()
        .then(data => setCurrUser(data))
        .catch(err => err);
    }, [position]);

    function handleChange(e){
        const {name, value} = e.target;
        setFormData(oldFormData => {
            return {...oldFormData, [name]: name === "name" ? capitalizeEachWord(value) : value};
        })
    }

    function handleSubmit(e){
        e.preventDefault();
        setLoading(true);
        try {
            const success = {status: "success", type: "success", message: "Message Sent"};
            const fail = {status: "failed", type: "failed", message: "Message not Sent"};
            const reset = {name: "", email: "", message: ""};
            const email = contact.email ? contact.email : "rucbiblestudy@gmail.com";
            contactFormMail(email, formData.name, formData.email, formData.message, setStatus, setFormData, setLoading, success, fail, reset);
        } catch (error) {
            setStatus({status: "failed", type: "failed", message: "Message not Sent"});
            setLoading(false);
        }
    }

    React.useEffect(() => {
        getContactInfo()
        .then(data => setContact(data))
        .catch(err => err);
    }, []);

    React.useEffect(() => {
        document.title = "Contact Us";
    }, []);

    const numbers = contact.phone.map((pho, index) => {
        return <span key={index}>
            <h1>
                { pho.name }
            </h1>
            <p><a href={`https://wa.me/234${pho.phone}?text=Hello, I obtained your number from the RUC Training Unit website's Contact Us section. My name is `} target='_blank' rel='noreferrer'>+234 {pho.phone.slice(0, 3)} {pho.phone.slice(3, 6)} {pho.phone.slice(6)}</a></p>
        </span>;
    });

    return (
        <section className="contactform" id='contactform'>
            <div className="formwrapper">
                <div className="wrapper" style={{height: status.message !== "" ? "450px" : "430px"}}>
                    <div className="form-box login">
                        <h2>Contact Us</h2>
                        <form onSubmit={handleSubmit}>
                            {status.message !== "" && <p className='status-wrapper' id="status-wrapper"><span className={`status-message ${status.type}`}>{status.message}</span><LiaTimesCircle className='status-close' onClick={() => {setStatus({status: "", type: "", message: ""})}}/></p>}
                            <div className="input-box">
                                <span className="icon"><FaUser /></span>
                                <input readOnly={loading} placeholder=" " name='name' onChange={handleChange} value={formData.name} maxLength={20} pattern="[A-Za-z\- ]+" type="text" autoComplete="off" required />
                                <label>Name</label>
                            </div>
                            <div className="input-box">
                                <span className="icon"><FaEnvelope /></span>
                                <input readOnly={loading} placeholder=" " name='email' onChange={handleChange} value={formData.email} maxLength={30} type="email" autoComplete="off" required />
                                <label>E-Mail</label>
                            </div>
                            <div className="input-box">
                                <span className="icon"><FaMessage /></span>
                                <textarea readOnly={loading} placeholder=" " name='message' onChange={handleChange} value={formData.message} maxLength={500} rows={5} className='inputs' required />
                                <label>Message</label>
                            </div>
                            <button type="submit" className="btn" disabled={loading}>{loading ? <FaSpinner className='spinner' /> : <><FaPaperPlane /> Send Message</>}</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className='lines'>
                <div className='line'>
                    {!contact?.email && <div className="loader-item" style={{ width: "210px", height: "270px", background: "linear-gradient(to top right, #F4F6FF 0%, #FFF 100%)", backgroundSize: "200% 100%", boxShadow: "0 5px 8px rgba(0, 0, 0, 0.4)" }}>
                        <div className="ruc-watermark">
                            <div>
                                <img src="/images/Bible Study Icon.png" alt="RUC Training Unit" />
                                <p style={{ color: "#002F63" }}>RUC Training Unit</p>
                            </div>
                        </div>
                    </div>}
                    {contact?.email && <span>
                        {currUser.position === "admin" && <Link to="/editcontact"><FaPen /></Link>}
                        <h1>
                            <FaWhatsapp /> Mobile and Whatsapp Lines
                        </h1>
                        <div>
                            {numbers}
                        </div>
                        <span className='line-email'>
                            <h1>
                                <FaEnvelope /> E-Mail
                            </h1>
                            <div>
                                <p><a href={`mailto: ${contact.email}`} target='_blank' rel='noreferrer'>{contact.email}</a></p>
                            </div>
                        </span>
                    </span>}
                </div>
            </div>
        </section>
    );
}

export default ContactForm;