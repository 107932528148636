import React from 'react';
import { FaChalkboardUser, FaChurch, FaEnvelope, FaIdCard, FaImage, FaLocationDot, FaSchool, FaSpinner } from 'react-icons/fa6';
import { LiaCheckCircle, LiaTimesCircle } from 'react-icons/lia';
import { allTrainingClasses, capitalizeEachWord, checkExtension, classRegistration, cleanMatric, cleanName, getCentresDirect, stripSpecialChar } from '../../AppManager';
import { useOutletContext } from 'react-router-dom';
import { nanoid } from "nanoid";

function AddClass() {
    const { record, setRecord } = useOutletContext();
    const [formData, setFormData] = React.useState({centre: "", trainingClass: "", name: "", teacher: "", "teacher matric": "", "assistant matric": "", "assistant teacher": "", "teacher img": false, "assistant img": false, "teacher email": "", "assistant email": "", location: ""});
    const [centres, setCentres] = React.useState([]);
    const [imgErr, setImgErr] = React.useState({name: "", reason: "", status: true});
    const [status, setStatus] = React.useState({status: "", type: "", message: ""});
    const [uprogress, setUprogess] = React.useState(0);
    const [ustate, setUstate] = React.useState("");
    const [uploading, setUploading] = React.useState(false);

    function handleChange(e){
        const { name, value, type, files } = e.target;
        setFormData(oldFormData => {
            if(type === "file" && files[0]){
                if(files[0].size <= 512000 && checkExtension(files[0].name)){
                    setImgErr({name: "", reason: "", status: true});
                    return {...oldFormData, [name]: files[0] };
                }else{
                    if(files[0].size > 512000){
                        setImgErr({name, reason: "size", status: false});
                    }else if(!checkExtension(files[0].name)){
                        setImgErr({name, reason: "format", status: false});
                    }
                }
                return oldFormData;
            }else{
                return {...oldFormData, [name]: name === "teacher" || name === "assistant teacher" || name === "name" ? cleanName(capitalizeEachWord(value)) : name === "teacher matric" || name === "assistant matric" ? cleanMatric(value) : value};
            }
        });
    }

    async function handleSubmit(e){
        e.preventDefault();
        try {
            setUploading(true);
            const regData = { name: formData.name, centre: formData.centre, trainingClass: formData.trainingClass, teacher: formData.teacher, teacherMatric: formData['teacher matric'], assistantTeacher: formData['assistant teacher'], assistantImage: formData['assistant img'], teacherImage: formData['teacher img'], assistantMatric: formData['assistant matric'], assistantEmail: formData['assistant email'], teacherEmail: formData['teacher email'], location: formData.location };
            const res = await classRegistration(regData);
            if(res === "Exists"){
                setStatus({status: "exists", type: "failed", message: "Class already Exists"});
                setUploading(false);
                return null;
            }else if(res === "Teacher E-Mail"){
                setStatus({status: "e-mail", type: "failed", message: "Teacher E-Mail Taken"});
                setUploading(false);   
                return null;
            }else if(res === "Assistant E-Mail"){
                setStatus({status: "e-mail", type: "failed", message: "Assistant E-Mail Taken"});
                setUploading(false);
                return null;
            }else if(res === "Registered"){
                const imgUpload = typeof(formData['teacher img']) === "object" || typeof(formData['assistant img']) === "object";
                if(imgUpload){
                    setUstate('Uploading...');
                    const interv = setInterval(() => {
                        setUprogess(oldProgress => {
                            if(oldProgress >= 100){
                                clearInterval(interv);
                            }
                            const rand = Math.floor(Math.random() * 25 + 15);
                            const add = oldProgress + rand;
                            return add > 100 ? 100 : add;
                        });
                    }, 500);
                }

                const assistantdownloadURL = typeof(formData['assistant img']) === "object" ? stripSpecialChar(formData['assistant matric']) + formData['assistant img'].name.slice(formData['assistant img'].name.lastIndexOf(".")) : "user.png";
                const teacherdownloadURL = typeof(formData['teacher img']) === "object" ? stripSpecialChar(formData['teacher matric']) + formData['teacher img'].name.slice(formData['teacher img'].name.lastIndexOf(".")) : "user.png";
                const newClass = { name: formData.name, teacher: formData.teacher, "teacher matric": formData['teacher matric'], "assistant teacher": formData['assistant teacher'], "assistant image": assistantdownloadURL, "teacher image": teacherdownloadURL, "assistant matric": formData['assistant matric'], students: "0", admission: true, currentClass: true, location: formData.location };
                const newClasses = record[formData.centre][formData.trainingClass] && record[formData.centre][formData.trainingClass].classes ? [...record[formData.centre][formData.trainingClass].classes] : [];
                newClasses.push(newClass);
    
                setRecord(oldRecord => {
                    return {...oldRecord, [formData.centre]: {...oldRecord[formData.centre], [formData.trainingClass]: {...oldRecord[formData.centre][formData.trainingClass], classes: newClasses}}}
                })

                setTimeout(() => {
                    setUprogess(0);
                    setStatus({status: "success", type: "success", message: "Class Created"});
                    setTimeout(() => {
                        setFormData(oldFormData => ({...oldFormData, centre: "", trainingClass: "", name: "", teacher: "", "teacher matric": "", "assistant matric": "", "assistant teacher": "", "teacher email": "", "assistant email": "", location: ""}));
                        setUploading(false);   
                    }, 500);
                }, imgUpload ? 3000 : 1000);

            }else{
                setStatus({status: "error", type: "failed", message: "Class Creation Failed"});
                setUploading(false);
                return null;
            }
        } catch (error) {
            setStatus({status: "error", type: "failed", message: "Creation Failed"});
            setUploading(false);
        }
    }

    React.useEffect(() => {
        if(status.message !== ""){
            document.getElementById("status-wrapper").scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [status]);

    React.useEffect(() => {
        getCentresDirect()
        .then(data => setCentres(data))
        .catch(err => err);
    }, []);

    React.useEffect(() => {
        document.title = "Add Class";
    }, []);

    const centreElem = centres.map(centre => <option key={nanoid()} value={centre}>{centre}</option>);
    const selectTclasses = allTrainingClasses.map(tclass => <option key={nanoid()} value={tclass}>{tclass}</option>);

    return (
        <div className="formwrapper" style={{margin: "50px 0"}}>
            <div className="wrapper" style={uploading ? {height: status.message !== "" ? "1220px" : "1180px"} : {height: status.message !== "" ? "1180px" : "1130px"}}>
                <div className="form-box login">
                    <h2>Add Class</h2>
                    <form onSubmit={handleSubmit}>
                        {status.message !== "" && <p className='status-wrapper' id="status-wrapper"><span className={`status-message ${status.type}`}>{status.message}</span><LiaTimesCircle className='status-close' onClick={() => {setStatus({status: "", type: "", message: ""})}}/></p>}
                        <div className="input-box">
                            <span className="icon"><FaSchool /></span>
                            <input readOnly={uploading} type="text" onChange={handleChange} value={formData.name} autoComplete="off" required pattern='[A-Za-z\- ]+' name="name" placeholder=" " minLength={3} maxLength={20} />
                            <label>Class Name</label>
                        </div>
                        <div className="input-box">
                            <select disabled={uploading} name="centre" className='inputs' required onChange={handleChange} value={formData.centre}>
                                <option value="" disabled>Choose one...</option>
                                {centreElem}
                            </select>
                            <label><FaChurch /> Centre</label>
                        </div>
                        <div className="input-box">
                            <select disabled={uploading} name='trainingClass' value={formData.trainingClass} onChange={handleChange} className="inputs" required>
                                <option value="" disabled>Choose one...</option>
                                {selectTclasses}
                            </select>
                            <label><FaSchool /> Training Class</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaLocationDot /></span>
                            <input readOnly={uploading} type="text" onChange={handleChange} value={formData.location} autoComplete="off" required pattern='[A-Za-z0-9\- ]+' name="location" placeholder=" " minLength={3} maxLength={50} />
                            <label>Location</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaChalkboardUser /></span>
                            <input readOnly={uploading} type="text" onChange={handleChange} value={formData.teacher} autoComplete="off" required name="teacher" pattern="[A-Za-z\- ]+" placeholder=" " minLength={7} maxLength={25} />
                            <label>Teacher</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaIdCard /></span>
                            <input readOnly={uploading} placeholder=" " type="text" name='teacher matric' style={{textTransform: "uppercase"}} value={formData['teacher matric']} onChange={handleChange} autoComplete="off" pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/[A-Za-z]{3}/\d{2}/\d{4,5}$|(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/(FD|fd|Fd|fD)/\d{2}/\d{4,5}$|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$" title='RUN/xxx/yy/yyyy' required />
                            <label>Teacher Matric Number</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaEnvelope /></span>
                            <input readOnly={uploading} placeholder=" " type="email" name='teacher email' value={formData["teacher email"]} minLength={13} maxLength={36} onChange={handleChange} autoComplete="off" required />
                            <label>Teacher E-Mail</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaImage /></span>
                            <input readOnly={uploading} type="file" onChange={handleChange} name="teacher img" />
                            <label>Teacher</label>
                            {imgErr.name === 'teacher img' && imgErr.reason === "size" && !imgErr.status && <p>Max Size Exceeded</p>}
                            {imgErr.name === 'teacher img' && imgErr.reason === "format" && !imgErr.status && <p>Invalid File Format</p>}
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaChalkboardUser /></span>
                            <input readOnly={uploading} type="text" onChange={handleChange} value={formData['assistant teacher']} autoComplete="off" required name="assistant teacher" pattern="[A-Za-z\- ]+" placeholder=" " minLength={7} maxLength={25} />
                            <label>Assistant Teacher</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaIdCard /></span>
                            <input readOnly={uploading} placeholder=" " type="text" name='assistant matric' style={{textTransform: "uppercase"}} value={formData['assistant matric']} onChange={handleChange} autoComplete="off" pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/[A-Za-z]{3}/\d{2}/\d{4,5}$|(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/(FD|fd|Fd|fD)/\d{2}/\d{4,5}$|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$" title='RUN/xxx/yy/yyyy' required />
                            <label>Assistant Teacher Matric</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaEnvelope /></span>
                            <input readOnly={uploading} placeholder=" " type="email" name='assistant email' value={formData["assistant email"]} minLength={13} maxLength={36} onChange={handleChange} autoComplete="off" required />
                            <label>Assistant E-Mail</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaImage /></span>
                            <input readOnly={uploading} type="file" onChange={handleChange} name="assistant img" />
                            <label>Assistant Teacher</label>
                            {imgErr.name === 'assistant img' && imgErr.reason === "size" && !imgErr.status && <p>Max Size Exceeded</p>}
                            {imgErr.name === 'assistant img' && imgErr.reason === "format" && !imgErr.status && <p>Invalid File Format</p>}
                        </div>
                        <button type="submit" disabled={!imgErr.status || uploading} className='btn'>{uploading ? uprogress === 100 ? "Finishing..." : <FaSpinner className='spinner' /> : "Create"}</button>
                        {uploading && uprogress !== 0 && <div className="upload-status">
                            <div id='upload-progress'>
                                <div id='upload-progress-bar' className='loader-item' style={{width: uprogress + "%", backgroundImage: uprogress !== 100 ? "linear-gradient(110deg, #002F63 8%, #1257a7 18%, #002F63 33%)" : "linear-gradient(110deg, green 8%, yellowgreen 18%, green 33%)"}}></div>
                                <label>{Math.floor(uprogress)}%</label>
                            </div>
                            <div style={{fontSize: "0.8em", fontFamily: "Georgia, 'Times New Roman', Times, serif"}}>{uprogress === 100 ? <span style={{display: "flex", alignItems: "center", fontFamily: "Georgia, 'Times New Roman', Times, serif"}}><LiaCheckCircle style={{fontSize: "1.2em", marginRight: "2px", color: "green"}} />Upload Completed</span> : ustate}</div>
                        </div>}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddClass;