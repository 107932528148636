import React from 'react'
import ClassCard from '../../components/ClassCard'

export default function ChapelClasses() {
    return (
        <>
            <h2 className="heading2">Chapel Classes Location</h2>
            <div>
                <ClassCard name={"Believers Class"} to={""} image="/images/lecture_rooms.jpg" />
                <span><strong>Location:</strong> Lecture Room 2 - 8, 24</span>
            </div>
            <div>
                <ClassCard name={"Baptismal Class"} to={""} image="/images/lecture_rooms.jpg" />
                <span><strong>Location:</strong> Lecture Room 9 - 17</span>
            </div>
            <div>
                <ClassCard name={"Workers in Training"} to={""} image="/images/lecture_rooms.jpg" />
                <span><strong>Location:</strong> Lecture Room 18 - 23</span>
            </div>
        </>
    )
}
