import React from 'react'
import { FaEnvelope, FaPhoneFlip, FaSpinner, FaUser } from 'react-icons/fa6';
import { LiaTimesCircle } from 'react-icons/lia';
import { getCurrUser } from '../Auth';
import { redirect, useLoaderData } from 'react-router-dom';
import { getContactInfo, setContactInfo } from '../AppManager';


export async function loader(){
    const currUser = await getCurrUser();
    if(currUser.position !== "admin"){
        return redirect("/forbidden");
    }
    const contact = await getContactInfo();
    return contact;
}

export default function EditContact() {
    const contact = useLoaderData();
    const [formData, setFormData] = React.useState({ contact1name: "", contact1phone: "", contact2name: "", contact2phone: "", email: "" });
    const [loading, setLoading] = React.useState(false);
    const [status, setStatus] = React.useState({status: "", type: "", message: ""});

    function handleChange(e){
        const { name, value } = e.target;
        setFormData(oldFormData => ({...oldFormData, [name]: value }));
    }

    async function handleSubmit(e){
        e.preventDefault();
        setLoading(true);
        try {
            const data = { phone: JSON.stringify([{ name: formData.contact1name, phone: formData.contact1phone }, { name: formData.contact2name, phone: formData.contact2phone }]), email: formData.email }
            const res = await setContactInfo(data);
            if(res === "Updated"){
                setStatus({status: "success", type: "success", message: "Contacts Updated"});
                setLoading(false);
                setFormData("");
            }else{
                setStatus({status: "error", type: "failed", message: "Update Failed"});
                setLoading(false);
            }
        } catch (error) {
            setStatus({status: "error", type: "failed", message: "Update Failed"});
            setLoading(false);
        }
    }

    React.useEffect(() => {
        setFormData(() => {
            const newFormData = { contact1name: "", contact1phone: "", contact2name: "", contact2phone: "", email: "" };
            newFormData.email = contact.email;
            for (let i = 0; i < contact.phone.length; i++) {
                const pho = contact.phone[i];
                if(i === 0){
                    newFormData.contact1name = pho.name;
                    newFormData.contact1phone = pho.phone;
                }else{
                    newFormData.contact2name = pho.name;
                    newFormData.contact2phone = pho.phone;
                }
            };
            return newFormData;
        });
    }, [contact]);

    React.useEffect(() => {
        if(status.message !== ""){
            document.getElementById("status-wrapper").scrollIntoView({ behavior: "smooth", block: "center" });
        }

        document.title = "Edit Contact";
    }, [status]);
    
    return (
        <div className="formwrapper" style={{margin: "50px 0"}}>
            <div className="wrapper" style={{height: "600px"}}>
                <div className="form-box login">
                    <h2>Edit Contacts</h2>
                    <form onSubmit={handleSubmit}>
                        {status.message !== "" && <p className='status-wrapper' id="status-wrapper"><span className={`status-message ${status.type}`}>{status.message}</span><LiaTimesCircle className='status-close' onClick={() => {setStatus({status: "", type: "", message: ""})}}/></p>}
                        <div className="input-box">
                            <span className="icon"><FaUser /></span>
                            <input readOnly={loading} type="text" onChange={handleChange} value={formData.contact1name} autoComplete="off" required min={3} max={20} name="contact1name" placeholder=" " />
                            <label>Contact 1 Name</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaPhoneFlip /></span>
                            <input readOnly={loading} type="number" onChange={handleChange} value={formData.contact1phone} autoComplete="off" required minLength={10} maxLength={10} name="contact1phone" placeholder=" " />
                            <label>Contact 1 Phone</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaUser /></span>
                            <input readOnly={loading} type="text" onChange={handleChange} value={formData.contact2name} autoComplete="off" required min={3} max={20} name="contact2name" placeholder=" " />
                            <label>Contact 2 Name</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaPhoneFlip /></span>
                            <input readOnly={loading} type="number" onChange={handleChange} value={formData.contact2phone} autoComplete="off" required minLength={10} maxLength={10} name="contact2phone" placeholder=" " />
                            <label>Contact 2 Phone</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaEnvelope /></span>
                            <input readOnly={loading} type="email" onChange={handleChange} value={formData.email} autoComplete="off" required minLength={13} maxLength={50} name="email" placeholder=" " />
                            <label>E-Mail</label>
                        </div>
                        <button type="submit" disabled={loading} className='btn'>{loading ? <FaSpinner className='spinner' /> : "Update"}</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
