import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { calcDisplayNo, getAllClassRecords, sortMonths } from '../../AppManager';
import AttendanceCard from './AttendanceCard';
import Paginate from '../../components/Paginate';
import EmptySearch from '../../components/EmptySearch';

function AdminAttendance() {
    const { record, search, setShowSearch, setPreventRefetch } = useOutletContext();
    const [data, setData] = React.useState([]);
    const { Class } = useParams();
    const [itemsPerPage, setItemsPerPage] = React.useState(5);
    const [attendanceFetch, setAttendanceFetch] = React.useState(false);

    React.useEffect(() => {
        const width = 210;
        const height = 160;
        const pagePadMarginWidth = 20;
        const pagePadMarginHeight = 20;
        setItemsPerPage(calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight));
        window.addEventListener("resize", () => {
            setItemsPerPage(calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight));
        })
        
        return () => {
            window.removeEventListener("resize", () => {
                setItemsPerPage(calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight));
            })
        }
    }, []);

    React.useEffect(() => {
        getAllClassRecords(Class)
        .then(data => {
            setData(data);
            setAttendanceFetch(true);
        })
        .catch(err => err);
    }, [Class, record]);
    
    React.useEffect(() => {
        setShowSearch(true);
        setPreventRefetch(true);
    });
    
    const stripClass = data.map(dat => {
        return dat.slice(0, dat.toLowerCase().indexOf(Class.toLowerCase()) - 1);
    })

    const toSort = stripClass.filter(dat => dat !== "Current");

    const sorted  = sortMonths(toSort);

    if(stripClass.includes("Current")){
        sorted.unshift("Current");
    }

    React.useEffect(() => {
        document.title = `${Class} Attendance`;
    }, [Class]);

    function AttendanceLoader(){
        return <>
            <div className="loader-item" style={{ width: "200px", height: "140px", background: "linear-gradient(to top right, #F4F6FF 0%, #FFF 100%)", backgroundSize: "200% 100%", boxShadow: "0 5px 8px rgba(0, 0, 0, 0.4)", borderRadius: "10px", margin: "10px 5px" }}></div>
            <div className="loader-item" style={{ width: "200px", height: "140px", background: "linear-gradient(to top right, #F4F6FF 0%, #FFF 100%)", backgroundSize: "200% 100%", boxShadow: "0 5px 8px rgba(0, 0, 0, 0.4)", borderRadius: "10px", margin: "10px 5px" }}></div>
            <div className="loader-item" style={{ width: "200px", height: "140px", background: "linear-gradient(to top right, #F4F6FF 0%, #FFF 100%)", backgroundSize: "200% 100%", boxShadow: "0 5px 8px rgba(0, 0, 0, 0.4)", borderRadius: "10px", margin: "10px 5px" }}></div>
        </>
    }

    const classes = sorted.filter(dat => (dat.toLowerCase().indexOf(search.toLowerCase()) > -1)).map(dat => <AttendanceCard key={nanoid()} name={dat} Class={Class} />);

    return (
        <div className="overview-students">
            <h1 className="heading1" style={{width: "100vw"}}>{Class} Attendance</h1>
            {
                attendanceFetch
                ?
                    classes.length === 0
                    ?
                    <EmptySearch message={"No Attendance Found"} />
                    :
                    <Paginate itemsPerPage={itemsPerPage} items={classes} />
                :
                <AttendanceLoader />
            }
        </div>
    );
}

export default AdminAttendance;