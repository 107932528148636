import React from 'react'
import { FaEye, FaEyeSlash, FaHandshake, FaLock, FaSpinner } from 'react-icons/fa6';
import { LiaTimesCircle } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';
import { passwordStrength } from '../AppManager';
import { baseURL } from '../Auth';
import axios from 'axios';

export default function AdminReg() {
    const navigate = useNavigate();
    const [formData, setFormData] = React.useState({password: "", confirmpassword: ""});
    const [status, setStatus] = React.useState({status: "", type: "", message: ""});
    const [loading, setLoading] = React.useState(false);
    const [showp, setShowp] = React.useState(false);
    const [redirection, setRedirection] = React.useState("");
    const [checkProgress, setCheckProgress] = React.useState({strength: "0%", color: "red", upper: false, lower: false, special: false, length: false, number: false});
    const [mismatch, setMismatch] = React.useState(false);

    React.useEffect(() => {
        if(redirection !== ""){
            navigate(redirection.path, redirection.options);
        }
    }, [redirection, navigate]);

    function handleChange(e){
        const { name, value } = e.target;
        setFormData(oldFormData => {
            const newFormData = {...oldFormData, [name]: value}
            setMismatch(newFormData.password !== newFormData.confirmpassword && newFormData.confirmpassword.length > 0 ? true : false);
            setCheckProgress(passwordStrength(newFormData.password));
            return newFormData;
        });
    }

    async function send(formData){
        try {
            const { password, confirmpassword } = formData;
            const data = { password, confirmpassword, admin_registration: "wjnfkwjefwj" };
            let formdata = new FormData();
            for(var key in data){
                formdata.append(key, data[key]);
            }
            const url = baseURL + "php/registration.php";
            const send = await axios.post(url, formdata, {
                mode: "cors",
            });
            return send.data;
        } catch (error) {
            return "Failed";
            // return error;
        }
    }

    function success(){
        setStatus({status: "success", type: "success", message: "Password Set. Redirecting..."});
        setTimeout(() => {
            setRedirection({path: "/login/admin", options: {replace: true}});
        }, 1000);
    }

    async function handleSubmit(e){
        e.preventDefault();
        try {
            if(formData.password !== formData.confirmpassword){
                setStatus({status: "Incorrect", type: "failed", message: "Password Mismatch"});
                setLoading(false);
                return null;
            }
            setLoading(true);
            const res = await send(formData);
            if(res === "Success"){
                success();
            }else if(res === "Mismatch"){
                setStatus({status: "Incorrect", type: "failed", message: "Password Mismatch"});
                setLoading(false);
            }else if(res === "Already"){
                setStatus({status: "Incorrect", type: "failed", message: "Password already Set"});
                setLoading(false);
            }else{
                setStatus({status: "error", type: "failed", message: "Registration Failed"});
                setLoading(false);
            }  
        } catch (error) {
            setStatus({status: "error", type: "failed", message: "Registration Failed"});
            setLoading(false);
        }
    }

    function toggleShow(){
        setShowp(oldShowp => !oldShowp);
    }

    React.useEffect(() => {
        if(status.message !== ""){
            document.getElementById("status-wrapper").scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [status]);

    React.useEffect(() => {
        document.title = "Set Password";
    }, []);

    return (
        <section className="contactform loginform adminreg">
            <div className="formwrapper">
                <div className={`wrapper`} style={{height: status.message !== "" ? "360px" : "330px"}}>
                    <div className="form-box login">
                        <h2>Set Password</h2>
                        <form onSubmit={handleSubmit}>
                            {status.message !== "" && <p className='status-wrapper' id="status-wrapper"><span className={`status-message ${status.type}`}>{status.message}</span><LiaTimesCircle className='status-close' onClick={() => {setStatus({status: "", type: "", message: ""})}}/></p>}
                            <div className="input-box" style={{borderBottom: `2px solid ${mismatch ? "red" : "#002F63"}`}}>
                                <span className="icon">
                                    {!showp && <FaEye style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                    {showp && <FaEyeSlash style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                </span>
                                <input readOnly={loading} placeholder=" " maxLength={30} type={showp ? "text" : "password"} name='password' pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}" className='password-input' value={formData.password} autoComplete="off" onChange={handleChange} required />
                                <label><FaLock /> Password</label>
                            </div>
                            <div className="input-box" style={{borderBottom: `2px solid ${mismatch ? "red" : "#002F63"}`}}>
                                <span className="icon">
                                    {!showp && <FaEye style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                    {showp && <FaEyeSlash style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                </span>
                                <input readOnly={loading} placeholder=" " maxLength={30} type={showp ? "text" : "password"} name='confirmpassword' value={formData.confirmpassword} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}" className='password-input' autoComplete="off" onChange={handleChange} required />
                                <label><FaHandshake /> Confirm Password</label>
                            </div>
                            <span className='password-note'>
                                <div id='strength'><div id='strength-bar' style={{width: checkProgress.strength, backgroundColor: checkProgress.color}}></div></div>
                                <i>
                                    At Least:
                                    <ul>
                                        <li className={checkProgress.upper ? "complete" : null}><span> 1 Upper Case</span></li>
                                        <li className={checkProgress.lower ? "complete" : null}><span> 1 Lower Case</span></li>
                                        <li className={checkProgress.number ? "complete" : null}><span> 1 Number</span></li>
                                        <li className={checkProgress.special ? "complete" : null}><span> 1 Special Character</span></li>
                                        <li className={checkProgress.length ? "complete" : null}><span> 6 Characters</span></li>
                                    </ul>
                                </i>
                            </span>
                            <button type="submit" className="btn" disabled={loading}>{loading ? <FaSpinner className='spinner' /> : "submit"}</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}
