import React from 'react';
import { FaEye, FaEyeSlash, FaIdCard, FaLock, FaPaperPlane, FaSpinner } from 'react-icons/fa6';
import { LiaTimesCircle } from 'react-icons/lia';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { forgottenPassword, getCookie, login, removeCookie } from '../../Auth';
import { passwordResetMail } from '../../AppManager';

function Student() {
    const params = useSearchParams()[0];
    const redirectTo = params.get('redirectTo');
    const message = params.get('message');
    const navigate = useNavigate();
    const [forgotPage, setForgotPage] = React.useState(false);
    const [formData, setFormData] = React.useState(() => {
        return {
            matric: getCookie("mkoe2ojoi4o") !== null ? getCookie("mkoe2ojoi4o") : "", 
            password: getCookie("ndi2n4r5i983nf") !== null ? getCookie("ndi2n4r5i983nf") : "", 
            remember: getCookie("mkoe2ojoi4o") !== null ? true : false
        }
    });
    const [forgotMatric, setForgotMatric] = React.useState("");
    const [status, setStatus] = React.useState({status: "", type: "", message: message ? message : ""});
    const [loading, setLoading] = React.useState(false);
    const [showp, setShowp] = React.useState(false);
    const [redirection, setRedirection] = React.useState("");

    React.useEffect(() => {
        if(redirection !== ""){
            navigate(redirection.path, redirection.options);
        }
    }, [redirection, navigate]);

    function handleChange(e){
        const { name, type, value, checked } = e.target;
        setFormData(oldFormData => {
            return {...oldFormData, [name]: type === "checkbox" ? checked : value}
        });
    }

    function success(){
        setStatus({status: "success", type: "success", message: "Log-In Successful. Redirecting..."});
        if(!formData.remember){
            setFormData({matric: "", password: "", remember: ""});
        }
        setTimeout(() => {
            if(redirectTo){
                setRedirection({path: redirectTo});
            }else{
                setRedirection({path: "/profile"});
            }
        }, 1000);
    }

    async function handleSubmit(e){
        e.preventDefault();
        try {
            setLoading(true);
            if(formData.matric === getCookie("mkoe2ojoi4o") && !formData.remember){
                removeCookie("mkoe2ojoi4o");
                removeCookie("ndi2n4r5i983nf");
            }
            const res = await login(formData.matric, "student", formData.password, formData.remember);
            if(res === "Success"){
                success();
            }else if(res === "Incorrect"){
                setStatus({status: "Incorrect", type: "failed", message: "Matric/Password Mismatch"});
                setLoading(false);
            }else{
                setStatus({status: "error", type: "failed", message: "Login Failed"});
                setLoading(false);
            }  
        } catch (error) {
            setStatus({status: "error", type: "failed", message: "Login Failed"});
            setLoading(false);
        }
    }

    function toggleShow(){
        setShowp(oldShowp => !oldShowp);
    }

    function handleForgotChange(e){
        const { name, value } = e.target;
        if(name === "forgotmatric"){
            setForgotMatric(value);
        }
    }

    async function handleForgotSubmit(e){
        e.preventDefault();
        setLoading(true);
        try {
            const res = await forgottenPassword(forgotMatric, "student");
            if(res[0] === "Success"){
                const success = {status: "success", type: "success", message: "Password Reset Link sent to E-Mail"};
                const fail = {status: "failed", type: "failed", message: "Request Failed"};
                const reset = "";
                passwordResetMail(res[1], res[2], res[3], setStatus, setForgotMatric, setLoading, success, fail, reset);
            }else if(res[0] === "Not Found"){
                setStatus({status: "Not Found", type: "failed", message: "Matric not Registered"});
                setLoading(false);
            }else if(res[0] === "Exists"){
                setStatus({status: "Exists", type: "failed", message: "Previous Reset Link Unused"});
                setLoading(false);
            }else{
                setStatus({status: "failed", type: "failed", message: "Request Failed"});
                setLoading(false);
            }
        } catch (error) {
            setStatus({status: "failed", type: "failed", message: "Request Failed"});
            setLoading(false);
        }
    }

    React.useEffect(() => {
        if(status.message !== ""){
            document.getElementById("status-wrapper").scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [status]);

    React.useEffect(() => {
        document.title = "Student Login";
    }, []);

    return (
        <section className="contactform loginform">
            <div className="formwrapper">
                <div className={`wrapper ${forgotPage ? "active-forgot" : ""}`}>
                    <div className="form-box login">
                        <h2>Student Login</h2>
                        <form onSubmit={handleSubmit}>
                            {status.message !== "" && <p className='status-wrapper' id="status-wrapper"><span className={`status-message ${status.type}`}>{status.message}</span><LiaTimesCircle className='status-close' onClick={() => {setStatus({status: "", type: "", message: ""})}}/></p>}
                            <div className="input-box">
                                <span className="icon"><FaIdCard /></span>
                                <input readOnly={loading} placeholder=" " type="text" name='matric' autoComplete="off" pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/[A-Za-z]{3}/\d{2}/\d{4,5}$|(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/(FD|fd|Fd|fD)/\d{2}/\d{4,5}$|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$" title='RUN/xxx/yy/yyyy' value={formData.matric} onChange={handleChange} style={{textTransform: "uppercase"}} required />
                                <label>Matric Number</label>
                            </div>
                            <div className="input-box">
                                <span className="icon">
                                    {!showp && <FaEye style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                    {showp && <FaEyeSlash style={{cursor: "pointer"}} className='ico' onClick={toggleShow} />}
                                </span>
                                <input readOnly={loading} placeholder=" " name='password' type={showp ? "text" : "password"} value={formData.password} autoComplete="off" onChange={handleChange} required />
                                <label><FaLock /> Password</label>
                            </div>
                            <div className="remember-forgot">
                                <label htmlFor="rememberme"><input readOnly={loading} type="checkbox" name='remember' id="rememberme" onChange={handleChange} checked={formData.remember} /> Remember Me</label>
                                <span className="forgot-link" onClick={() => {setForgotPage(true); setStatus({status: "", type: "", message: ""});}}>Forgot Password?</span>
                            </div>
                            <button type="submit" className="btn" disabled={loading}>{loading ? <FaSpinner className='spinner' /> : "Login"}</button>
                        </form>
                    </div>
                    <div className="form-box forgotpassword">
                        <h2>Forgot Password</h2>
                        <form onSubmit={handleForgotSubmit}>
                            {status.message !== "" && <p className='status-wrapper' id="status-wrapper"><span className={`status-message ${status.type}`}>{status.message}</span><LiaTimesCircle className='status-close' onClick={() => {setStatus({status: "", type: "", message: ""})}}/></p>}
                            <div className="input-box">
                                <span className="icon"><FaIdCard /></span>
                                <input readOnly={loading} placeholder=" " name='forgotmatric' type="text" value={forgotMatric} onChange={handleForgotChange} style={{textTransform: "uppercase"}} pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/[A-Za-z]{3}/\d{2}/\d{4,5}$|(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/(FD|fd|Fd|fD)/\d{2}/\d{4,5}$|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$" title='RUN/xxx/yy/yyyy' autoComplete="off" required />
                                <label htmlFor="">Matric Number</label>
                            </div>
                            <button type="submit" className="btn" disabled={loading}>{loading ? <FaSpinner className='spinner' /> : <><FaPaperPlane /> Send Link</>}</button>
                            <div className="login-register">
                                <p><span className="return-link"  onClick={() => {setForgotPage(false); setStatus({status: "", type: "", message: ""});}}>Login</span></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Student;