import React from 'react';
import "./NotFound.css";
import { Link } from 'react-router-dom';
import MotionComponent from './MotionComponent';

function NotFound() {
    return (
        <MotionComponent tag='section' className='notfound' content={
            <div>
                <h1 className="heading1">404 Error</h1>
                <p>Page not Found</p>
                <button className="btn"><Link to="/" replace>Back to Home</Link></button>
            </div>
        } />
    );
}

export default NotFound;