import ReactPaginate from "react-paginate";
import React from "react";
import "./Paginate.css";
import { useSearchParams } from "react-router-dom";

export default function Paginate({ itemsPerPage, items }) {
    const [CurrentItems, setCurrentItems] = React.useState([]);
    const [pageCount, setPageCount] = React.useState(0);
    const [itemOffset, setItemOffset] = React.useState(0);
    const [first, setFirst] = React.useState(true);
    const [searchParams, setSearchParams] = useSearchParams();

    React.useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, items]);

    const pages = document.querySelectorAll(".page-item");
    React.useEffect(() => {
        const select = searchParams.get("selected");
        const selected = select ? parseInt(select) : 0;
        
        pages.forEach(page => {
            if (page?.classList.contains('page-click')) {
                page?.classList.remove('page-click');
            }
            if(parseInt(page.innerText) === selected + 1){
                page?.classList.add('page-click');
            }
        });
    }, [searchParams, pages]);

    const handlePageClick = (event) => {
        const select = searchParams.get("selected");
        const selected = first && select ? parseInt(select) : event.selected;
        const newOffset = selected * itemsPerPage % items.length;
        !first && setSearchParams({ selected: event.selected });
        setItemOffset(newOffset);
        setFirst(false);
    };

    return (
      <>
        {CurrentItems}
        <span className="pagination-wrapper">
            {itemsPerPage < items.length && <ReactPaginate
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                initialPage={0}
                pageCount={pageCount}
                containerClassName="pagination"
                activeClassName="page-active"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                nextLabel=">"
                nextClassName="page-item page-next"
                nextLinkClassName="page-link"
                previousLabel="<"
                previousClassName="page-item page-prev"
                previousLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                renderOnZeroPageCount={null}
            />}
        </span>
      </>
    );
}