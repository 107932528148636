import React from 'react';
import { FaPen, FaPlus } from 'react-icons/fa6';
import { NavLink, useOutletContext } from 'react-router-dom';
import { detectOpenAdmission, extractAdminOverview, showSwal, updateAllClassAdmission } from '../../AppManager';
import ConfirmAction from '../../components/ConfirmAction';

function OverviewGeneral() {
    const { record, currUser, setRecord, maxStudents } = useOutletContext();
    const [overview, setOverview] = React.useState({centre: 0, classes: 0, teacher: 0, "assistant teacher": 0, students: 0});
    const [disabled, setDisabled] = React.useState(true);
    const [close, setClose] = React.useState(true);
    const [actionEvent, setActionEvent] = React.useState({});

    React.useEffect(() => {
        document.title = "Overview";
    }, []);

    function toggleReg(e){
        const { name, type } = e.target;
        setActionEvent({ name, type });
        setClose(false);
    }

    async function toggleRegConfirmed({ name, type }){
        if(name === 'regclose' && type === "button"){
            const res = await updateAllClassAdmission();
            if(res === "Updated"){
                setRecord(oldRecord => {
                    const newRecord = oldRecord;
                    for (const centre in newRecord) {
                        if (Object.hasOwnProperty.call(newRecord, centre)) {
                            const centreData = newRecord[centre];
                            for (const trainingClass in centreData) {
                                if (Object.hasOwnProperty.call(centreData, trainingClass)) {
                                    const classData = centreData[trainingClass].classes;
                                    for (let i = 0; i < classData.length; i++) {
                                        const Class = classData[i];
                                        Class.admission = false;
                                    }
                                }
                            }
                        }
                    }
                    return newRecord;
                });
                showSwal("success", "All Registrations Closed");
                setDisabled(true);
            }else{
                showSwal("error", "Failed");
            }
        }
    }

    React.useEffect(() => {
        setOverview(extractAdminOverview(record));
    }, [record]);
  
    React.useEffect(() => {
        if(detectOpenAdmission(record)){
            setDisabled(false);
        }else{
            setDisabled(true);
        }
    }, [record]);

    return (
        <div className="overview-class admin-overview-class">
            {!close && <ConfirmAction currUser={currUser} setClose={setClose} confirmed={() => {toggleRegConfirmed(actionEvent)}} />}
            <h1 className="heading1">General Overview</h1>
            <div>
                <div><h1 className="heading3" style={{textAlign: "left"}}>Number of Centres:</h1><span>{overview.centre}<NavLink to="/admin/overview/addcentre"><FaPlus /></NavLink></span></div>
                <div><h1 className="heading3" style={{textAlign: "left"}}>Number of Classes:</h1><span><NavLink to="/admin/overview/classes">{overview.classes}</NavLink><NavLink to="/admin/overview/addclass"><FaPlus /></NavLink><NavLink to="/admin/overview/editclass"><FaPen /></NavLink></span></div>
                <div><h1 className="heading3" style={{textAlign: "left"}}>Number of Teachers:</h1><span><NavLink to="/admin/teachers">{overview.teacher + overview["assistant teacher"]}</NavLink></span></div>
                <div><h1 className="heading3" style={{textAlign: "left"}}>Number of Students:</h1><span><NavLink to="/admin/students">{overview.students}</NavLink></span></div>
                <div><h1 className="heading3" style={{textAlign: "left"}}>Max Number of Students:</h1><span>{maxStudents}<NavLink to="/admin/studentsmax"><FaPen /></NavLink></span></div>
                <div><h1 className="heading3" style={{textAlign: "left"}}>Passing Marks for Classes</h1><span><NavLink to="/admin/marks">View</NavLink></span></div>
                <div>
                    <h1 className="heading3" style={{textAlign: "left"}}>Close All Registrations:</h1>
                    <span className='checkbox-wrapper'>
                        <button type='button' className='text-btn sp-btn' name='regclose' style={{fontSize: "1em", fontWeight: "bolder", backgroundColor: "rgb(195, 6, 6)", border: "none"}} disabled={disabled} onClick={toggleReg}>CLOSE</button>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default OverviewGeneral;