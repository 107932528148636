import React from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { NavLink, Outlet, redirect, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { getClassDetails, getClassPassMarks, getClassRecord, getGeneralOverview } from '../AppManager';
import { clearUser, getCookie, getCurrUser, setCookie } from '../Auth';

export async function loader(){
    const currUser = await getCurrUser();
    if(currUser.position !== "teacher" && currUser.position !== "assistant teacher"){
        return redirect("/forbidden");
    }
    const Record = await getGeneralOverview();
    const Class = currUser.class;
    const classDetail = getClassDetails(Class, Record);
    const ClassRecord = await getClassRecord(classDetail.trainingClass, Class);
    return { Record, ClassRecord, Class, currUser, classDetail };
}

function Overview() {
    const path = useLocation().pathname;
    const [search, setSearch] = React.useState("");
    const { Record, ClassRecord, currUser, classDetail } = useLoaderData();
    const [record, setRecord] = React.useState(Record);
    const [classRecord, setClassRecord] = React.useState(ClassRecord);
    const [showSearch, setShowSearch] = React.useState(false);
    const [passMarks, setPassMarks] = React.useState({ believers: 0, baptismal: 0, workers: 0 });
    const navigate = useNavigate();
    
    React.useEffect(() => {
        function fakeAuth(){
            const position = getCookie("nd38h4nnue2");
            if(position !== "teacher" && position !== "assistant teacher"){
                const check = getCookie("nwoeem93jnrr49") || getCookie("nd38h4nnue2") || getCookie("njen98ue9jA") || getCookie("nniNJSN89u3heu23") || getCookie("BNEidw2k83e8y9");
                if(check){
                    clearUser();
                }
                navigate("/");
            }
        }

        window.addEventListener("storage", fakeAuth);
        return () => {
            window.removeEventListener("storage", fakeAuth);
        };
    }, [navigate]);

    React.useEffect(() => {
        document.title = "Overview";

        getClassPassMarks()
        .then(data => setPassMarks(data))
        .catch(err => err);
    }, []);

    React.useEffect(() => {
        const refetch = setInterval(() => {
            getGeneralOverview()
            .then(data => JSON.stringify(data) !== JSON.stringify(record) && setRecord(data))
            .catch(err => err);

            getClassRecord(classDetail.trainingClass, classDetail.name)
            .then(data => JSON.stringify(data) !== JSON.stringify(classRecord) && setClassRecord(data))
            .catch(err => err);

            getClassPassMarks()
            .then(data => JSON.stringify(data) !== JSON.stringify(passMarks) && setPassMarks(data))
            .catch(err => err);
        }, 5000)
        
        return () => {
            clearInterval(refetch);
        }
    }, [classDetail, record, classRecord, passMarks]);
    
    function handleChange(e){
        const { name, value, type } = e.target;
        if(name === "search" && type === "search"){
            setSearch(value);
        }
    }

    React.useEffect(() => {
        setSearch("");
        setShowSearch(false);
    }, [path]);

    const navStyles = path.indexOf("/overview/attendance") > -1
    ?
    {top: "0"}
    :
    {};

    React.useEffect(() => {
        function changeStatus() {
            if(!navigator.onLine){
                setCookie("ructrainingunitOfflinePath", path === "/offline" ? getCookie("ructrainingunitOfflinePath") : path);
                navigate(`/offline?redirectTo=${getCookie("ructrainingunitOfflinePath")}`);
            }
        }
        if(!navigator.onLine){
            setCookie("ructrainingunitOfflinePath", path === "/offline" ? getCookie("ructrainingunitOfflinePath") : path);
            navigate(`/offline?redirectTo=${getCookie("ructrainingunitOfflinePath")}`);
        }
        window.addEventListener("offline", changeStatus);
        return () => {
          window.removeEventListener("offline", changeStatus);
        };
    }, [navigate, path]);

    return (
        <section className="overview">
            <nav style={navStyles}>
                <ul>
                    <li><NavLink className={({isActive}) => isActive ? "active" : ""} to="/overview/class">Class</NavLink></li>
                    <li><NavLink className={({isActive}) => isActive ? "active" : ""} to="/overview/students">Students</NavLink></li>
                    <li><NavLink className={({isActive}) => isActive ? "active" : ""} to="/overview/attendance">Attendance</NavLink></li>
                </ul>
                {showSearch && <div className='student-search'>
                    <input type="search" name="search" value={search} placeholder='Search here...' maxLength={20} onKeyUp={handleChange} onChange={handleChange} />
                    <FaMagnifyingGlass />
                </div>}
            </nav>
            <Outlet context={{ search, user: currUser, record, setRecord, classRecord, setClassRecord, setShowSearch, passMarks }} />
        </section>
    );
}

export default Overview;