import React from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { NavLink, Outlet, redirect, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { getClassPassMarks, getGeneralOverview, getMaximumStudents } from '../AppManager';
import { clearUser, getCookie, getCurrUser, setCookie } from '../Auth';

export async function loader(){
    const currUser = await getCurrUser();
    if(currUser.position !== "admin"){
        return redirect("/forbidden");
    }
    const Record = await getGeneralOverview();
    return { Record, currUser };
}

function AdminOverview() {
    const path = useLocation().pathname;
    const [search, setSearch] = React.useState("");
    const [showSearch, setShowSearch] = React.useState(false);
    const [preventRefetch, setPreventRefetch] = React.useState(false);
    const { Record, currUser } = useLoaderData();
    const [record, setRecord] = React.useState(Record);
    const [maxStudents, setMaxStudents] = React.useState(0);
    const [passMarks, setPassMarks] = React.useState({ believers: "", baptismal: "", workers: "" });
    const [currClass, setCurrClass] = React.useState("");
    const [classRecord, setClassRecord] = React.useState(Record);

    React.useEffect(() => {
        document.title = "Overview";

        getMaximumStudents()
        .then(data => setMaxStudents(data))
        .catch(err => err);

        getClassPassMarks()
        .then(data => setPassMarks(data))
        .catch(err => err);
    }, []);

    React.useEffect(() => {
        let refetch = "";
        if(!preventRefetch){
            refetch = setInterval(() => {
                getGeneralOverview()
                .then(data => JSON.stringify(data) !== JSON.stringify(record) && setRecord(data))
                .catch(err => err);

                getMaximumStudents()
                .then(data => data !== maxStudents && setMaxStudents(data))
                .catch(err => err);

                getClassPassMarks()
                .then(data => JSON.stringify(data) !== JSON.stringify(passMarks) && setPassMarks(data))
                .catch(err => err);
            }, 5000);
        }
      
        return () => {
            clearInterval(refetch);
        }
    }, [path, record, preventRefetch, maxStudents, passMarks]);

    const navigate = useNavigate();
    
    React.useEffect(() => {
        function fakeAuth(){
            const position = getCookie("nd38h4nnue2");
            if(position !== "admin"){
                const check = getCookie("nwoeem93jnrr49") || getCookie("nd38h4nnue2") || getCookie("njen98ue9jA") || getCookie("nniNJSN89u3heu23") || getCookie("BNEidw2k83e8y9");
                if(check){
                    clearUser();
                }
                navigate("/");
            }
        }

        window.addEventListener("storage", fakeAuth);
        return () => {
            window.removeEventListener("storage", fakeAuth);
        };
    }, [navigate]);

    function handleChange(e){
        const { name, value, type } = e.target;
        if(name === "search" && type === "search"){
            setSearch(value);
        }
    }

    React.useEffect(() => {
        setSearch("");
        setShowSearch(false);
        setPreventRefetch(false);
    }, [path]);

    const navStyles = path.indexOf("/overview/attendance") > -1
    ?
    {
        top: "0"
    }
    :
    {};

    React.useEffect(() => {
        function changeStatus() {
            if(!navigator.onLine){
                setCookie("ructrainingunitOfflinePath", path === "/offline" ? getCookie("ructrainingunitOfflinePath") : path);
                navigate(`/offline?redirectTo=${getCookie("ructrainingunitOfflinePath")}`);
            }
        }
        if(!navigator.onLine){
            setCookie("ructrainingunitOfflinePath", path === "/offline" ? getCookie("ructrainingunitOfflinePath") : path);
            navigate(`/offline?redirectTo=${getCookie("ructrainingunitOfflinePath")}`);
        }
        window.addEventListener("offline", changeStatus);
        return () => {
          window.removeEventListener("offline", changeStatus);
        };
    }, [navigate, path]);

    return (
        <section className="overview admin-overview">
            <nav style={navStyles}>
                <ul>
                    <li><NavLink className={({isActive}) => isActive ? "active" : ""} to="overview">Overview</NavLink></li>
                    <li><NavLink className={({isActive}) => isActive ? "active" : ""} to="teachers">Teachers</NavLink></li>
                    <li><NavLink className={({isActive}) => isActive ? "active" : ""} to="students">Students</NavLink></li>
                    <li><NavLink className={({isActive}) => isActive ? "active" : ""} to="attendance">Attendance</NavLink></li>
                    <li><NavLink className={({isActive}) => isActive ? "active" : ""} to="special">Special</NavLink></li>
                </ul>
                {showSearch && <div className='student-search'>
                    <input type="search" name="search" value={search} placeholder='Search here...' maxLength={20} onKeyUp={handleChange} onChange={handleChange} />
                    <FaMagnifyingGlass />
                </div>}
            </nav>
            <Outlet context={{ search, record, setShowSearch, setRecord, classRecord, setClassRecord, currClass, setCurrClass, currUser, setPreventRefetch, maxStudents, setMaxStudents, passMarks, setPassMarks }} />
        </section>
    );
}

export default AdminOverview;