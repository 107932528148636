import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom'
import Navbar from './Navbar';
import Footer from './Footer';
import { FaChevronLeft } from 'react-icons/fa6';

function Layout() {
    const path = useLocation().pathname;
    
    return ( 
        <>
            <Navbar />
            <main>
                <Outlet />
            </main>
            {path !== "/" && path !== "/adminreg" && <Link to={-1}><button className="back-btn"> <FaChevronLeft /></button></Link>}
            <Footer />
        </>
    );
}

export default Layout;