import React from 'react';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { capitalizeEachWord, getTeacher, showSwal } from '../../AppManager';
import { FaKey, FaUserPen } from 'react-icons/fa6';

function TeacherDetails() {
    const { record, user } = useOutletContext();
    const { id } = useParams();
    const navigate = useNavigate();
    const [teacher, setTeacher] = React.useState({name: "", matric: "", class: "", trainingClass: "", centre: "", location: "", position: "", image: "user.png"});
    const [page, setPage] = React.useState(true);
    const [pageFlip, setPageFlip] = React.useState(true);

    React.useEffect(() => {
        try {
            const tch = getTeacher(id, record);
            if(tch){
                setTeacher(tch);
            }else{
                showSwal("error", "Teacher not Found");
                navigate("/overview", {replace: true});
            }
        } catch (error) {
            showSwal("error", "Teacher not Found");
            navigate("/overview", {replace: true});
        }
    }, [id, record, navigate]);


    function togglePage(){
        setPageFlip(oldPageFlip => !oldPageFlip);
        setTimeout(() => {
            setPage(oldPage => !oldPage);
        }, 250);
    }

    React.useEffect(() => {
        document.title = teacher?.name ? teacher.name : "Teacher Details";
    }, [teacher]);

    return (
        <div className="details-card-wrapper">
            {!teacher?.name && <div className="loader-item" style={{ width: "220px", height: "280px" }}>
                <div className="ruc-watermark">
                    <div>
                        <img src="/images/Bible Study Icon.png" alt="RUC Training Unit" />
                        <p>RUC Training Unit</p>
                    </div>
                </div>
            </div>}
            {teacher?.name && <div className={`details-card ${pageFlip ? "" : "rotate"}`}>
                {page && <div className='front' onClick={togglePage}>
                    <div className='details-img'>
                        <img onError={(e)=>{ if (e.target.src !== "/user.png"){ e.target.onerror = null; e.target.src="/user.png"; }}} src={`/images/teachers/${teacher.image}`} alt={capitalizeEachWord(teacher.name)} />
                    </div>
                    <span>{capitalizeEachWord(teacher.name)}</span>
                    <span>{teacher.position}</span>
                    {teacher.position.toLowerCase() === user.position.toLowerCase() && <span className='manage'>
                        <Link to="edit" state={{id: teacher.matric, position: teacher.position, user}}><FaUserPen style={{fontSize: "1.1em"}} /></Link>
                        <Link to="password" state={{matric: teacher.matric, position: teacher.position, user}}><FaKey style={{fontSize: "1.1em"}} /></Link>
                    </span>}
                </div>}
                {!page && <div className='back' onClick={togglePage}>
                    <div>
                        <h3>Name</h3>
                        <span>{capitalizeEachWord(teacher.name)}</span>
                    </div>
                    <div>
                        <h3>Matric Number</h3>
                        <span>{teacher.matric.toUpperCase()}</span>
                    </div>
                    <div>
                        <h3>E-Mail</h3>
                        <span style={{ wordBreak: "break-all" }}><a href={`mailto: ${teacher.email}`}>{teacher.email}</a></span>
                    </div>
                    <div>
                        <h3>Centre</h3>
                        <span>{teacher.centre}</span>
                    </div>
                    <div>
                        <h3>Training Class</h3>
                        <span>{teacher.trainingClass}</span>
                    </div>
                    <div>
                        <h3>Class</h3>
                        <span>{teacher.class}</span>
                    </div>
                    <div>
                        <h3>Location</h3>
                        <span>{teacher.location}</span>
                    </div>
                </div>}
                <div className="ruc-watermark">
                    <div>
                        <img src="/images/Bible Study Icon.png" alt="RUC Training Unit" />
                        <p>RUC Training Unit</p>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default TeacherDetails;