import React from 'react';
import { FaSpinner } from 'react-icons/fa6';
import { generateAttendance, showSwal } from '../../AppManager';
import { LiaTimesCircleSolid } from 'react-icons/lia';
import { downloadBaseURL } from '../../Auth';

function AttendanceCard({ name, Class }) {
    const [download, setDownload] = React.useState({state: "", link: ""});
    const displayName = name === "Current" ? "Current Attendance" : name;

    function downloadAttendance(){
        generateAttendance(name.toLowerCase(), Class)
        .then(filename => {
            if(filename === "Empty"){
                showSwal("error", "Attendance Download Failed", "No Record Found");
            }else{
                setDownload({state: "generating", link: ""});
                setTimeout(() => {
                    setDownload({state: "ready", link: `${downloadBaseURL}${filename}`});
                }, 1000);
            }
        })
        .catch(() => {
            showSwal("error", "Attendance Download Failed");
        });
    }

    return (
        <>
            {
                download.state === "ready"
                ?
                    <div className='attendance-download-card'>
                        <LiaTimesCircleSolid className='close' onClick={() => {setDownload({state: "", link: ""})}} />
                        <label>{displayName}</label>
                        <a href={download.link} onClick={() => {setDownload({state: "", link: ""})}}><button>Download</button></a>
                    </div>
                :
                    <div className='attendance-card' onClick={downloadAttendance}>
                        <img className='attendance-card-img' src="/images/excel_logo.png" alt={displayName} />
                        <label>{download.state === "" ? displayName : <FaSpinner className='spinner' />}</label>
                    </div>
            }
        </>
    );
}

export default AttendanceCard;