import React from 'react';
import { FaChalkboardUser, FaEnvelope, FaIdCard, FaImage, FaLocationDot, FaSpinner } from 'react-icons/fa6';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { capitalizeEachWord, checkExtension, cleanMatric, cleanName, deleteTeacherAdmin, getClassDetails, getTeacher, showSwal, stripSpecialChar, updateClass } from '../../AppManager';
import { LiaCheckCircle, LiaTimesCircle } from 'react-icons/lia';

function EditClass() {
    const { record, setRecord, setPreventRefetch } = useOutletContext();
    const [classDetails, setClassDetails] = React.useState({ name: "", teacher: "", "teacher matric": "", "teacher imgname": "", "assistant teacher": "", "assistant image": "", "teacher image": "", "assistant matric": "", "assistant imgname": "", students: "", centre: "", trainingClass: "", "teacher email": "", "assistant email": "", location: "" });
    const navigate = useNavigate();
    const { Class } = useParams();
    const [formData, setFormData] = React.useState({teacher: "", "teacher matric": "", "teacher email": "", "assistant teacher": "", "teacher img": false, "assistant img": false, "assistant matric": "", "assistant email": "", location: ""});
    const [imgErr, setImgErr] = React.useState({name: "", reason: "", status: true});
    const [status, setStatus] = React.useState({status: "", type: "", message: ""});
    const [uprogress, setUprogess] = React.useState(0);
    const [ustate, setUstate] = React.useState("");
    const [uploading, setUploading] = React.useState(false);

    React.useEffect(() => {
        setPreventRefetch(true);
    });

    React.useEffect(() => {
        try {
            const cdetail = getClassDetails(Class, record);
            if(cdetail.name !== ""){
                setClassDetails(cdetail);
            }else{
                showSwal("error", "Class not Found");
                navigate("/admin/overview/editclass", {replace: true});
            }
        } catch (error) {
            showSwal("error", "Class not Found");
            navigate("/admin/overview/editclass", {replace: true});
        }
    }, [Class, record, navigate])
    
    React.useEffect(() => {
        setFormData(oldFormData => {
            return { ...oldFormData, name: classDetails.name, teacher: classDetails.teacher, "assistant teacher": classDetails['assistant teacher'], "assistant matric": classDetails['assistant matric'], "teacher matric": classDetails['teacher matric'], "teacher email": classDetails['teacher email'], "assistant email": classDetails['assistant email'], location: classDetails.location };
        })
    }, [classDetails]);

    React.useEffect(() => {
        document.title = 'Edit Class';
    }, []);

    function handleChange(e){
        const { name, value, type, files } = e.target;
        setFormData(oldFormData => {
            if(type === "file" && files[0]){
                if(files[0].size <= 512000 && checkExtension(files[0].name)){
                    setImgErr({name: "", reason: "", status: true});
                    return {...oldFormData, [name]: type === "file" ? files[0] : value};
                }else{
                    if(files[0].size > 512000){
                        setImgErr({name, reason: "size", status: false});
                    }else if(!checkExtension(files[0].name)){
                        setImgErr({name, reason: "format", status: false});
                    }
                }
                return oldFormData;
            }else{
                return {...oldFormData, [name]: name === "teacher" || name === "assistant teacher" ? cleanName(capitalizeEachWord(value)) : name === "teacher matric" || name === "assistant matric" ? cleanMatric(value) : value};
            }
        });
    }

    async function handleSubmit(e){
        e.preventDefault();
        try {
            if(formData.teacher !== classDetails.teacher || formData['assistant teacher'] !== classDetails['assistant teacher'] || formData['teacher matric'] !== classDetails['teacher matric'] || formData['assistant matric'] !== classDetails['assistant matric'] || formData['assistant email'] !== classDetails['assistant email'] || formData['teacher email'] !== classDetails['teacher email'] || formData['assistant img'].name || formData['teacher img'].name || formData.location !== ""){
                setUploading(true);
                const location = formData.location === "" ? classDetails.location : formData.location;
                const teacher = formData.teacher === "" ? classDetails.teacher : formData.teacher;
                const assistant = formData['assistant teacher'] === "" ? classDetails['assistant teacher'] : formData['assistant teacher'];
                const teacherMatric = formData['teacher matric'] === "" ? classDetails['teacher matric'] : formData['teacher matric'];
                const assistantMatric = formData['assistant matric'] === "" ? classDetails['assistant matric'] : formData['assistant matric'];
                const teacherEmail = formData['teacher email'] === "" ? classDetails['teacher email'] : formData['teacher email'];
                const assistantEmail = formData['assistant email'] === "" ? classDetails['assistant email'] : formData['assistant email'];
                const teacherdownloadURL = formData['teacher img'].name ? stripSpecialChar(formData['teacher matric']) + formData['teacher img'].name.slice(formData['teacher img'].name.lastIndexOf(".")) : classDetails['teacher image'];
                const assistantdownloadURL = formData['assistant img'].name ? stripSpecialChar(formData['assistant matric']) + formData['assistant img'].name.slice(formData['assistant img'].name.lastIndexOf(".")) : classDetails['assistant image'];

                const newClass = { ...classDetails, teacher, "teacher matric": teacherMatric, "assistant teacher": assistant, "assistant image": assistantdownloadURL, "teacher image": teacherdownloadURL, "assistant matric": assistantMatric, "teacher email": teacherEmail, "assistant email": assistantEmail, location };

                const regData = { name: classDetails.name, teacher, teacherMatric, assistantTeacher: assistant, assistantImage: formData['assistant img'], teacherImage: formData['teacher img'], assistantMatric, assistantEmail, teacherEmail, location };

                const res = await updateClass(regData);
                if(res === "Updated"){
                    setRecord(oldRecord => {
                        let updatedClass = [...oldRecord[classDetails.centre][classDetails.trainingClass].classes];
                        for (let k = 0; k < updatedClass.length; k++) {
                            const Class = updatedClass[k];
                            if(Class.name === classDetails.name){
                                updatedClass[k] = newClass; 
                            }
                        }
                        
                        const newRecord = {...oldRecord, [classDetails.centre]: {...oldRecord[classDetails.centre], [classDetails.trainingClass]: {...oldRecord[classDetails.centre][classDetails.trainingClass], classes: updatedClass}}};

                        if(teacherMatric !== classDetails['teacher matric'] && !getTeacher(classDetails['teacher matric'], newRecord)){
                            deleteTeacherAdmin({ matric: classDetails['teacher matric'], Class: classDetails.name, image: classDetails['teacher image'], position: "Teacher" });
                        }
                        
                        if(assistantMatric !== classDetails['assistant matric'] && !getTeacher(classDetails['assistant matric'], newRecord)){
                            deleteTeacherAdmin({ matric: classDetails['assistant matric'], Class: classDetails.name, image: classDetails['assistant image'], position: "Assistant Teacher" })
                        }

                        return newRecord;
                    })
                    const imgUpload = typeof(formData['teacher img']) === "object" || typeof(formData['assistant img']) === "object";
                    if(imgUpload){
                        setUstate('Uploading...');
                        const interv = setInterval(() => {
                            setUprogess(oldProgress => {
                                if(oldProgress >= 100){
                                    clearInterval(interv);
                                }
                                const rand = Math.floor(Math.random() * 25 + 15);
                                const add = oldProgress + rand;
                                return add > 100 ? 100 : add;
                            });
                        }, 500);
                    }
                    setTimeout(() => {
                        setUprogess(0);
                        setTimeout(() => {
                            setStatus({status: "success", type: "success", message: "Updated Successfully"});
                            setUploading(false);   
                        }, 500);
                    }, imgUpload ? 3000 : 1000);
                }else if(res === "Not Found"){
                    setStatus({status: "not found", type: "failed", message: "Class not Found. Redirecting..."});
                    setTimeout(() => {
                        navigate("/admin/overview/editclass", {replace: true});
                    }, 2000);
                }else if(res === "Teacher E-Mail"){
                    setStatus({status: "e-mail", type: "failed", message: "Teacher E-Mail Taken"});
                    setUploading(false);   
                }else if(res === "Assistant E-Mail"){
                    setStatus({status: "e-mail", type: "failed", message: "Assistant E-Mail Taken"});
                    setUploading(false);   
                }else{
                    setStatus({status: "failed", type: "failed", message: "Update Failed"});
                    setUploading(false);   
                }
            }
        } catch (error) {
            setStatus({status: "failed", type: "failed", message: "Update Failed"});
            setUploading(false);   
        }
    }

    React.useEffect(() => {
        if(status.message !== ""){
            document.getElementById("status-wrapper").scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [status]);
    
    return (
        <div className="formwrapper" style={{margin: "50px 0"}}>
            <div className="wrapper" style={uploading ? {height: status.message !== "" ? "1070px" : "1030px"} : {height: status.message !== "" ? "1030px" : "980px"}}>
                <div className="form-box login">
                    <h2>Edit Class</h2>
                    <form onSubmit={handleSubmit}>
                        {status.message !== "" && <p className='status-wrapper' id="status-wrapper"><span className={`status-message ${status.type}`}>{status.message}</span><LiaTimesCircle className='status-close' onClick={() => {setStatus({status: "", type: "", message: ""})}}/></p>}
                        <div className="input-box">
                            <input type="text" value={classDetails.name} autoComplete="off" required readOnly placeholder=" " />
                            <label>Class Name</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaChalkboardUser /></span>
                            <input readOnly={uploading} type="text" onChange={handleChange} value={formData.teacher} autoComplete="off" name="teacher" pattern="[A-Za-z\- ]+" minLength={7} maxLength={25} placeholder=" " />
                            <label>Teacher</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaIdCard /></span>
                            <input readOnly={uploading} placeholder=" " type="text" name='teacher matric' style={{textTransform: "uppercase"}} value={formData['teacher matric']} onChange={handleChange} autoComplete="off" pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/[A-Za-z]{3}/\d{2}/\d{4,5}$|(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/(FD|fd|Fd|fD)/\d{2}/\d{4,5}$|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$" title='RUN/xxx/yy/yyyy' />
                            <label>Teacher Matric Number</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaEnvelope /></span>
                            <input readOnly={uploading} placeholder=" " type="email" name='teacher email' value={formData["teacher email"]} minLength={13} maxLength={36} onChange={handleChange} autoComplete="off" />
                            <label>Teacher E-Mail</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaImage /></span>
                            <input readOnly={uploading} type="file" onChange={handleChange} name="teacher img" />
                            <label>Teacher <span style={{fontSize: "0.7em"}}>(max = 500kb)</span></label>
                            {imgErr.name === 'teacher img' && imgErr.reason === "size" && !imgErr.status && <p>Max Size Exceeded</p>}
                            {imgErr.name === 'teacher img' && imgErr.reason === "format" && !imgErr.status && <p>Invalid File Format</p>}
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaChalkboardUser /></span>
                            <input readOnly={uploading} type="text" onChange={handleChange} value={formData["assistant teacher"]} autoComplete="off" name="assistant teacher" pattern="[A-Za-z\- ]+" minLength={7} maxLength={25} placeholder=" " />
                            <label>Assistant Teacher</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaIdCard /></span>
                            <input readOnly={uploading} placeholder=" " type="text" name='assistant matric' style={{textTransform: "uppercase"}} value={formData['assistant matric']} onChange={handleChange} autoComplete="off" pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/[A-Za-z]{3}/\d{2}/\d{4,5}$|(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/(FD|fd|Fd|fD)/\d{2}/\d{4,5}$|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$" title='RUN/xxx/yy/yyyy' />
                            <label>Assistant Matric</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaEnvelope /></span>
                            <input readOnly={uploading} placeholder=" " type="email" name='assistant email' value={formData["assistant email"]} minLength={13} maxLength={36} onChange={handleChange} autoComplete="off" />
                            <label>Assistant E-Mail</label>
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaImage /></span>
                            <input readOnly={uploading} type="file" onChange={handleChange} name="assistant img" />
                            <label>Assistant Teacher <span style={{fontSize: "0.7em"}}>(max = 500kb)</span></label>
                            {imgErr.name === 'assistant img' && imgErr.reason === "size" && !imgErr.status && <p>Max Size Exceeded</p>}
                            {imgErr.name === 'assistant img' && imgErr.reason === "format" && !imgErr.status && <p>Invalid File Format</p>}
                        </div>
                        <div className="input-box">
                            <span className="icon"><FaLocationDot /></span>
                            <input readOnly={uploading} type="text" onChange={handleChange} value={formData.location} autoComplete="off" pattern='[A-Za-z0-9\- ]+' name="location" placeholder=" " minLength={3} maxLength={50} />
                            <label>Location</label>
                        </div>
                        <button type="submit" disabled={!imgErr.status || uploading} className='btn'>{uploading ? uprogress === 100 ? "Finishing..." : <FaSpinner className='spinner' /> : "Update"}</button>
                        {uploading && uprogress !== 0 && <div className="upload-status">
                            <div id='upload-progress'>
                                <div id='upload-progress-bar' className='loader-item' style={{width: uprogress + "%", backgroundImage: uprogress !== 100 ? "linear-gradient(110deg, #002F63 8%, #1257a7 18%, #002F63 33%)" : "linear-gradient(110deg, green 8%, yellowgreen 18%, green 33%)"}}></div>
                                <label>{Math.floor(uprogress)}%</label>
                            </div>
                            <div style={{fontSize: "0.8em", fontFamily: "Georgia, 'Times New Roman', Times, serif"}}>{uprogress === 100 ? <span style={{display: "flex", alignItems: "center", fontFamily: "Georgia, 'Times New Roman', Times, serif"}}><LiaCheckCircle style={{fontSize: "1.2em", marginRight: "2px", color: "green"}} />Upload Completed</span> : ustate}</div>
                        </div>}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditClass;