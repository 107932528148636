import Swal from "sweetalert2";
import { baseURL, teacherAuthRequired } from "./Auth";
import axios from "axios";

export const userImg = "user.png";
export const allTrainingClasses = ["Believers Class", "Baptismal Class", "Workers in Training"];

export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const fullDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export function getCurrTimeInfo(){
    const date = new Date();
    const today = `${days[date.getDay()]}-${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getFullYear()}`;
    const options = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
    const options24 = { hour: '2-digit', minute: '2-digit', second: '2-digit', hourCycle: "h23" };
    const currentTime = new Intl.DateTimeFormat('en-US', options).format(date);
    const currentTime24 = new Intl.DateTimeFormat('en-US', options24).format(date);
    const currTime = currentTime.slice(0, 8);
    const currTime24 = currentTime24.slice(0, 8);
    const currMonth = `${months[date.getMonth()].toLowerCase()}${date.getFullYear()}`;
    const upperCurrMonth = `${months[date.getMonth()]} ${date.getFullYear()}`;
    return {currTime, currTime24, currMonth, upperCurrMonth, today, options, options24};
}

export function calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight){
    const screenWidth = window.screen.width - (pagePadMarginWidth);
    const screenHeight = window.screen.height - (pagePadMarginHeight);
    const maxColumn = Math.floor(screenWidth / width);
    const maxRow = Math.floor(screenHeight / height) + 1;
    const maxItems = maxColumn * maxRow;
    return maxItems > 5 ? maxItems : 5;
}

export const capitalizeEachWord = (string) => {
    if(string){
        let words = string.split(" ");
        for (let k = 0; k < words.length; k++) {
            const word = words[k];
            if(word){
                words[k] = word[0].toUpperCase() + word.slice(1).toLowerCase();
            }
        }

        let newWord = "";
        for (let p = 0; p < words.length; p++) {
            const word = words[p];
            if(p === words.length - 1 || word !== ""){
                newWord += word + " ";
            }
        }
        return newWord.slice(0, newWord.length - 1);
    }
}

export function getIntValueOrString(inputString) {
    if (/^\d+$/.test(inputString)) {
        return parseInt(inputString, 10).toString();
    } else {
        return inputString.toUpperCase();
    }
}

export function stripSpecialChar(string) {
    if(string){
        const specialChars = ['\\', '/', '*', ':', '?', '"', '<', '>', '|', '&'];
        let newString = '';
        for (let k = 0; k < string.length; k++) {
          if (!specialChars.includes(string[k])) {
            newString += string[k];
          }
        }
        return newString;
    }
}

export function stripSpace(string) {
    if(string){
        let newString = '';
        for (let k = 0; k < string.length; k++) {
          if (string[k] !== " ") {
            newString += string[k];
          }
        }
        return newString;
    }
}

export function cleanName(string) {
    if(string){   
        let newString = '';
        for (let k = 0; k < string.length; k++) {
            if(/[A-Z]/.test(string[k]) || /[a-z]/.test(string[k]) || string[k] === "-" || string[k] === " ") {
                newString += string[k];
            }
        }
        return newString;
    }
}

export function cleanMatric(string) {
    if(string){   
        let newString = '';
        for (let k = 0; k < string.length; k++) {
            if(/[A-Z]/.test(string[k]) || /[a-z]/.test(string[k]) || /\d/.test(string[k]) || string[k] === "/") {
                newString += string[k];
            }
        }
        return newString;
    }
}

export function equalArrayValues(array){
    const x = array[0];
    for (let i = 0; i < array.length; i++) {
        const y = array[i];
        if(x !== y){
            return false;
        }
    }
    return true;
}

export function arrayValuesFrequency(arr, val){
    return arr.filter(value => value === val).length;
}

export function selectValue(arr, maxVal) {
    if(arr.length === 0){
        return -2;
    }

    // Condition 0: Check if all values are equal
    const allEqual = arr.every(value => value === arr[0]);
    if(allEqual && maxVal === arr[0]){
        return -1;
    }

    if (allEqual) {
      return 0;
    }

    // Condition 1: Check if any values are less than 10
    const less = arr.filter(val => val < 10);
    if(less.length > 0){
        return arr.indexOf(less[0]);
    }
  
    // Condition 2: Get minimum, maximum, and penultimate minimum values
    const min = Math.min(...arr);
    const max = Math.max(...arr);
    const sortedArr = arr.slice().sort((a, b) => a - b);
    const penultimateMin = sortedArr[1];
  
    // Condition 3: If maximum is not a factor of 5, choose max
    if (max % 5 !== 0) {
      return arr.indexOf(max);
    }
  
    // Condition 4: If minimum === penultimate minimum, select lower index minimum
    if (min === penultimateMin) {
      return Math.min(arr.indexOf(min), arr.lastIndexOf(min));
    }
  
    // Condition 5: If all values are the same except for maximum
    const uniqueValues = [...new Set(arr)];
    if (uniqueValues.length === 2 && uniqueValues.includes(max) && arrayValuesFrequency(arr, max) === 1) {
      // Condition 5i: Max is not a factor of 5, choose max
      if (max % 5 !== 0) {
        return arr.indexOf(max);
      }
      // Condition 5ii: Max is a factor of 5, choose index 2
      return 1;
    }
  
    // Condition 6: If all values are the same except for minimum, select minimum
    return arr.indexOf(min);
} 

export function sortMonths(input){
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const lowerMonths = months.map(month => month.toLowerCase());
    const sortedMonths = [];
    let powers = [];
    const powerMonths = {};
    for (let i = 0; i < input.length; i++) {
        const element = input[i].toLowerCase();
        const split = element.match(/[a-zA-Z]+|[0-9]+/g);
        const index = lowerMonths.indexOf(split[0]);
        const power = (index / 10) + parseInt(split[1]);
        powerMonths[power] = `${months[index]} ${split[1]}`;
    }
    for (const key in powerMonths) {
        powers.push(key);
    }
    powers = powers.sort();
    for (let m = 0; m < powers.length; m++) {
        const element = powers[m];
        sortedMonths.unshift(powerMonths[element]);
    }
    return sortedMonths;
}

function compareByProperty(a, b, property) {
    return a[property].localeCompare(b[property]);
}
  
export function sortByProperty(array, property) {
    return array.sort((a, b) => compareByProperty(a, b, property));
}

export function passwordStrength(password){
    let passwordStat = {strength: "", color: "", upper: false, lower: false, special: false, length: false, number: false};
    let percentage = 0;
    if(password.length >= 6) {
        passwordStat.length = true;
        percentage += 20;
    }
    if(/[A-Z]/.test(password)) {
        passwordStat.upper = true;
        percentage += 20;
    }
    if(/[a-z]/.test(password)) {
        passwordStat.lower = true;
        percentage += 20;
    }
    if(/\d/.test(password)) {
        passwordStat.number = true;
        percentage += 20;
    }
    // eslint-disable-next-line
    if(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
        passwordStat.special = true;
        percentage += 20;
    }

    if(percentage === 20 || percentage === 0){
        passwordStat.color = "red";
    }
    if(percentage === 40){
        passwordStat.color = "orange";
    }
    if(percentage === 60){
        passwordStat.color = "yellowgreen";
    }
    if(percentage === 80){
        passwordStat.color = "darkgreen";
    }
    if(percentage === 100){
        passwordStat.color = "darkblue";
    }
    return {...passwordStat, strength: percentage + "%"};
}

export function checkExtension(string) {
    const extensions = ['jpeg', 'jpg', 'png', 'heic'];
    const ext = string.slice(string.lastIndexOf(".") + 1).toLowerCase();
    if(extensions.includes(ext)) {
        return true;
    }
    return false;
}

export async function getGeneralOverview(){
    try {
        const data = { get_general_overview: "ownejwew" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/read.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        if(typeof(send.data) === "object"){
            return send.data;
        }else{
            return {};
            // return "Failed";
        }
    } catch (error) {
        return {};
        // return "Failed";
        // return error;
    }
}

export async function getClassRecord(trainingClass, className){
    try {
        const data = { class: className.toLowerCase(), trainingClass, month: "current", get_class_record: "xmrnekt" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/read.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });

        if(typeof(send.data) === "object"){
            return send.data;
        }else{
            return {};
            // return "Failed";
        }
    } catch (error) {
        return {};
        // return error;
    }
}

export const getClassMonthRecord = async (month, Class ) => {
    try {
        const record = await getGeneralOverview();
        const { trainingClass } = getClassDetails(Class, record);
        const data = { class: Class.toLowerCase(), trainingClass, month: month.toLowerCase(), get_class_record: "xmrnekt" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/read.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        if(typeof(send.data) === "object"){
            return send.data;
        }else{
            return {};
            // return "Failed";
        }
    } catch (error) {
        return {};
        // return error;
    }
}

export const getAllClassRecords = async (Class) => {
    try {
        const data = { class: Class.toLowerCase(), get_all_class_record: "emgkrelmkl" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/read.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        if(typeof(send.data) === "object"){
            return send.data;
        }else{
            // return "Failed";
            return [];
        }
    } catch (error) {
        return [];
        // return error;
    }
}

export async function classAssigner(centre, trainingClass){
    let maxStudents = await getMaximumStudents();
    maxStudents = parseInt(maxStudents);
    const overview = await getGeneralOverview();
    if(overview.hasOwnProperty(centre)){
        const centreClasses = overview[centre];
        if(centreClasses.hasOwnProperty(trainingClass)){
            const lasses = centreClasses[trainingClass].classes;
            const classes = lasses.filter(lass => lass.admission === true);
            const students = [];
            classes.map(clas => students.push(parseInt(clas.students)));
            const classIndex = selectValue(students, maxStudents);
            if(classIndex === -2){
                return "none";
            }else if(classIndex > -1){
                return classes[classIndex].name;
            }else{
                return "full";
            }
        }
    }
    return "none";
}

export function extractMatricDigits(string){
    const i = string.lastIndexOf("/") + 1;
    if(i === 0){
        if(string.length > 5){
            return string.slice(string.length - 5);
        }else{
            return string;
        }
    }else{
        return string.slice(i);
    }
}

export function extractAdminOverview(data){
    let details = {centre: 0, classes: 0, teacher: 0, "assistant teacher": 0, students: 0};
    const allTeachers = [];
    const allTeachersMatric = [];
    const allAssistants = [];
    const allAssistantsMatric = [];
    for (const centre in data) {
        if (Object.hasOwnProperty.call(data, centre)) {
            details = {...details, centre: details.centre + 1};
            const centreData = data[centre];
            for (const trainingClass in centreData) {
                if (Object.hasOwnProperty.call(centreData, trainingClass)) {
                    const classData = centreData[trainingClass] && centreData[trainingClass].classes ? centreData[trainingClass].classes : [];
                    for (let i = 0; i < classData.length; i++) {
                        const Class = classData[i];
                        if(Class.teacher !== ""){
                            if(!allTeachersMatric.includes(Class["teacher matric"])){
                                allTeachers.push(Class.teacher);
                                allTeachersMatric.push(Class["teacher matric"]);
                            }
                        }
                        if(Class["assistant teacher"] !== ""){
                            if(!allAssistantsMatric.includes(Class["assistant matric"])){
                                allAssistants.push(Class["assistant teacher"]);
                                allAssistantsMatric.push(Class["assistant matric"]);
                            }
                        }
                        details = {...details, classes: details.classes + 1, students: details.students + parseInt(Class.students)};
                    }
                }
            }
        }
    }
    details = {...details, teacher: allTeachers.length, "assistant teacher": allAssistants.length};
    return details;
}

export function extractAllCentres(data){
    const allCentres = [];
    for (const centre in data) {
        if (Object.hasOwnProperty.call(data, centre)) {
            allCentres.push(centre);
        }
    }
    return allCentres.sort();
}

export function extractAllClasses(data){
    const allClasses = [];
    for (const centre in data) {
        if (Object.hasOwnProperty.call(data, centre)) {
            const centreData = data[centre];
            for (const trainingClass in centreData) {
                if (Object.hasOwnProperty.call(centreData, trainingClass)) {
                    const classData = centreData[trainingClass].classes;
                    for (let i = 0; i < classData.length; i++) {
                        const Class = classData[i];
                        allClasses.push({...Class, trainingClass, centre});
                    }
                }
            }
        }
    }
    return sortByProperty(allClasses, "name");
}

export function extractAllTeachers(data){
    const allTeachers = [];
    const allTeachersMatric = [];
    for (const centre in data) {
        if (Object.hasOwnProperty.call(data, centre)) {
            const centreData = data[centre];
            for (const trainingClass in centreData) {
                if (Object.hasOwnProperty.call(centreData, trainingClass)) {
                    const classData = centreData[trainingClass].classes;
                    for (let i = 0; i < classData.length; i++) {
                        const Class = classData[i];
                        if(Class.teacher !== ""){
                            if(!allTeachersMatric.includes(Class["teacher matric"])){
                                allTeachers.push({name: Class.teacher, matric: Class["teacher matric"], trainingClass, class: Class.name, centre, position: "Teacher", image: Class["teacher image"], location: Class.location, email: Class["teacher email"]});
                                allTeachersMatric.push(Class["teacher matric"]);
                            }
                        }
                        if(Class["assistant teacher"] !== ""){
                            if(!allTeachersMatric.includes(Class["assistant matric"])){
                                allTeachers.push({name: Class["assistant teacher"], matric: Class["assistant matric"], trainingClass, class: Class.name, centre, position: "Assistant Teacher", image: Class["assistant image"], location: Class.location, email: Class["assistant email"]});
                                allTeachersMatric.push(Class["assistant matric"]);
                            }
                        }
                    }
                }
            }
        }
    }
    return sortByProperty(allTeachers, "class");
}

export function extractTrainingClassTeachers(data, tclass){
    const allTeachers = [];
    const allTeachersMatric = [];
    for (const centre in data) {
        if (Object.hasOwnProperty.call(data, centre)) {
            const centreData = data[centre];
            for (const trainingClass in centreData) {
                if (Object.hasOwnProperty.call(centreData, trainingClass) && trainingClass === tclass) {
                    const classData = centreData[trainingClass].classes;
                    for (let i = 0; i < classData.length; i++) {
                        const Class = classData[i];
                        if(Class.teacher !== ""){
                            if(!allTeachersMatric.includes(Class["teacher matric"])){
                                allTeachers.push({name: Class.teacher, matric: Class["teacher matric"], trainingClass, class: Class.name, centre, position: "Teacher", image: Class["teacher image"]});
                                allTeachersMatric.push(Class["teacher matric"]);
                            }
                        }
                        if(Class["assistant teacher"] !== ""){
                            if(!allTeachersMatric.includes(Class["assistant matric"])){
                                allTeachers.push({name: Class["assistant teacher"], matric: Class["assistant matric"], trainingClass, class: Class.name, centre, position: "Assistant Teacher", image: Class["assistant image"]});
                                allTeachersMatric.push(Class["assistant matric"]);
                            }
                        }
                    }
                }
            }
        }
    }
    return allTeachers;
}

export function extractClassesTeachers(data, className){
    const allTeachers = [];
    const classDetail = getClassDetails(className, data);
    classDetail.teacher !== "" && allTeachers.push({name: classDetail.teacher, matric: classDetail["teacher matric"], image: classDetail["teacher image"]});
    classDetail["assistant teacher"] !== "" && allTeachers.push({name: classDetail["assistant teacher"], matric: classDetail["assistant matric"], image: classDetail["assistant image"]});
    return allTeachers;
}

export function extractTrainingClassClasses(data, tclass){
    const allClasses = [];
    for (const centre in data) {
        if (Object.hasOwnProperty.call(data, centre)) {
            const centreData = data[centre];
            for (const trainingClass in centreData) {
                if (Object.hasOwnProperty.call(centreData, trainingClass) && tclass === trainingClass) {
                    const classes = centreData[trainingClass].classes;
                    for (let k = 0; k < classes.length; k++) {
                        const Class = classes[k];
                        allClasses.push({...Class, centre, trainingClass});
                    }
                }
            }
        }
    }
    return allClasses;
}
 
export async function extractTrainingClassStudents(data, tclass){
    const allClasses = extractTrainingClassClasses(data, tclass);
    const rawStudents = [];
    const allStudents = {};
    const allStudentsArray = [];
    for (let a = 0; a < allClasses.length; a++) {
        const Class = allClasses[a];
        const fet = await getClassRecord(tclass, Class.name);
        rawStudents.push(fet);

    }
    for (let b = 0; b < rawStudents.length; b++) {
        const students = rawStudents[b];
        for (const student in students) {
            if (Object.hasOwnProperty.call(students, student) && student !== "meetings" && student !== "cumatt") {
                const info = students[student];
                allStudents[student] = info;
            }
        }
    }
    for (const student in allStudents) {
        if (Object.hasOwnProperty.call(allStudents, student)) {
            const element = allStudents[student];
            allStudentsArray.push(element);
        }
    }
    return sortByProperty(allStudentsArray, "name");
}

export async function extractAllStudents(data){
    const allClasses = extractAllClasses(data);
    const allStudents = {};
    const allStudentsArray = [];
    for (let a = 0; a < allClasses.length; a++) {
        const Class = allClasses[a];
        const students = await getClassRecord(Class.trainingClass, Class.name);
        for (const student in students) {
            if (Object.hasOwnProperty.call(students, student) && student !== "meetings" && student !== "cumatt") {
                const info = students[student];
                info.class = Class.name;
                info.teacher = Class.teacher;
                info.centre = Class.centre;
                info.location = Class.location;
                allStudents[student] = info;
            }
        }
    }
    for (const student in allStudents) {
        if (Object.hasOwnProperty.call(allStudents, student)) {
            const element = allStudents[student];
            allStudentsArray.push(element);
        }
    }
    return sortByProperty(allStudentsArray, "name");
}

export function getTeacher(idnum, data){
    const allTeachers = extractAllTeachers(data);
    const teacher = allTeachers.filter(teacher => extractMatricDigits(teacher.matric) === extractMatricDigits(idnum))[0];
    return teacher;
}

export function getClassTeacher(className, data){
    for (const centre in data) {
        if (Object.hasOwnProperty.call(data, centre)) {
            const centreData = data[centre];
            for (const trainingClass in centreData) {
                if (Object.hasOwnProperty.call(centreData, trainingClass)) {
                    const classData = centreData[trainingClass].classes;
                    for (let i = 0; i < classData.length; i++) {
                        const Class = classData[i];
                        if(Class.name.toLowerCase() === className.toLowerCase()){
                            return Class.teacher ? Class.teacher : "";
                        }
                    }
                }
            }
        }
    }
    return "";
}

export function getClassDetails(className, data){
    let details = {name: "", teacher: "", "teacher matric": "", "teacher image": "", "assistant teacher": "", "assistant matric": "", "assistant image": "", students: "", centre: "", trainingClass: "", "teacher email": "", "assistant email": "", location: ""};
    for (const centre in data) {
        if (Object.hasOwnProperty.call(data, centre)) {
            const centreData = data[centre];
            for (const trainingClass in centreData) {
                if (Object.hasOwnProperty.call(centreData, trainingClass)) {
                    const classData = centreData[trainingClass].classes;
                    for (let i = 0; i < classData.length; i++) {
                        const Class = classData[i];
                        if(Class.name.toLowerCase() === className.toLowerCase()){
                            details = {...Class, centre, trainingClass};
                        }
                    }
                }
            }
        }
    }
    return details;
}

export async function extractClassStudents(className){
    const rawStudents = [];
    const allStudents = {};
    const allStudentsArray = [];

    const record = await getGeneralOverview();
    const classDetail = getClassDetails(className, record);
    const fet = await getClassRecord(classDetail.trainingClass, className);
    rawStudents.push(fet);

    for (let b = 0; b < rawStudents.length; b++) {
        const students = rawStudents[b];
        for (const student in students) {
            if (Object.hasOwnProperty.call(students, student) && student !== "meetings" && student !== "cumatt") {
                const info = students[student];
                allStudents[student] = info;
            }
        }
    }
    for (const student in allStudents) {
        if (Object.hasOwnProperty.call(allStudents, student)) {
            const element = allStudents[student];
            allStudentsArray.push(element);
        }
    }
    return sortByProperty(allStudentsArray, "name");
}

export function getCentre(className, data){
    for (const centre in data) {
        if (Object.hasOwnProperty.call(data, centre)) {
            const centreData = data[centre];
            for (const trainingClass in centreData) {
                if (Object.hasOwnProperty.call(centreData, trainingClass)) {
                    const classData = centreData[trainingClass].classes;
                    for (let i = 0; i < classData.length; i++) {
                        const Class = classData[i];
                        if(Class.name.toLowerCase() === className.toLowerCase()){
                            return centre;
                        }
                    }
                }
            }
        }
    }
    return "";
}

export async function getStudent(idnum, data){
    const allStudents = await extractAllStudents(data);
    let student = allStudents.filter(student => extractMatricDigits(student.matric) === idnum)[0];
    return student;
}

export function detectOpenAdmission(data){
    for (const centre in data) {
        if (Object.hasOwnProperty.call(data, centre)) {
            const centreData = data[centre];
            for (const trainingClass in centreData) {
                if (Object.hasOwnProperty.call(centreData, trainingClass)) {
                    const classData = centreData[trainingClass].classes;
                    for (let i = 0; i < classData.length; i++) {
                        const Class = classData[i];
                        if(Class.admission === true){
                            return true;
                        }
                    }
                }
            }
        }
    }
    return false;
}

export const calcEachAtt = (meetings, member) => {
    let total = meetings.length;
    let present = 0;
    for (let k = 0; k < meetings.length; k++) {
        const meeting = meetings[k];
        if (Object.hasOwnProperty.call(member, meeting)) {
            const status = member[meeting];
            if(status !== "ABSENT"){
                present = present + 1;
            }
        }
    }
    const att = total === 0 ? "00.00" : (((present / total) * 100).toFixed(2).toString()).padStart(5, "0");
    return att;
}

export const calcDailyAtt = (date, record) => {
    var total = 0;
    var signedin = 0;
    for (const member in record) {
        if (Object.hasOwnProperty.call(record, member)) {
            total = total + 1;
            const info = record[member];
            if(Object.hasOwnProperty.call(info, date)) {
                if(member !== "cumatt"){
                    const status = info[date];
                    if(status !== "ABSENT"){
                        signedin = signedin + 1;
                    }
                }
            }
        }
    }
    total = total === 0 ? 0 : total - 2;
    const att = (((signedin / total) * 100).toFixed(2).toString()).padStart(5, "0");
    const percentage = signedin === 0 ? 0 : att;
    return [total, signedin, percentage];
}

export const calcCumAtt = (record) => {
    const exclude = ["meetings", "cumatt"];
    const meetings = record.meetings;
    for (const key in record) {
        if (Object.hasOwnProperty.call(record, key)) {
            if(!exclude.includes(key)){
                const member = record[key];
                const memberAtt = calcEachAtt(meetings, member) + "%";
                record[key].attendance = memberAtt;
            }
        }
    }
    var attsCount = 0;
    var totalAtts = 0;
    for (let i = 0; i < meetings.length; i++) {
        const meeting = meetings[i];
        const dailyAtt = calcDailyAtt(meeting, record);
        attsCount = attsCount + 1;
        totalAtts = totalAtts + parseFloat(dailyAtt[2]);
        record.cumatt[meeting] = `${dailyAtt[2]}% (${dailyAtt[1]} / ${dailyAtt[0]})`;
    }
    const cumAtt = ((totalAtts / attsCount).toFixed(2).toString()).padStart(5, "0");
    record.cumatt.average = cumAtt + "%";
    return record;
}

export const allowSignIn = async () => {
    if(!await teacherAuthRequired()){
        return false;
    }
    const date = new Date();
    const today = fullDays[date.getDay()];
    let starttime = '00:00:00';
    let endtime = '00:00:00';
    const programDays = ["Sunday"];
    if(programDays.includes(today)){
        if(today === "Sunday"){
            starttime = '06:00:00';
            endtime = '08:00:00';
        }
        const startdate = new Date(`1970-01-01T${starttime}`);
        const enddate = new Date(`1970-01-01T${endtime}`);
        const todaydate = new Date(`1970-01-01T${getCurrTimeInfo().currTime24}`);
        if(todaydate < startdate || enddate <= todaydate){
            return false;
        }
        return true;
    }
    return false;
}

export const todayExists = (record) => {
    if(record.meetings.includes(getCurrTimeInfo().today)){
        return true;
    }
    return false;
};

export const createToday = (record) => {
    for (const key in record) {
        if (Object.hasOwnProperty.call(record, key)) {
            const element = record[key];
            if(key === "meetings"){
                element.push(getCurrTimeInfo().today);
            }else{
                element[getCurrTimeInfo().today] = "ABSENT";
            }
        }
    }
    return record;
};

export function showSwal(icon, title, text){
    Swal.fire({
        icon: icon,
        title: title,
        text: text,
        confirmButtonText: 'OK',
    })
}


// ================================================================================

// PHP

// ================================================================================
export async function allAttendanceUpdate(){
    try {
        const data = { all_attendance_update: "cnjnfkwjn" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/update.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function studentRegistration(formData){
    try {
        const { name, matric, email, trainingClass, Class, centre, image, attendance, password, confirmpassword } = formData;
        const data = { name, matric, email, trainingClass, Class, centre, image, attendance, password, confirmpassword, student_registration: "hdjfhj" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/registration.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            headers: {
              "Content-Type": "multipart/form-data",
            },
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function updateStudent(formData){
    try {
        const { name, matric, Class, image, score } = formData;
        const data = { name, matric, Class, image, score, update_student: "leerkjfek" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key]);
        }
        const url = baseURL + "php/update.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            headers: {
              "Content-Type": "multipart/form-data",
            },
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function updateStudentSpecial(formData){
    try {
        const data = { ...formData, update_student_special: "nldnfjlwmw" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key]);
        }
        const url = baseURL + "php/update.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            headers: {
              "Content-Type": "multipart/form-data",
            },
        });
        allAttendanceUpdate();
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function deleteStudent(formData){
    try {
        const { matric, Class, image } = formData;
        const data = { matric, Class, image, delete_student: "jnkjgccfgxf" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key]);
        }
        const url = baseURL + "php/delete.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            headers: {
              "Content-Type": "multipart/form-data",
            },
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function classRegistration(formData){
    try {
        const { name, centre, trainingClass, teacher, teacherMatric, assistantTeacher, assistantImage, teacherImage, assistantMatric, assistantEmail, teacherEmail, location } = formData;
        const data = { name, centre, trainingClass, teacher, teacherMatric, assistantTeacher, assistantImage, teacherImage, assistantMatric, assistantEmail, teacherEmail, location, class_registration: "enxjienif" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/registration.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            headers: {
              "Content-Type": "multipart/form-data",
            },
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function updateClass(formData){
    try {
        const { name, teacher, teacherMatric, assistantTeacher, assistantImage, teacherImage, assistantMatric, assistantEmail, teacherEmail, location } = formData;
        const data = { name: name.toLowerCase(), teacher, teacherMatric, assistantTeacher, assistantImage, teacherImage, assistantEmail, teacherEmail, assistantMatric, location, update_class: "kcnrkjwek" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/update.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            headers: {
              "Content-Type": "multipart/form-data",
            },
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function setMaximumStudents(maximum){
    try {
        const data = { maximum, max_students: "jnfknwek" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/update.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function getMaximumStudents(){
    try {
        const data = { max_students: "kfnxekrfnkej" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/read.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function setClassPassMarks(formData){
    try {
        const { believers, baptismal, workers } = formData;
        const data = { believers, baptismal, workers, setpassmarks: "iknykyiu" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/update.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function getClassPassMarks(){
    try {
        const data = { getpassmarks: "inllkjml" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/read.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        const res = send.data;
        if(typeof(res) === "object"){
            return res;
        }else{
            return "Failed";
        }
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function updateAllClassAdmission(){
    try {
        const data = { update_all_class_admission: "emctrjy" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/update.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function updateClassAdmission(formData){
    try {
        const { name } = formData;
        const admission = formData.admission.toString();
        const data = { name, admission, update_class_admission: "njkqndkj" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/update.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function updateCurrentClass(formData){
    try {
        const { name } = formData;
        const currentClass = formData.currentClass.toString();
        const data = { name: name.toLowerCase(), currentClass, update_class_current_class: "niuhmjk" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/update.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function teacherRegistration(formData){
    try {
        const { matric, email, Class, centre, image, password, confirmpassword } = formData;
        const data = { matric, email, class: Class.toLowerCase(), centre, image, password, confirmpassword, teacher_registration: "lwqkdlkr" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/registration.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            headers: {
              "Content-Type": "multipart/form-data",
            },
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function updateTeacher(formData){
    try {
        const { name, matric, Class, image, position } = formData;
        const data = { name, matric, Class, image, position, update_teacher: "refmkrlemg" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key]);
        }
        const url = baseURL + "php/update.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            headers: {
              "Content-Type": "multipart/form-data",
            },
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function deleteTeacher(formData){
    try {
        const { matric, Class, image, position } = formData;
        const data = { matric, Class, image, position, delete_teacher: "jrnekkse" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key]);
        }
        const url = baseURL + "php/delete.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            headers: {
              "Content-Type": "multipart/form-data",
            },
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function deleteTeacherAdmin(formData){
    try {
        const { matric, Class, image, position } = formData;
        const data = { matric, Class, image, position, delete_teacher_admin: "wenfkwnkjrwk" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key]);
        }
        const url = baseURL + "php/delete.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            headers: {
              "Content-Type": "multipart/form-data",
            },
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function centreRegistration(formData){
    try {
        const { name } = formData;
        const data = { name, centre_registration: "ejwfhrbe" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/registration.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function getStudentDirect(matric){
    try {
        const data = { matric, get_student: "enkjnrtkr" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/read.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        const res = send.data;
        if(typeof(res) === "object" && res.status === "Success"){
            return res;
        }else{
            return "Failed";
        }
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function getTeacherDirect(matric, Class){
    try {
        const data = { matric, class: Class, get_teacher: "lrjtjelk" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/read.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        const res = send.data;
        if(typeof(res) === "object" && res.status === "Success"){
            return res;
        }else{
            return "Failed";
        }
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function getCentresDirect(){
    try {
        const data = { get_centre: "detmelknfel" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/read.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        const res = send.data;
        if(res[0] === "Success"){
            return [...new Set(res.slice(1).sort())];
        }else{
            return [];
            // return { message: res[0] };
        }
    } catch (error) {
        return [];
        // return { message: "Failed" };
        // return error;
    }
}

export async function getCentreClassesDirect(centre){
    try {
        const data = { centre, get_centre_class: "kemltnerl" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/read.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        const res = send.data;
        if(res[0] === "Success"){
            return res.slice(1);
        }else{
            return { message: res[0] };
        }
    } catch (error) {
        return { message: "Failed" };
        // return error;
    }
}

export async function getClassDetailsDirect(className){
    const details = {name: "", teacher: "", "teacher matric": "", "teacher image": "", "assistant teacher": "", "assistant matric": "", "assistant image": "", students: "", centre: "", trainingClass: "", "teacher email": "", "assistant email": "", location: "", status: "Failed"};
    try {
        const data = { class: className.toLowerCase(), get_class_details_direct: "lrkfmkler" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/read.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        const res = send.data;
        if(typeof(res) === "object" && res.status === "Success"){
            return res;
        }else{
            return details;
        }
    } catch (error) {
        return details;
    }
}

export async function signIn(formData){
    try {
        const { matric, Class, attendance } = formData;
        const data = { matric, class: Class.toLowerCase(), attendance, signin: "kewmxekfm" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key]);
        }
        const url = baseURL + "php/update.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function specialSignIn(formData){
    try {
        const { matric, className, attendance, date, time } = formData;
        const data = { matric, class: className.toLowerCase(), attendance, date, time, signin: "kewmxekfm" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key]);
        }
        const url = baseURL + "php/update.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        allAttendanceUpdate();
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function updateClassAverage(trainingClass, className){
    try {
        const rec = await getClassRecord(trainingClass, className);
        const record = calcCumAtt(rec);
        const average = JSON.stringify(record["cumatt"]);
        const data = { average, class: className.toLowerCase(), update_class_average: "owfmjekn" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key]);
        }
        const url = baseURL + "php/update.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function generateAttendance(month, className){
    try {
        const rec = await getClassMonthRecord(month, className);
        if(rec.meetings.length === 0){
            return "Empty";
        }
        const record = calcCumAtt(rec);
        const data = { generate_attendance: "ienjrntekrj", class: className.toLowerCase(), record: JSON.stringify(record) };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/download.php";
        const send = await axios.post(url, formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function getContactInfo(){
    try {
        const data = { get_contact: "wekmlwer" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/read.php";
        const send = await axios.post(url, formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        const res = send.data;
        if(typeof(res) === "object"){
            res.phone = JSON.parse(res.phone);
            return res;
        }else{
            return { phone: [ {"name": "Paul", "phone": "8142644065"}, {"name": "Joshua","phone": "8103182378"} ], email: "rucbiblestudy@gmail.com" };
            // return "Failed";
        }
    } catch (error) {
        return { phone: [ {"name": "Paul", "phone": "8142644065"}, {"name": "Joshua","phone": "8103182378"} ], email: "rucbiblestudy@gmail.com" };
        // return "Failed";
        // return error;
    }
}

export async function setContactInfo(formData){
    try {
        const { phone, email } = formData;
        const data = { phone, email, set_contact: "qndlkw" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/update.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            Headers: {
                "Content-Type": "application/json"
            }
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}



// ================================================================================

// Mails

// ================================================================================
export const sendEmail = async ( To, Subject, Content, setStatus, setFormData, setLoading, success, fail, reset ) => {
    try {
        const data = { To, Subject, Content, send_mail: "njkxekfe" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/email.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
            headers: {
              "Content-Type": "multipart/form-data",
            },
        });
        const res = send.data;
        setLoading(false);
        if(res === "sent"){
            setStatus(success);
            setFormData(reset);
            return "sent";
        }else{
            setStatus(fail);
            return "failed";
        }
    } catch (error) {
        setLoading(false);
        setStatus(fail);
        return "failed";
        // return error;
    }
};

export function contactFormMail(to, name, email, message, setStatus, setFormData, setLoading, success, fail, reset){
    try {
        // const send = await emailjs.send(process.env.REACT_APP_EMAIL_JS_SERVICE_ID, process.env.REACT_APP_EMAIL_JS_CONTACT_FORM_TEMPLATE_ID, { name, email, message}, process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY);
        // if(send.status === 200){
        //     return "sent";
        // }
        const mailContent = `<div style="font-family: 'Poppins', sans-serif; color: #002F63; padding: 20px; text-align: justify;"><hr /><div style="margin-bottom: 5px; width: 100%; max-width: 250px; pointer-events: none"><img src="${baseURL}mail_header.png" style="width: 100%; object-fit: contain;"  /></div><div><h2 style="font-size: 20px; color: #002F63;">Contact Form Message</h2></div><div style="margin-top: 8px;"><strong style="display: inline-block; width: 80px;">Name:</strong> ${name}</div><div style="margin-top: 8px;"><strong style="display: inline-block; width: 80px;">E-Mail:</strong> ${email}</div><div style="margin-top: 8px;"><strong style="display: inline-block; width: 80px;">Message:</strong></div><div style="margin-bottom: 16px;">${message}</div><hr /></div>`;
        
        const res = sendEmail(`"RUC Training Unit" <${to}>`, `Message from ${name}`, mailContent, setStatus, setFormData, setLoading, success, fail, reset);
        return res;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export function passwordResetMail(name, email, token, setStatus, setFormData, setLoading, success, fail, reset){
    try {
        // const send = await emailjs.send(process.env.REACT_APP_EMAIL_JS_SERVICE_ID, process.env.REACT_APP_EMAIL_JS_PASSWORD_RESET_TEMPLATE_ID, { name, email, token}, process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY);
        // if(send.status === 200){
        //     return "sent";
        // }
        const mailContent = `<div style="font-family: 'Poppins', sans-serif; color: #002F63; padding: 20px; text-align: justify;"><hr><div style="margin-bottom: 5px; width: 100%; max-width: 250px; pointer-events: none"><img src="${baseURL}mail_header.png" style="width: 100%; object-fit: contain;"></div><div>Hello ${name},</div><div style="margin-top: 8px;">Click the button below to proceed with the password reset.</div><a href="${baseURL}passwordreset/${token}"><button style="margin: 10px 0; padding: 10px 25px; border-radius: 5px; border: none; outline: none; cursor: pointer; background-color: #002F63; color: white;">Reset Password</button></a><div>This link can only be used once and will expire in 10 minutes. <br></div><div style="margin-top: 8px;">If you are having problems with the button above, click or copy and paste the link below to your browser. </div><div style="margin: 10px 0;"><a href="${baseURL}passwordreset/${token}">${baseURL}passwordreset/${token}</a></div><div>If you didn’t ask to reset your password, you can ignore this email and rest assured that your account is safe.</div><div style="margin-top: 8px; margin-bottom: 16px;">Your RUC Training Unit team</div><hr></div>`;

        const res = sendEmail(`"${name}" <${email}>`, "Password Reset Mail", mailContent, setStatus, setFormData, setLoading, success, fail, reset);
        return res;
    } catch (error) {
        return "Failed";
        // return error;
    }
}
