import React from 'react';
import { Link } from 'react-router-dom';

function ClassCard({ name, to, image = false }) {
    return (
        <Link className='class-card' to={to}>
            <img src={image ? image : "/images/classroom.jpg"} alt={name} />
            <h3>{name}</h3>
        </Link>
    );
}

export default ClassCard;