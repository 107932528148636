import React from 'react';
import { FaBars, FaCircleChevronDown, FaLock, FaLockOpen, FaSpinner } from 'react-icons/fa6';
import { NavLink, useLocation } from 'react-router-dom';
import Banner from './Banner';
import { clearUser, getCookie, getCurrUser } from '../Auth';

var disclaimer = document.querySelector("img[alt='www.000webhost.com']");
if (disclaimer) {
    disclaimer.remove();
}

function Navbar() {
    const path = useLocation().pathname;
    const [showNav, setShowNav] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [currUser, setCurrUser] = React.useState({ class: '', position: 'none', matric: '', centre: '' });
    const position = getCookie("nd38h4nnue2");
    
    React.useEffect(() => {
        getCurrUser()
        .then(data => setCurrUser(data))
        .catch(err => err);
    }, [position, path]);

    const logout = () => {
        try {
            setLoading(true);
            clearUser();
            setTimeout(() => {
                setCurrUser({ class: '', position: 'none', matric: '', centre: '' });
                setLoading(false);
            }, 1000);
        } catch (error) {
        }
    };

    
    React.useEffect(() => {
        function clearCookies(){
            JSON.parse(localStorage.getItem("soifdjksnfjkew")) && clearUser();
        }

        window.addEventListener("storage", clearCookies);
        return () => {
            window.removeEventListener("storage", clearCookies);
        };
    }, []);

    const navStyles = path.indexOf("/overview/attendance") > -1
    ?
    {
        position: "initial"
    }
    :
    {};

    React.useEffect(() => {
        const nav = document.getElementById("navbar");
        nav.classList.contains("fixed") && nav.classList.remove("fixed");
        nav.classList.contains("transparent") && nav.classList.remove("transparent");
        path !== "/" && window.scrollTo({top: 0, behavior: "smooth"});
    }, [path]);

    return (
        <nav id='navbar' style={{...navStyles, boxShadow: path.indexOf("overview") > -1 || path.indexOf("admin") > -1 ? "none" : "0 0 5px rgba(0, 47, 99, 0.5)"}}>
            <Banner />
            <ul>
                <input type="checkbox" id="showNav" style={{display: "none"}} checked={showNav} onChange={() => {return null}} />
                <div className='menus'>
                    <li onClick={() => {setShowNav(false)}}><NavLink className={({isActive}) => isActive ? "active" : ""} to="/">HOME</NavLink></li>
                    {currUser.position === "student" && <li onClick={() => {setShowNav(false)}}><NavLink className={({isActive}) => isActive ? "active" : ""} to="/profile">PROFILE</NavLink></li>}
                    {currUser.position === "admin" && <li onClick={() => {setShowNav(false)}}><NavLink className={({isActive}) => isActive ? "active" : ""} to="/admin">OVERVIEW</NavLink></li>}
                    {(currUser.position === "teacher" || currUser.position === "assistant teacher") && <li onClick={() => {setShowNav(false)}}><NavLink className={({isActive}) => isActive ? "active" : ""} to="/overview">OVERVIEW</NavLink></li>}
                    {currUser.position === "none" && <li onClick={() => {setShowNav(false)}}><NavLink className={({isActive}) => isActive ? "active" : ""} to="/register">REGISTER</NavLink></li>}
                    <li className='md-menu' onClick={() => {setShowNav(false)}}><NavLink className={({isActive}) => isActive ? "active" : ""} to="/findus">FIND US</NavLink></li>
                    <li onClick={() => {setShowNav(false)}}><NavLink className={({isActive}) => isActive ? "active" : ""} to="/contact">CONTACT US</NavLink></li>
                    {/* <span onClick={() => {setShowNav(false)}} className='md-menu'><LiaTimesSolid /></span> */}
                </div>
                {currUser.position === "none" && <div className='login-div'>
                    <li>
                        <button><FaLockOpen style={{marginRight: "3px"}} /> LOG IN <FaCircleChevronDown style={{fontSize: "0.9em", marginLeft: "3px"}} /></button>
                        <ul>
                            <li>
                                <NavLink className={({isActive}) => isActive ? "active" : ""} to="/login/student">Student</NavLink>
                            </li>
                            <li>
                                <NavLink className={({isActive}) => isActive ? "active" : ""} to="/login/teacher">Teacher</NavLink>
                            </li>
                            <li>
                                <NavLink className={({isActive}) => isActive ? "active" : ""} to="/login/admin">Admin</NavLink>
                            </li>
                        </ul>
                    </li>
                </div>}
                {currUser.position !== "none" && <div>
                    <li><button className='logout' onClick={logout} disabled={loading}>{loading ? <FaSpinner className='spinner' style={{fontSize: "1.4em"}} /> : <NavLink><FaLock style={{marginRight: "3px"}} /> LOG OUT</NavLink>}</button></li>
                </div>}
                <div className='md-menu'>
                    <li onClick={() => {setShowNav(true)}} style={{width: "auto", marginLeft: "10px"}}><button style={{width: "auto", padding: "10px"}}><FaBars /></button></li>
                </div>
                <div className="md-menu navclosediv" onClick={() => {setShowNav(false)}}></div>
            </ul>
        </nav>
    );
}

export default Navbar;