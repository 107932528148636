import axios from "axios";

const origin = window.location.origin;
export const baseURL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? "http://localhost:80/www/ruc-training-unit/public/" : origin + "/";
export const downloadBaseURL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? "http://localhost:80/www/ruc-training-unit/files/" : origin + "/files/";

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function setCookie(cname, cvalue, exdays = 1/3) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + "; path=/";
}

export function removeCookie(cname) {
    document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
}
  
export function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export async function userVerification(password){
    try {
        const matric = getCookie("nwoeem93jnrr49");
        const position = getCookie("nd38h4nnue2");
        if(!matric || !position){
            return "Failed";
        }
        const data = { matric, password, position, user_verification: "ldkendwejnkf" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/verification.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function getCurrUser(){
    try {
        const Class = getCookie("nniNJSN89u3heu23");
        const centre = getCookie("njen98ue9jA");
        const matric = getCookie("nwoeem93jnrr49");
        const position = getCookie("nd38h4nnue2");
        const token = getCookie("BNEidw2k83e8y9");
        if(!Class || !matric || !position || !token || !centre){
            removeCookie("nniNJSN89u3heu23");
            removeCookie("njen98ue9jA");
            removeCookie("nwoeem93jnrr49");
            removeCookie("nd38h4nnue2");
            removeCookie("BNEidw2k83e8y9");
            localStorage.setItem("soifdjksnfjkew", "true");
            setTimeout(() => {
                localStorage.setItem("soifdjksnfjkew", "false");
            }, 2000);
            return { class: '', position: 'none', matric: '', centre: '' };
        }
        const data = { class: Class.toLowerCase(), matric, position, token, get_curr_user: "mwekjnekj" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/verification.php";
        const send = await axios.post(url, formdata, {
            mode: "cors",
        });
        if(send.data === "Confirmed"){
            return { class: Class.toLowerCase(), position, matric, centre };
        }else{
            return { class: '', position: 'none', matric: '', centre: '' };
        }
    } catch (error) {
        return { class: '', position: 'none', matric: '', centre: '' };
        // return error;
    }
}

export const clearUser = () => {
    try {
        removeCookie("nwoeem93jnrr49");
        removeCookie("nd38h4nnue2");
        removeCookie("njen98ue9jA");
        removeCookie("nniNJSN89u3heu23");
        removeCookie("BNEidw2k83e8y9");
        localStorage.setItem("soifdjksnfjkew", "true");
        setTimeout(() => {
            localStorage.setItem("soifdjksnfjkew", "false");
        }, 2000);
        return true;
    } catch (error) {
        return false;    
    }
}

export const authRequired = async () => {
    const user = await getCurrUser();
    if(user.position !== '' && user.position !== 'none'){
        return true;
    }
    return false;
};

export const teacherAuthRequired = async () => {
    const user = await getCurrUser();
    if(user.position === 'teacher' || user.position === 'assistant teacher'){
        return true;
    }
    return false;
};

export const adminAuthRequired = async () => {
    const user = await getCurrUser();
    if(user.position === 'admin'){
        return true;
    }
    return false;
};

export async function adminLogin(password){
    try {
        const data = { password, admin_login: "wkmlfknjw" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
       const url = baseURL + "php/verification.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
        });
        const res = send.data.split(", ");
        if(res[0] === "Confirmed"){
            setCookie("nwoeem93jnrr49", "RUN/AAA/00/00000");
            setCookie("nd38h4nnue2", "admin");
            setCookie("njen98ue9jA", "admin");
            setCookie("nniNJSN89u3heu23", "admin");
            setCookie("BNEidw2k83e8y9", res[1]);
        }
        return res[0];
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function login(matric, position, password, remember = false){
    try {
        const data = { matric, position, password, login: "lmhiturnd" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
       const url = baseURL + "php/verification.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
        });
        const res = send.data.split(", ");
        if(res[0] === "Confirmed"){
            if(remember){
                setCookie(position === "student" ? "mkoe2ojoi4o" : `jxnNS4uenk23k`, res[1]);
                setCookie(position === "student" ? "ndi2n4r5i983nf" : `jnxkj5rn32kn39nd`, password);
            }
            setCookie("nwoeem93jnrr49", res[1]);
            setCookie("nd38h4nnue2", res[2]);
            setCookie("njen98ue9jA", res[3]);
            setCookie("nniNJSN89u3heu23", res[4]);
            setCookie("BNEidw2k83e8y9", res[5]);
            return "Success";
        }else{
            return res[0];
        }
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function forgottenPassword(matric, position){
    try {
        const data = { matric, position, forgot_password: "ekmlwemelfmr" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
       const url = baseURL + "php/verification.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
        });
        const res = send.data.split(", ");
        return res;
    } catch (error) {
        return "Failed";
        // return error;
    }
}

export async function passwordReset(matric, token, password, confirmpassword){
    try {
        const data = { matric, token, password, confirmpassword, password_reset: "ecrtjlerlte" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/verification.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
        });
        return send.data.split(", ");
    } catch (error) {
        return ["Failed"];
        // return error;
    }
}

export async function changePassword(formData){
    try {
        const { matric, position, oldpassword, newpassword, confirmpassword } = formData;
        const data = { matric, position, oldpassword, newpassword, confirmpassword, change_password: "nkmyniumjmk" };
        let formdata = new FormData();
        for(var key in data){
            formdata.append(key, data[key])
        }
        const url = baseURL + "php/verification.php";
        const send = await axios.post(url,formdata, {
            mode: "cors",
        });
        return send.data;
    } catch (error) {
        return "Failed";
        // return error;
    }
}