import React from 'react';
import { Link } from 'react-router-dom';

function MemberCard({ name, image, matric, to, imgfolder }) {
    return (
        <Link className='member-card' to={to}>
            <img onError={(e)=>{ if (e.target.src !== "/user.png"){ e.target.onerror = null; e.target.src="/user.png"; }}} src={`/images/${imgfolder}/${image}`} alt={name} />
            <h3>{name}</h3>
            <p className={matric ? "" : "empty"}>{matric}</p>
        </Link>
    );
}

export default MemberCard;